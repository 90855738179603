import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { Nationalite } from 'src/app/models/gestion/parametrage/nationalite';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NationaliteService {

  url: string = environment.backend + '/nationalites';

  constructor(private http: HttpClient) { }

  createNationalite(nationalite: Nationalite): Observable<Object> {
    return this.http.post(`${this.url}`, nationalite);
  }

  deleteNationalite(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateNationalite(nationalite: Nationalite): Observable<Object> {
    return this.http.put(`${this.url}`, nationalite);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

}
