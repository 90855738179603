<!-- <p>generer-placard works! Générer une fiche placard au dossier : {{numerorg}}</p> -->
<p>Entrez les informations de la fiche placard :</p>
<div>
    <form [formGroup]="placardJuridiqueForm" >
        <div class="row">
            <div class="card-body row"style="border-radius: 10px; box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%); border: 0; margin: 6px;">
            <!-- <div class="col-md-12 mb-3" style="border-bottom: 1px solid #eee; border-top-left-radius: 0.3rem; border-top-right-radius: 0.3rem;"><h5 class="card-title">Ajout du document</h5></div> -->
          
            <div class="form-group col-md-6 mb-3">
                <label for="dateProcede">Date prévue pour les enchères <span class="error-field"> * </span></label>
                <div class="input-group">
                    <input class="form-control" formControlName="dateProcede" id="dateProcede"
                        placeholder="dd-mm-yyyy" type="date">
                    </div>
                <div *ngIf="placardJuridiqueForm.controls.dateProcede.errors &&
                    (placardJuridiqueForm.controls.dateProcede.dirty || placardJuridiqueForm.controls.dateProcede.touched)"
                    class="error-field">
                    Ce champ est obligatoire !
                </div>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="description">Description</label>
                <textarea class="form-control" id="code" formControlName="description" placeholder="Entrer la description du bien" type="textarea"> </textarea>
            </div>
          
            <div class="col-md-6 form-group mb-3">
                <label for="localisation">Localisation</label>
                <textarea class="form-control" id="localisation" formControlName="localisation" placeholder="Entrer la localisation du bien" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="code">Caractéristiques</label>
                <textarea class="form-control" id="caracteristiques" formControlName="caracteristiques" placeholder="Entrer les caractéristiques du bien" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="creancier">Créancier</label>
                <textarea class="form-control" id="creancier" formControlName="creancier" placeholder="Entrer nom prénoms addresse contacts du créancier" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="code">Défense du créancier</label>
                <textarea class="form-control" id="avocat" formControlName="avocat" placeholder="Entrer nom, contacts et adresses du cabinet" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="procedureUn">EN EXECUTION : </label>
                <textarea class="form-control" id="procedureUn" formControlName="procedureUn" placeholder="Entrer la première procédure exécutée" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="code">EN EXECUTION 2:</label>
                <textarea class="form-control" id="procedureDeux" formControlName="procedureDeux" placeholder="Entrer la deuxième procédure exécutée" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="code">A LA REQUETE DE :</label>
                <textarea class="form-control" id="preteur" formControlName="preteur" placeholder="Entrer nom prénoms addresse contacts du prêteur" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="avocatPreteur">Défense du prêteur: </label>
                <textarea class="form-control" id="avocatPreteur" formControlName="avocatPreteur" placeholder="Entrer nom, contacts et adresses du cabinet " type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="code">Prix:</label>
                <textarea class="form-control" id="prix" formControlName="prix" placeholder="Entrer le prix du bien" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="code">ENTREE EN JOUISSANCE 1:</label>
                <textarea class="form-control" id="conditionUn" formControlName="conditionUn" placeholder="Entrer la condition d'entrée en jouissance du bien" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="conditionDeux">ENTREE EN JOUISSANCE 2:</label>
                <textarea class="form-control" id="conditionDeux" formControlName="conditionDeux" placeholder="Entrer la condition d'entrée en jouissance du bien" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="code">Contacts pour plus d'Informations:</label>
                <textarea class="form-control" id="contact" formControlName="contact" placeholder="Entrer les contacts pour bien" type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="signataire">Signataire:</label>
                <textarea class="form-control" id="signataire" formControlName="signataire" placeholder="Entrer noms contacts et adresses du signataire " type="textarea"> </textarea>
            </div>

            <div class="col-md-6 form-group mb-3">
                <label for="pm">Prix minimal d'enchérissement:</label>
                <textarea class="form-control" id="pm" formControlName="pm" placeholder="Entrer le prix minimal d'enchérissement " type="textarea"> </textarea>
            </div>


            <hr>
            <div class="col-md-12" style="margin-top: 1px;">
                <btn-loading [loading]="isLoadingData" btnClass="btn-save" (click)="printFileToDownloadInNavigator()" class="float-right">Génerer le placard</btn-loading>
                <button class="btn m-1 ml-2 btn-cancel" type="button" (click)="resetForm"> Annuler</button>
            </div>
        </div>
        </div>
    </form>
</div>