
import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Nac} from "../../../models/gestion/nomenclature/nac";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NacService {

  url: string = environment.backend + '/nacs';

  constructor(private http: HttpClient) { }

  createNac(nac: Nac): Observable<Object> {
    return this.http.post(`${this.url}`, nac);
  }

  deleteNac(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateNac(nac: Nac): Observable<Object> {
    return this.http.put(`${this.url}`, nac);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

  nacById(id:number): Observable<Object> {
    return this.http.get(`${this.url}/${id}`);
  }

  listByService(serviceId: number): Observable<Object> {
    return this.http.get(`${this.url}/service/${serviceId}`);
  }

  listByTypeService(typeServiceId: number): Observable<Object> {
    return this.http.get(`${this.url}/type-service/${typeServiceId}`);
  }

  validerNac(nacId: number): Observable<Object> {
    return this.http.get(`${this.url}/valider-nac/${nacId}`);
  }

}
