import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import { CalendarEventDB } from '../../shared/inmemory-db/calendarEvents';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CalendarAppEvent} from "../../../models/gestion/audience/calendar-event.model";
import {CalendarEventDB} from "../../../models/gestion/audience/calendar-events";
import {environment} from "../../../../environments/environment";
import {Evenement} from "../../../models/gestion/parametrage/evenement";
import {AgendaAudience} from "../../../models/gestion/audience/agenda-audience";
import { AgendaAudiencePartie } from 'src/app/models/gestion/audience/agenda-audience-partie';

@Injectable({
  providedIn: 'root'
})
export class AgendaAudiencePartieService {

  url: string = environment.backend + '/agenda-audience-partie';

  constructor(
    private http: HttpClient
  ) {
  }

  listByAgendaAudienceId(agendaAudienceId: number): Observable<Object> {
    return this.http.get(`${this.url}/agenda-audience/${agendaAudienceId}`);
  }

  createAgendaAudiencePartie(agendaAudiencePartie: AgendaAudiencePartie): Observable<Object> {
    return this.http.post(`${this.url}`, agendaAudiencePartie);
  }

  deleteAgendaAudiencePartie(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

}
