import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Audience } from 'src/app/models/gestion/audience/audience';
import { DossierJuridique } from 'src/app/models/gestion/dossier-juridique/dossier-juridique';
import { PartieJuridique } from 'src/app/models/gestion/dossier-juridique/partie-juridique';
import { ServiceJuridique } from 'src/app/models/gestion/org-judiciaire/service-juridique';
import { TransferDataService } from 'src/app/services/common/transfer-data.service';
import { AudienceGenerateService } from 'src/app/services/gestion/audience/audience-generate.service';
import { DossiersService } from 'src/app/services/gestion/dossier-juridique/dossiers.service';

@Component({
  selector: 'app-audience-details',
  templateUrl: './audience-details.component.html',
  styleUrls: ['./audience-details.component.css']
})
export class AudienceDetailsComponent implements OnInit {
  audienceId;
  audience: Audience;
  audienceListFiltered;
  dossiersByAudienceListFiltered;

  audienceList: Audience[] = [];
  dossiersByAudienceList: DossierJuridique[] = [];
  ch: ServiceJuridique;
  idService: number;


  //pour les tabs navs
  activeTabsNav;

  //anneeChoice: Annee = null;

  isLoadingData = false;

  searchControl: FormControl = new FormControl();


  constructor(
    private transferDataService:TransferDataService,
    private audienceGenerateService: AudienceGenerateService,
    private toastr: ToastrService,
    private dossiersService: DossiersService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.audienceId = this.transferDataService.getAudienceData();
    if (this.audienceId){
      this.audienceGenerateService.getAudienceById(this.audienceId).subscribe(
        (data: any) => {
          this.audience = data;
          console.log(this.audience);
        },
        (error: HttpErrorResponse) => {
          console.log('Echec status ==> '+error.status);
        });
    }else{
      this.toastr.error("Erreur: aucune audience n'est sélectionnée", ' Erreur !', {progressBar: true});
    }

    this.dossiersService.getAllDossierByAudienceId(this.audienceId).subscribe(
      (data: any) => {
        console.log('Dossier by audience id');
        console.log(data);
        this.dossiersByAudienceList = [...data];
        this.dossiersByAudienceListFiltered = [...this.dossiersByAudienceList];
      },
      (error: HttpErrorResponse) => {
        console.log('Echec status lors du get Audience By Id ==> ' + error.status);
        console.log('Message error ==>' + error.statusText);
      }
    );

  }

  filerData(val) {

    if (val) {
      val = val.toLowerCase();
    } else {
      return this.audienceListFiltered = [...this.audienceList];
    }

    const columns = Object.keys(this.audienceList[0]);
    if (!columns.length) {
      return;
    }

    const rows = this.audienceList.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.audienceListFiltered = rows;
  }

  getPartieByTypeLibelle(partiesJuridiqueList: PartieJuridique[], type: string): PartieJuridique[] {
    let parties: PartieJuridique[] = [];
    if (partiesJuridiqueList.length > 0 && partiesJuridiqueList != null) {
      partiesJuridiqueList.forEach(p => {
        if (p.typePartieLibelle == type) {
          parties.push(p);
        }
      });
    }
    parties = [...parties];
    //console.log(parties);
    return parties;
  }
  backToListAudience(){
    console.log("Backing...");
    this.router.navigate(['gestion/audience/role-avant-et-apres-audience']);
  }

}
