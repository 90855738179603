import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { PersonneMorale } from 'src/app/models/gestion/personnes/personne-morale';

@Injectable({
  providedIn: 'root'
})
export class PersonneMoraleService {

 
  url: string = environment.backend + '/personnemorales';

  constructor(private http: HttpClient) { }

  getPersonneMoraleById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/${id}`);
  }

  createPersonneMorale(personneMorale: PersonneMorale): Observable<Object> {
    return this.http.post(`${this.url}`, personneMorale);
  }

  deletePersonneMorale(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updatePersonneMorale(personneMorale: PersonneMorale): Observable<Object> {
    return this.http.put(`${this.url}`, personneMorale);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }
}
