
<!--<div class="form-group">
    <label for="prive">Portée de la note</label>
    <ng-select [(ngModel)]="portee" id="prive" placeholder="Choisir la portée">
        <ng-option value=1>Privée</ng-option>
        <ng-option value=2>Publique</ng-option>
    </ng-select>
</div>

<div class="form-group">
    <textarea  [(ngModel)]="notetxt" class="form-control" rows="4" placeholder = "Ecrire des notes">
    </textarea>
</div>


<div class="form-group">

        <button [disabled]="notetxt =='' || portee =='' " (click)="enregistrerNotes()" class="btn btn-danger btn-rounded" ngbAutofocus type="button">Enregistrer note
        </button>

</div>-->

<form (ngSubmit)="enregistrerNotes()" [formGroup]="validateForm">

    <div class="row">
        <div class="col-md-12 form-group mb-1">
            <label for="prive">Portée de la note</label>
            <ng-select formControlName ="prive" id="prive" placeholder="Choisir la portée">
                <ng-option value="true">Privée</ng-option>
                <ng-option value="false">Publique</ng-option>
            </ng-select>

            <!--<div *ngIf="validateForm.controls.prive.errors &&
                               (validateForm.controls.prive.dirty || validateForm.controls.prive.touched)"
                         class="error-field">
                      Ce champ est obligatoire !
            </div>-->

        </div>
        <div class="col-md-12 form-group mb-1">
            <label for="prive">Note :</label>
            <textarea  formControlName="message" class="form-control" rows="4" placeholder = "Ecrire des notes">
            </textarea>
        </div>
        
        <div class="col-md-12">
            <btn-loading [loading]="loading" btnClass="btn-primary btn-rounded btn-save">Enregistrer
            </btn-loading>
            <button (click)="resetForm()" class="btn m-1 ml-2 btn-cancel" type="button">
              Annuler
            </button>
          </div>
    </div>

</form>

