import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from "../../models/gestion/utilisateur/user";
import {DefaultHabilitation} from "../../models/habilitation/default-habilitation";
import {AdminHabilitation} from "../../models/habilitation/admin-habilitation";
import {GreffierEnrolementHabilitation} from "../../models/habilitation/greffier-enrolement-habilitation";
import {GreffierCacpcHabilitation} from "../../models/habilitation/greffier-cacpc-habilitation";
import {JugeHabilitation} from "../../models/habilitation/juge-habilitation";
import {PresidentTrbunal} from "../../models/habilitation/president-trbunal";
import {AuxilliaireHabilitation} from "../../models/habilitation/auxilliaire-habilitation";
import {PresidentSection} from "../../models/habilitation/president-section";
import {SecretaireJudiciaire} from "../../models/habilitation/secretaire-judiciaire";
import {SecretaireParticuliere} from "../../models/habilitation/secretaire-particuliere";
import {Procureur} from "../../models/habilitation/procureur";
import {JugeConsulaireHabilitation} from "../../models/habilitation/juge-consulaire-habilitation";
import {ComptableHabilitation} from "../../models/habilitation/comptable-habilitation";
import {VicePresidenteHabilitation} from "../../models/habilitation/vice-preseindente-habilitation";
import { MenuUserHabilitation } from 'src/app/models/habilitation/menu-user-habilitation';
import { UserService } from '../gestion/utilisateur/user.service';

export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string;       // Possible values: link/dropDown/extLink
  name?: string;      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}

export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string;       // Display text
  state?: string;     // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false
  };
  selectedItem: IMenuItem;
  
  selectedItem11: IMenuItem[];

  constructor(
    private menuUserHabilitation: MenuUserHabilitation,
    private userService: UserService,
  ) {
  }

  defaultMenu: IMenuItem[] = DefaultHabilitation.menuList;
  adminMenu: IMenuItem[] = AdminHabilitation.menuList;
  greffierEnrolementMenu: IMenuItem[] = GreffierEnrolementHabilitation.menuList;
  greffierCacpcMenu: IMenuItem[] = GreffierCacpcHabilitation.menuList;
  jugeToutChambreMenu: IMenuItem[] = JugeHabilitation.menuList;
  procureurMenu: IMenuItem[] = Procureur.menuList;
  auxilliaireJusticeMenu: IMenuItem[] = AuxilliaireHabilitation.menuList;
  comptableMenu: IMenuItem[] = ComptableHabilitation.menuList;
  //presidentSectionMenu: IMenuItem[] = PresidentSection.menuList;
  secretaireJudiciaireMenu: IMenuItem[] = SecretaireJudiciaire.menuList;
  secretaireParticuliereMenu: IMenuItem[] = SecretaireParticuliere.menuList;
  presidentTrbunalMenu: IMenuItem[] = PresidentTrbunal.menuList;
  jugeConsulaireMenu: IMenuItem[] = JugeConsulaireHabilitation.menuList;
  vicePresidenteMenu: IMenuItem[] = VicePresidenteHabilitation.menuList;

  
  
 

  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // You can customize this method to supply different menu for
  // different user type.
  // publishNavigationChange(menuType: string) {
  //   switch (userType) {
  //     case 'admin':
  //       this.menuItems.next(this.adminMenu);
  //       break;
  //     case 'user':
  //       this.menuItems.next(this.userMenu);
  //       break;
  //     default:
  //       this.menuItems.next(this.defaultMenu);
  //   }
  // }

  publishNavigationChange(currentUser:User) {
    let not_valid_user:boolean = false;
    if(currentUser!=null){
      if(currentUser.authorities?.indexOf('ROLE_ADMIN')>=0) {
        this.menuItems.next(this.adminMenu);
      } else { // NOT ADMIN
        const currentExerciceFonction = currentUser.currentExerciceFonction;
        if(currentExerciceFonction!=null){
          const fonctionCode = currentExerciceFonction.fonctionCode;
          const serviceCode = currentExerciceFonction.serviceCode;
          console.log('Info fonction service');
          console.log('fonction : '+ fonctionCode);
          console.log('service : '+ serviceCode);
          switch (fonctionCode) {
            case 'GRE': // Greffier
              switch (serviceCode) {
                case 'CAPCP': // Chambre des Procédures Collectives d'Apurement du Passif
                  this.menuItems.next(this.greffierCacpcMenu);
                  break;
                case 'ENR': // Enrôlement
                  this.menuItems.next(this.greffierEnrolementMenu);
                  break;
              }
              break;
            case 'JUG': // Juge
              this.menuItems.next(this.jugeToutChambreMenu);
              break;
            case 'JUC': // Juge Consulaire
              this.menuItems.next(this.jugeConsulaireMenu);
              break;
            case 'PRO': //Procureur
              this.menuItems.next(this.procureurMenu);
              break;
            case 'PRG': //Procureur General
              this.menuItems.next(this.procureurMenu);
              break;
            case 'AVT': //Avocat
              this.menuItems.next(this.auxilliaireJusticeMenu);
              break;
            case 'HSR': //Huissier
              this.menuItems.next(this.auxilliaireJusticeMenu);
              break;
            case 'PRS': //President de section
              this.menuItems.next(this.presidentTrbunalMenu);
              break;
            case 'PRT': //President du tribunal
              this.menuItems.next(this.presidentTrbunalMenu);
              break;
            case 'SEJ': //Secretariat judiciaire
              this.menuItems.next(this.secretaireJudiciaireMenu);
              break;
            case 'SEP': //Secretariat particuliers
              this.menuItems.next(this.secretaireParticuliereMenu);
              break;
              case 'COM': //Comptable
            this.menuItems.next(this.comptableMenu);
              break;
              case 'VP': //vice président
              this.menuItems.next(this.vicePresidenteMenu);
              break;
            default:
              this.menuItems.next(this.defaultMenu);
          }
        } else {
          // currentExerciceFonction is null
          not_valid_user = true;
        }
      }
    }else { // CurrentUser is null
      // no menu list should be dipslayed
      not_valid_user = true;
    }

    if(not_valid_user) {
      //this.menuItems.next(this.adminMenu); // just for test purpuse
    }

   }

   //publishNavigationChange Léo

   //publishNavigationChange2(menuUserConnected: IMenuItem[]){
    publishNavigationChange2(currentUser: User){
      //publishNavigationChange2(){
      if(currentUser!=null){
        this.userService.findAllMenuByUser(currentUser?.id).subscribe(
          (data: IMenuItem[]) => {
              console.log('liste des menus');
              console.log(data);
    
              this.selectedItem11 = this.menuUserHabilitation.contruireTableauIMenuItem(data)
              this.menuItems.next(this.selectedItem11);
              console.log('Liste de MenuItem');
              console.log(this.selectedItem11);
             
          });

      }

   }



}

