export class Note{
    audienceId: number;
    audienceUuid: string;
    createdBy: string;
    createdDate: string;
    dossierId: number;
    dossierUuid: string;
    id: number;
    lastModifiedBy: string;
    lastModifiedDate: string;
    message: string;
    prive: boolean;
    userNoteId: number;
}