<div>

  <div class="row col-md-12" style="background-color: #f8f9fa ! important">
  
      <div class="col-md-2">
        <div class="auth-logo text-center mb-2" style="padding:0px; margin: 0px;">
          <img src="assets/images/logoForsetiCom.png" alt="">
        </div>
      </div>
  
      <!-- <div class="col-md-11" style="text-align: center; padding-top: 2em">
        <h1>TRIBUNAL DE COMMERCE DE COTONOU</h1>
      </div> -->
      <div id="gestion" class="col-md-10 mb-2" style="text-align: center; padding-top: 1.5em;">
        <strong class="content font-weight-bold">TRIBUNAL DE COMMERCE DE COTONOU</strong>
      </div>
    </div>
    
    <div class="separator-breadcrumb border-top"></div>
    <div class="text-center" style="color: #000; font-weight: bold; margin-bottom: 20px;" *ngIf="audience"> <span class="h2">{{audience.chambreLibelle}}</span></div>
    <div class="text-center" style="color: #000; font-weight: bold; margin-bottom: 20px;" *ngIf="audience"> Rôle du {{audience.date | date :  "dd/MM/y à hh:mm:ss"}} -  Salle: {{audience.salleLibelle}}</div>
    <div class="row">
        <!-- <div class="card-title mb-3 border-bottom pb-2"><strong style="text-transform: uppercase;">{{audienceList[0].chambreLibelle}}</strong></div>-->
        <div class="col-md-12 ">
          <button (click)="backToListAudience()" class="float-right btn btn-outline-secondary m-1" type="button">
            Retour à la liste des audiences
          </button>
        </div> 

        <br/><br/>

        <div class="col-md-12" style="margin-top: 20px;">
        <div class="card  o-hidden">
            <ngx-datatable
            style="height: 100%; box-shadow: none;"
            class="material fullscreen"
            [columnMode]="'force'"
            [limit]="6"
            [messages]="{emptyMessage: 'Aucun dossier à afficher pour cette audience!'}"            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="60"
            [rows]="dossiersByAudienceListFiltered">
            <ngx-datatable-column >
                <ng-template ngx-datatable-header-template>
                <span class="font-weight-bold text-14">  Dossiers</span>
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                <ul>
                    <li>{{ row.numerorg != null ? row.numerorg : row.oldnumerorg }}</li>
                    <li *ngFor="let nac of row.nacs"> {{ nac.libelle }} </li>
                </ul>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                <span class="font-weight-bold text-14"> Parties</span>
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                <span
                    *ngIf="getPartieByTypeLibelle(row.parties, 'DEMANDEUR').length < 1"> Aucun demandeur(s) : 
                </span>
                <ul>
                    <li *ngFor="let partie of getPartieByTypeLibelle(row.parties, 'DEMANDEUR')">
                    {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }} {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                    (DEMANDEUR)
                    </li>
                    <li *ngFor="let partie of getPartieByTypeLibelle(row.parties, 'DEFENDEUR')">
                    {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }} {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                    (DEFENDEUR)
                    </li>
                </ul>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="statutLibelle">
                <ng-template ngx-datatable-header-template>
                <span class="font-weight-bold text-14"> Statut</span>
                </ng-template>
            </ngx-datatable-column>                    
            </ngx-datatable>
        </div>
        </div>
    </div>
      
</div>
