import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {jsPDF} from "jspdf";

@Component({
  selector: 'app-note-editor',
  templateUrl: './note-editor.component.html',
  styleUrls: ['./note-editor.component.css']
})
export class NoteEditorComponent implements OnInit {
  content: string ;
  audienceId!: number;
  dossierId!: number;

  constructor(
      private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.audienceId = +params.audienceId; // Convertit en nombre avec "+"
      this.dossierId = +params.dossierId;
      console.log('Audience ID:', this.audienceId);
      console.log('Dossier ID:', this.dossierId);
    });
  }


  // Function to decode HTML entities
  decodeHtml(html: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  saveContentAsPDF(): void {
    // Remove HTML tags from the content using a simple regex
    const plainTextWithEntities = this.content.replace(/<\/?[^>]+(>|$)/g, "");

    // Decode HTML entities (like &eacute;) into proper characters (like é)
    const plainText = this.decodeHtml(plainTextWithEntities);

    // Create a new jsPDF instance with A4 page size (210mm x 297mm)
    const doc = new jsPDF({
      format: 'a4',
      unit: 'mm'
    });

    // Set up the header
    const headerText = 'Prise de note'; // You can customize the header text here
    doc.setFontSize(14);
    doc.text(headerText, 10, 10); // Add header at the top of the page

    // Add the decoded plain text below the header
    doc.setFontSize(12); // Reset font size for the content
    const marginTop = 20; // Adding margin below the header
    const marginLeft = 10; // Left margin
    const pageWidth = 190; // A4 width minus the margins (210mm - 2 * 10mm)

    // doc.text() does not support automatic line breaks, so we need to split the text into lines
    const splitText = doc.splitTextToSize(plainText, pageWidth);

    // Add the split text to the PDF with some margin from the top (below the header)
    doc.text(splitText, marginLeft, marginTop);

    // Save the PDF
    doc.save('note.pdf');
  }

}
