import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Audience} from '../../models/gestion/audience/audience';
import {DossiersService} from '../../services/gestion/dossier-juridique/dossiers.service';
import {DossierJuridique} from '../../models/gestion/dossier-juridique/dossier-juridique';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from "moment";

@Component({
  selector: 'app-audiencer-petite-creance',
  templateUrl: './audiencer-petite-creance.component.html',
  styleUrls: ['./audiencer-petite-creance.component.css']
})
export class AudiencerPetiteCreanceComponent implements OnInit {
  @Input() numerorg?: string;
  dossierJuridiqueForm: FormGroup;
  audienceList: Audience[] = [];
  loading: boolean;

  constructor(
      private dossiersService: DossiersService,
      private fb: FormBuilder,
      private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadDossierDataList();
    this.makeForm(null);
  }
  makeForm(dossierJuridique: DossierJuridique): void {
    this.dossierJuridiqueForm = this.fb.group({
      numeroDossier: [dossierJuridique != null ? dossierJuridique.numeroDossier : null],
      audiencedistributionId: [dossierJuridique != null ? dossierJuridique.audiencedistributionId : null],
    });
  }
  loadDossierDataList(): void {
    this.dossiersService.listDesAudiencesPetiteCreance().subscribe((data: any) => {
      console.log('liste des audience+++++++', data);
      this.audienceList = data;
      this.audienceList = data.filter((d) => d.date >= moment(new Date()).format('yyyy-MM-DDTHH:mm'));
    });
  }
  submit(dossierNumber: string): void {
    for (const i in this.dossierJuridiqueForm.controls) {
      this.dossierJuridiqueForm.controls[i].markAsDirty();
      this.dossierJuridiqueForm.controls[i].updateValueAndValidity();
    }
    if (this.dossierJuridiqueForm.valid === false) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.toastr.error('Veuillez remplir les champs convenablement.', ' Erreur !', {progressBar: true});
      }, 3000);
    }else {
      this.loading = true;
      const formData = this.dossierJuridiqueForm.getRawValue();
      formData.numeroDossier = dossierNumber;
      console.log('Valeur de formData ++++==> ', formData);
      this.dossiersService.updateDossierParticulier(formData).subscribe(
          (data: any) => {
            this.loading = false;
            this.resetForm();
            this.toastr.success('Dossier audiencer avec succes', 'Succès', {progressBar: true});
            window.location.reload();
          },
          (error: HttpErrorResponse) => {
            this.loading = false;
            this.resetForm();
            this.toastr.error('Echec de l\'enregistrement', 'Erreur', {progressBar: true});
            console.log('Echec atatus ==> ' + error.status);
          });
    }
  }
  resetForm(): void {
    this.dossierJuridiqueForm.reset();
    for (const i in this.dossierJuridiqueForm.controls) {
      this.dossierJuridiqueForm.controls[i].markAsPristine();
      this.dossierJuridiqueForm.controls[i].updateValueAndValidity();
    }
    this.makeForm(null);
  }

}
