<div class="auth-layout-wrap" style="background-color: #1e1f20">
    <div class="auth-content">
<div class="card o-hidden" [@animate]="{value:'*',params:{y:'100px',opacity:'0',delay:'100ms', duration: '400ms'}}">
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div class="p-4">
                <div class="auth-logo text-center mb-4">
                    <!--<img src="assets/images/logoForsetiCom.png" alt="Logo Forseti">-->
                    <!--<img src="assets/images/logoTCC_02.png" alt="Logo TCC" style="width: 275px;height: 60px;margin-top: 15px;margin-bottom: 15px;">
                    <img src="assets/images/logoTCC.png" alt="Logo TCC"
                         style="width: 400px;height: 90px;margin-top: 15px;">-->
                </div>

                <h1 class="mb-2 text-18 font-weight-bold  text-center">S'identifier</h1><br>
                <p class="bg-warning mb-2" style="text-align: center">{{ authenticationFailError }}</p>

                <form [formGroup]="signinForm" (ngSubmit)="signin()">
                    <div class="form-group">
                        <label for="email">Utilisateur</label>
                        <input id="email" class="form-control form-control-rounded" formControlName="email"
                               type="email">
                    </div>

                    <div class="form-group">
                        <label for="password">Mot de passe</label>
                        <input id="password" class="form-control form-control-rounded" formControlName="password"
                               type="password">
                    </div>
                    <br>
                    <!-- <button class="btn btn-rounded btn-primary btn-block mt-2">Sign In</button> -->
                    <!-- <btn-loading btnClass="btn-primary btn-rounded btn-block mt-3" [loadingText]="loadingText"
                      [loading]="loading">
                      Connexion
                    </btn-loading> -->
                    <button class="btn btn-primary btn-rounded w-100" type="submit">
                        <div class="btn-spinner float-left" *ngIf="loading"></div>
                        <span>Connexion</span>
                    </button>
                    <br><br>
                    <a class="btn btn-outline-warning btn-rounded w-100" style="border-color: #287fb8;"
                       routerLink="../search">
                        <span>Recherche de dossier</span>
                    </a>

                </form>
                <br>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mt-3 text-center">
                            <a style="text-decoration: none;"
                               routerLink="/forgot-password"><u>Mot de passe oublié</u></a>
                        </div>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                        <div class="mt-3 text-center" style="float: right;">
                            <a routerLink="/particulier/particulier-infos" target="_blank"><u>Petites Créances</u></a>
                        </div>
                    </div>
                    <!-- <div class="col-md-4" style="text-align: right;">
                        <div class="mt-3 text-center" style="float: right;">
                            <a routerLink="/audience/calendrier-audience" target="_blank"><u>Calendrier</u></a>
                        </div>
                    </div> -->
                </div>

            </div>
            
            <!--    <div class="col-md-6 text-center " style="background-size: cover;background-image: url(./assets/images/photo-long-3.jpg)">
            <div class="pr-3 auth-right">
              <button class="btn btn-rounded btn-outline-primary btn-outline-email btn-block btn-icon-text" routerLink="/sessions/signup">
                <i class="i-Mail-with-At-Sign"></i>
                Sign up with Email
              </button>
              <button class="btn btn-rounded btn-outline-primary btn-outline-google btn-block btn-icon-text">
                <i class="i-Google-Plus"></i>
                Sign up with Google
              </button>
              <button class="btn btn-rounded btn-outline-primary btn-block btn-icon-text btn-outline-facebook">
                <i class="i-Facebook-2"></i>
                Sign up with Facebook
              </button>
            </div>
          </div>-->
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 mb-2">
            <div class="float-left">
                <div class="d-flex align-items-center">
                    <!--<p class="ml-2" style="font-weight: bold">Financement : </p> <h5 > BANQUE MONDIALE</h5>-->
                    <img src="./assets/images/logoBM.png" id="bm" alt="Logo Banque Mondiale"
                         style="width: 10rem; height: 2rem" class="ml-2">
                </div>
            </div>
            <div class="float-right">
                <div class="d-flex align-items-center">
                    <!--<img class="logo" src="./assets/images/forsetilogo.png" alt="">-->
                    <img src="./assets/images/pero.png" id="pero" alt="Logo PeroGroupe">
                    <div>
                        <!--<p class="m-0">&copy; PERO GROUPE {{date}} Forseti </p>-->
                        <!--<p class="m-0">&copy; Tous droits réservés</p>-->
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</div>
</div>
<app-search *ngIf="searchService.searchOpen"></app-search>
