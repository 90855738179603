import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpRequest, HttpResponse} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import { Nationalite } from 'src/app/models/gestion/parametrage/nationalite';
import { NationaliteService } from 'src/app/services/gestion/parametrage/nationalite.service';
import {PersonnePhysique} from "../../models/gestion/personnes/personne-physique";
import {PersonnePhysiqueService} from "../../services/gestion/personnes/personne-physique.service";
import {ProfessionService} from "../../services/gestion/parametrage/profession.service";
import {Profession} from "../../models/gestion/parametrage/profession";
import { SexeService } from 'src/app/services/gestion/parametrage/sexe.service';
import { SituationMatrimonialeService } from 'src/app/services/gestion/parametrage/situation-matrimoniale.service';
import { Sexe } from 'src/app/models/gestion/parametrage/sexe';
import { SituationMatrimoniale } from 'src/app/models/gestion/parametrage/situation-matrimoniale';

@Component({
  selector: 'app-ajouter-avocat',
  templateUrl: './ajouter-avocat.component.html',
  styleUrls: ['./ajouter-avocat.component.css']
})
export class AjouterAvocatComponent implements OnInit {

  
  addAvocatForm: FormGroup;

  nationaliteList: Nationalite[] = [];
  professionList: Profession[] = [];
  sexeList: Sexe[] = [];
  situationMatrimonialeList: SituationMatrimoniale[] = [];

  constructor(
    private nationaliteService: NationaliteService,
    private personnePhysiqueService: PersonnePhysiqueService,
    private professionService: ProfessionService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private sexeService: SexeService,
    private situationMatrimonialeService: SituationMatrimonialeService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.sexeService.list().subscribe(
      (data: any) => {
        this.sexeList = [...data];
        console.log(this.sexeList);
      },
      (error: HttpErrorResponse) => {
        console.log('Echec status ==> '+error.status);
      });

      
      this.situationMatrimonialeService.list().subscribe(
        (data: any) => {
            this.situationMatrimonialeList = [...data];
            console.log(this.situationMatrimonialeList);
        },
        (error: HttpErrorResponse) => {
            console.log('Echec status ==> ' + error.status);
        }
    );

    //Nationalite  list 
    this.nationaliteService.list().subscribe(
      (data: any) => {
        this.nationaliteList = [...data];
        console.log("Nationalité",this.nationaliteList);
      },
      (error: HttpErrorResponse) => {
        console.log('Echec atatus ==> '+error.status);
      });

      // profession list
      this.professionService.list().subscribe(
        (data: any) => {
          this.professionList = data;
        });

        this.makeFormPersonnePhysique(null);

  }

  makeFormPersonnePhysique(personne: PersonnePhysique): void {
    this.addAvocatForm = this.fb.group({
      id: [personne != null ? personne.id : null],
      civilite: [personne != null ? personne.civilite : null, [Validators.required]],
      datenaissance: [personne != null ? personne.datenaissance : null],
      dateprestation: [personne != null ? personne.dateprestation : null],
      fonction: [personne != null ? personne.fonction : null],
      //nationalite: [personne != null ? personne.nationalite : null],
      nom: [personne != null ? personne.nom : null, [Validators.required]],
      prenom: [personne != null ? personne.prenom : null],
      nomjeunefille: [personne != null ? personne.nomjeunefille : null],
      nommere: [personne != null ? personne.nommere : null],
      prenommere: [personne != null ? personne.prenommere : null],
      nompere: [personne != null ? personne.nompere : null],
      prenompere: [personne != null ? personne.prenompere : null],
      professionCode: [personne != null ? personne.professionCode : null],
      professionId: [personne != null ? personne.professionId : null],
      nationaliteCode: [personne != null ? personne.nationaliteCode : null],
      nationaliteId: [personne != null ? personne.nationaliteId : null],
      sexeCode: [personne != null ? personne.sexeCode : null],
      sexeId: [personne != null ? personne.sexeId : null],
      //sexe: [personne != null ? personne.sexe : null],
      situationmatrimonialeId: [personne != null ? personne.situationmatrimonialeId : null],
      situationmatrimonialeCode: [personne != null ? personne.situationmatrimonialeCode : null],
      telephone: [personne != null ? personne.telephone : null],
      email: [personne != null ? personne.email : null],
    });
  }

  submitedPersonnePhysiqueForm(status: boolean): void {

    if (status == true) {
      const formData = this.addAvocatForm.value;
      formData.datenaissance = new Date(formData.datenaissance);
      formData.dateprestation = new Date(formData.dateprestation);

      this.personnePhysiqueService.createPersonne(formData).subscribe(
        (data: any) => {
           
                console.log('Les personne physiques');
                console.log(data);
                //this.avocatPersonnePhysiqueListe = data.filter(a => a.professionCode == 'AVT');
                this.toastr.success('Avocat ajouté avec succès !', ' Success !', {progressBar: true});
                this.resetForm();
                if (data == null ) {
                  this.toastr.info('La création du partie n\'a pas aboutie ! ', ' Erreur !', {progressBar: true});
                }
                this.activeModal.close(data);
        },
        (error: HttpErrorResponse) => {
          this.toastr.error('Echec lors de la création du partie !', ' Erreur !', {progressBar: true});
          console.log('Echec atatus ==> ' + error.status);
        });
      
    }
    else{
      this.activeModal.close(null)
    }

  }

  resetForm(): void {
    this.addAvocatForm.reset();
    for (const key in this.addAvocatForm.controls) {
      this.addAvocatForm.controls[key].markAsPristine();
      this.addAvocatForm.controls[key].updateValueAndValidity();
    }
    this.makeFormPersonnePhysique(null);
  }


}
