import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BienJuridique } from "src/app/models/gestion/dossier-juridique/bien-juridique";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BienJuridiqueService {
  url: string = environment.backend + '/biens';

  constructor(private http: HttpClient) {
  }

  createBien(bienJuridique: BienJuridique): Observable<Object> {
    return this.http.post(`${this.url}`, bienJuridique);
  }

  deleteBien(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateBien(bienJuridique: BienJuridique): Observable<Object> {
    return this.http.put(`${this.url}`, bienJuridique);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

}
