import {IMenuItem} from "../../services/common/navigation.service";

export class ComptableHabilitation {
  static menuList: IMenuItem[] = [
    {
        name: 'Dossiers non payés',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        type: 'link',
        icon: 'i-Bar-Chart',
        state: '/gestion/comptabilite/dossier-impaye'
      },
      {
        name: 'Dossiers payés',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        type: 'link',
        icon: 'i-Receipt-3',
        state:'/gestion/comptabilite/dossier-paye'
      },
      
    ];
}
