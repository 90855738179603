<div class="container">
    <editor apiKey="f7tso3h0optrv5dlqbidvmb9v8a16fnnxmb73lvpkaq65hde"
            [(ngModel)]="content"
            [init]="{
          height: 800,
          plugins: 'advlist autolink lists link image charmap preview anchor textcolor ',
          toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help '
        }"
    ></editor>
    <div class="button-group">
<!--        <button id="save-btn" (click)="saveContentAsXml()">Enregistrer</button>-->
        <button id="validate-btn" (click)="saveContent()" *ngIf="isJudge()">Valider la décision</button>
        <button id="close-btn" (click)="closeContent()">Fermer</button>
    </div>
</div>

<ng-template #agreedUserDecision let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Validation de la décision</h4>
        <button
                (click)="modal.dismiss('Cross click')"
                aria-describedby="modal-title"
                aria-label="Close button"
                class="close"
                type="button"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            <strong>
                Etes-vous sûr de vouloir valider la décision ?
                <span class="text-primary"> </span> ?</strong>
        </p>
    </div>
    <div class="modal-footer">
        <button
                (click)="modal.dismiss('cancel')"
                class="btn btn-outline-danger btn-rounded"
                type="button"
        >
            Non, je ne valide pas la décision
        </button>
        <button
                (click)="modal.close('Ok')"
                class="btn btn-success btn-rounded"
                ngbAutofocus
                type="button"
        >
           Oui, je valide la décision
        </button>
    </div>
</ng-template>
