import {Component, OnInit} from '@angular/core';
import {TransferDataService} from "../../services/common/transfer-data.service";
import {DossiersService} from "../../services/gestion/dossier-juridique/dossiers.service";
import {ProgressionDossier} from "../../models/gestion/dossier-juridique/progression-dossier";
import {PartieJuridique} from "../../models/gestion/dossier-juridique/partie-juridique";
import {DossierJuridique} from "../../models/gestion/dossier-juridique/dossier-juridique";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";


@Component({
    selector: 'app-projection-dossier',
    templateUrl: './projection-dossier.component.html',
    styleUrls: ['./projection-dossier.component.css']
})
export class ProjectionDossierComponent implements OnInit {

    audienceId

    progressionDossierListByAudienceId: ProgressionDossier[] = [];
    progressionDossierListByAudienceIdEncours: ProgressionDossier[] = [];
    progressionDossierListByAudienceIdEnAttente: ProgressionDossier[] = [];
    progressionDossierListByAudienceIdTermine: ProgressionDossier[] = [];
    progressionDossierListByAudienceIdEncoursUpdate: ProgressionDossier[] = [];
    dossierEncours: DossierJuridique[] = [];


    constructor(
        private transferDataService: TransferDataService,
        private dossiersService: DossiersService,
        private router: Router,
        private modalService: NgbModal,
    ) {
    }

    ngOnInit(): void {
        this.audienceId = this.transferDataService.getAudienceData();

        if (this.audienceId) {
            this.router.navigate(['gestion/audience/progression-audience']);

        }

        this.dossiersService.listProgressionDossierByAudienceId(this.audienceId?.id).subscribe(
            (data: any) => {
                this.progressionDossierListByAudienceId = [...data];
                console.log("PDLA", this.progressionDossierListByAudienceId);

            });

        this.showDossierEnAttente();
        this.showDossierEncours();
        this.showDossierTermine();

        /*this.dossiersService.listProgressionDossierByAudienceId(this.audienceId.id).subscribe(
          (data: any) => {
            this.progressionDossierListByAudienceIdEnAttente = [];
            data.forEach(attente => {
              if(attente.progressiondossier.etat.libelle == "En attente")
              {
                this.progressionDossierListByAudienceIdEnAttente.push(attente);
              }

            });

            console.log("En attente", this.progressionDossierListByAudienceIdEnAttente);

          });*/

        /* this.dossiersService.listProgressionDossierByAudienceId(this.audienceId.id).subscribe(
           (data: any) => {
             this.progressionDossierListByAudienceIdEncours = [];
             data.forEach(encours => {
               if(encours.progressiondossier.etat.libelle == "En cours")
               {
                 this.progressionDossierListByAudienceIdEncours.push(encours);
                 this.progressionDossierListByAudienceIdEncoursUpdate.push(encours.progressiondossier);

               }


             });

             console.log("En cours", this.progressionDossierListByAudienceIdEncours);
           });*/

        /*this.dossiersService.listProgressionDossierByAudienceId(this.audienceId.id).subscribe(
          (data: any) => {
            this.progressionDossierListByAudienceIdTermine = [];
            data.forEach(termine => {
              if(termine.progressiondossier.etat.libelle == "Terminé")
              {
                this.progressionDossierListByAudienceIdTermine.push(termine);
              }
             
            });
            
            console.log("Terminé", this.progressionDossierListByAudienceIdTermine);
          });*/


    }

    backToAudience(): void {
        this.router.navigate(['gestion/audience/progression-audience']);
    }

    //fonction de progressionDossier encours
    showDossierEncours() {
        this.dossiersService.listProgressionDossierByAudienceId(this.audienceId?.id).subscribe(
            (data: any) => {
                this.progressionDossierListByAudienceIdEncours = [];
                data.forEach(encours => {
                    if (encours.progressiondossier.etat.libelle == "En cours") {
                        this.progressionDossierListByAudienceIdEncours.push(encours);
                        this.progressionDossierListByAudienceIdEncoursUpdate.push(encours.progressiondossier);
                        this.dossierEncours.push(encours.dossier);

                    }


                });

                console.log("En cours", this.progressionDossierListByAudienceIdEncours);
            });
    }

    //fonction de progressionDossier en attente
    showDossierEnAttente() {
        this.dossiersService.listProgressionDossierByAudienceId(this.audienceId?.id).subscribe(
            (data: any) => {
                this.progressionDossierListByAudienceIdEnAttente = [];
                data.forEach(attente => {
                    if (attente.progressiondossier.etat.libelle == "En attente") {
                        this.progressionDossierListByAudienceIdEnAttente.push(attente);
                    }

                });

                console.log("En attente", this.progressionDossierListByAudienceIdEnAttente);

            });
    }

    //fonction de progressionDossier terminé
    showDossierTermine() {
        this.dossiersService.listProgressionDossierByAudienceId(this.audienceId?.id).subscribe(
            (data: any) => {
                this.progressionDossierListByAudienceIdTermine = [];
                data.forEach(termine => {
                    if (termine.progressiondossier.etat.libelle == "Terminé") {
                        this.progressionDossierListByAudienceIdTermine.push(termine);
                    }

                });

                console.log("Terminé", this.progressionDossierListByAudienceIdTermine);
            });

    }


    getPartieByTypeLibelle(partiesJuridiqueList: PartieJuridique[], type: string): PartieJuridique[] {
        let parties: PartieJuridique[] = [];
        if (partiesJuridiqueList.length > 0 && partiesJuridiqueList != null) {
            partiesJuridiqueList.forEach(p => {
                if (p.typePartieLibelle == type) {
                    parties.push(p);
                }
            });
        }
        parties = [...parties];
        // console.log(parties);
        return parties;
    }

    updatedEtatProgressionDossier(progressionDossierUpdate: ProgressionDossier): void {

        let pgEncours: ProgressionDossier = new ProgressionDossier();
        pgEncours = this.progressionDossierListByAudienceIdEncoursUpdate[0];
        if (pgEncours != null && pgEncours != undefined) {
            console.log("pgEncours ==> ");

            //Terminé le dossier encours
            this.dossiersService.updatedProgressionDossierEtat(pgEncours.id, 12053, pgEncours).subscribe(
                (data: any) => {

                });
            // Mettre en attente encours
            this.dossiersService.updatedProgressionDossierEtat(progressionDossierUpdate.id, 9152, progressionDossierUpdate).subscribe(
                (data: any) => {

                });
            this.showDossierEnAttente();
            this.showDossierEncours();
            this.showDossierTermine();
        }


    }

// Show Modal Note à lire
    showNoteDossierEncours(content) {

        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true})
            .result.then((result) => {

        }, (reason) => {
            console.log(`Dismissed with: ${reason}`);
        });
    }

    gererTermine() {

    }

    gererRemettreEnAttente() {

    }
}
