import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.css']
})
export class MessageInfoComponent implements OnInit {


  @Input() msgToDisplay?: string;

  @Input() typeMsg?: number;
  //typeMsg == 0 Info; typeMsg == 1 Warning; typeMsg == 2 Danger

  constructor() { }

  ngOnInit(): void {
  }

}
