<div *ngIf="dossierJuridique != null">
  <!-- <h3> AGENDA DU DOSSIER : <strong> {{ dossierJuridique.numerorg }}  </strong></h3>
    <br>-->

  <div>
    <div class="row">
      <div class="col-md-3">
        <strong>Mode de saisine : </strong><br />
        {{ dossierJuridique.origineProcedureLibelle }}
      </div>
      <div class="col-md-3">
        <strong>Type de procédure : </strong> <br />
        {{ dossierJuridique.typeProcedureLibelle }}
      </div>
      <div class="col-md-6">
        <strong>Objet(s) de la demande : </strong><br />
        <span *ngFor="let nac of nacChoicedList; last as isLast">
          {{ nac?.libelle }}
          <span *ngIf="!isLast">; &nbsp; </span>
        </span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-header">
            <h5>Demandeur(s)</h5>
          </div>
          <div class="card-body">
            <!--<h5 class="card-title">Card title text</h5>
              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>-->
            <ul>
              <li *ngFor="let row of partiesChoicedDemandeurList">
                {{
                  row.personnephysique != null
                    ? row.personnephysique.nom +
                      " " +
                      row.personnephysique.prenom
                    : ""
                }}
                {{
                  row.personnemorale != null
                    ? row.personnemorale.raisonsociale
                    : ""
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-header">
            <h5>Défendeur(s)</h5>
          </div>
          <div class="card-body">
            <ul>
              <li *ngFor="let row of partiesChoicedDefendeurList">
                {{
                  row.personnephysique != null
                    ? row.personnephysique.nom +
                      " " +
                      row.personnephysique.prenom
                    : ""
                }}
                {{
                  row.personnemorale != null
                    ? row.personnemorale.raisonsociale
                    : ""
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <br />
  </div>
  <br />
  <ul #nav="ngbNav" [(activeId)]="activeTabsNav" class="nav-tabs" ngbNav>
    <li [ngbNavItem]="1">
      <a ngbNavLink>Liste des instructions</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-md-12 mb-2">
            <button
              [disabled]="!agendaList?.length"
              (click)="imprimerAgenda()"
              class="btn btn-save mb-2"
              type="button"
            >
              Imprimer le calendrier d'instruction
            </button>
            <br />
            <div class="card o-hidden">
              <ngx-datatable
                [messages]="{ emptyMessage: 'Aucune donnée à afficher!' }"
                [columnMode]="'force'"
                [footerHeight]="50"
                [headerHeight]="50"
                [rowHeight]="'auto'"
                [rows]="agendaList"
                class="material"
              >
                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14">Date</span>
                  </ng-template>
                  <ng-template
                    let-row="row"
                    let-rowIndex="rowIndex"
                    ngx-datatable-cell-template
                  >
                    {{ row.date | date : "dd-MM-yyyy" }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14">Événement</span>
                  </ng-template>
                  <ng-template
                    let-row="row"
                    let-rowIndex="rowIndex"
                    ngx-datatable-cell-template
                  >
                    {{ row.evenement.libelle }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14"
                      >Partie concernée</span
                    >
                  </ng-template>
                  <ng-template
                    let-row="row"
                    let-rowIndex="rowIndex"
                    ngx-datatable-cell-template
                  >
                    <ul>
                      <li *ngFor="let part of row.parties">
                        {{
                          part?.personnephysique
                            ? part.personnephysique.nom +
                              " " +
                              part.personnephysique.prenom
                            : ""
                        }}
                        {{
                          part?.personnemorale
                            ? part.personnemorale.raisonsociale
                            : ""
                        }}
                      </li>
                    </ul>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14">Observation</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14">État</span>
                  </ng-template>
                  <ng-template
                    let-row="row"
                    let-rowIndex="rowIndex"
                    ngx-datatable-cell-template
                  >
                    <strong style="color: red" *ngIf="!row.etatProgression"
                      >NON EFFECTUÉ</strong
                    >
                    <strong style="color: green" *ngIf="row.etatProgression"
                      >EFFECTUÉ</strong
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="serviceAgendaCode != 'CACPC'">
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14">Actions</span>
                  </ng-template>
                  <ng-template
                    let-row="row"
                    let-rowIndex="rowIndex"
                    ngx-datatable-cell-template
                  >
                    <button
                      (click)="deleteAgendaAudience(row)"
                      class="btn btn-outline-danger btn-sm m-1"
                      type="button"
                      *ngIf="!row.etatProgression"
                    >
                      <i class="i-Close-Window"></i>
                    </button>
                    <span *ngIf="row.etatProgression">
                      <strong> - </strong>
                    </span>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </ng-template>

      <!--            <ng-template ngbNavContent>-->
      <!--                <div class="row">-->
      <!--                    <div class="col-md-12 mb-2">-->
      <!--                        <button [disabled]="agendaList == null || agendaList.length < 1" (click)="imprimerAgenda()" class="btn btn-save mb-2" type="button">-->
      <!--                            Imprimer le calendrier d'instruction:::::::::-->
      <!--                        </button>-->
      <!--                        <br>-->
      <!--                        <div class="card  o-hidden">-->
      <!--                            <ngx-datatable-->
      <!--                                           [messages]="{emptyMessage: 'Aucune donnée à afficher!'}"-->
      <!--                                           [columnMode]="'force'"-->
      <!--                                           [footerHeight]="50"-->
      <!--                                           [headerHeight]="50"-->
      <!--                                           [rowHeight]="'auto'"-->
      <!--                                           [rows]="agendaList"-->
      <!--                                           class="material">-->
      <!--                                <ngx-datatable-column>-->
      <!--                                    <ng-template ngx-datatable-header-template>-->
      <!--                                        <span class="font-weight-bold text-14"> Date</span>-->
      <!--                                    </ng-template>-->
      <!--                                    <ng-template let-row="row"  let-rowIndex="rowIndex" ngx-datatable-cell-template>-->
      <!--                                        {{ row.date | date : 'dd-MM-yyyy'}}-->
      <!--                                    </ng-template>-->
      <!--                                </ngx-datatable-column>-->
      <!--                                <ngx-datatable-column>-->
      <!--                                    <ng-template ngx-datatable-header-template>-->
      <!--                                        <span class="font-weight-bold text-14"> Evenement</span>-->
      <!--                                    </ng-template>-->
      <!--                                    <ng-template let-row="row"  let-rowIndex="rowIndex" ngx-datatable-cell-template>-->
      <!--                                        {{ row.evenement.libelle }}-->
      <!--                                    </ng-template>-->
      <!--                                </ngx-datatable-column>-->
      <!--                                <ngx-datatable-column>-->
      <!--                                    <ng-template ngx-datatable-header-template>-->
      <!--                                        <span class="font-weight-bold text-14"> Partie concernée</span>-->
      <!--                                    </ng-template>-->
      <!--                                    <ng-template let-row="row"  let-rowIndex="rowIndex" ngx-datatable-cell-template>-->
      <!--                                        {{ row.parties ? '' : '-' }}-->
      <!--                                        {{row.parties}}-->
      <!--                                        <ul>-->
      <!--                                            <li *ngFor="let part of row.parties">  -->
      <!--                                                {{ part?.personnephysique != null ? (part.personnephysique.nom + ' ' +-->
      <!--                                                part.personnephysique.prenom) : ''}}-->
      <!--                                                {{ part?.personnemorale != null ? (part.personnemorale.raisonsociale) : '' }}-->
      <!--                                            </li>-->
      <!--                                        </ul>-->
      <!--                                        &lt;!&ndash; {{ row.partie ? '' : '-' }} &ndash;&gt;-->

      <!--                                        &lt;!&ndash; {{ row.partie?.personnephysique != null ? (row.partie.personnephysique.nom + ' ' +-->
      <!--                                        row.partie.personnephysique.prenom) : ''}}-->
      <!--                                        {{ row.partie?.personnemorale != null ? (row.partie.personnemorale.raisonsociale) : '' }} &ndash;&gt;-->

      <!--                                        &lt;!&ndash;{{ row.partie.personnephysique != null ? (row.partie.personnephysique.nom + ' ' +-->
      <!--                                        row.partie.personnephysique.prenom) : '' }} {{ row.partie.personnemorale != null ?-->
      <!--                                            (row.partie.personnemorale.raisonsociale) : '' }}&ndash;&gt;-->
      <!--                                    </ng-template>-->
      <!--                                </ngx-datatable-column>-->
      <!--                                <ngx-datatable-column  name="observation">-->
      <!--                                    <ng-template ngx-datatable-header-template>-->
      <!--                                        <span class="font-weight-bold text-14">  Observation</span>-->
      <!--                                    </ng-template>-->
      <!--                                </ngx-datatable-column>-->
      <!--                                <ngx-datatable-column>-->
      <!--                                    <ng-template ngx-datatable-header-template>-->
      <!--                                        <span class="font-weight-bold text-14">  Etat</span>-->
      <!--                                    </ng-template>-->
      <!--                                    <ng-template let-row="row"  let-rowIndex="rowIndex" ngx-datatable-cell-template>-->
      <!--                                        <strong style="color: red;" *ngIf="row.etatProgression  == false"> NON ÉFFECTUÉ</strong>-->
      <!--                                        <strong style="color: green;" *ngIf="row.etatProgression  == true"> ÉFFECTUÉ </strong>-->
      <!--                                    </ng-template>-->
      <!--                                </ngx-datatable-column>-->
      <!--                                <ngx-datatable-column *ngIf="serviceAgendaCode == 'NG_ME'">-->
      <!--                                    <ng-template ngx-datatable-header-template>-->
      <!--                                        <span class="font-weight-bold text-14"> Actions</span>-->
      <!--                                    </ng-template>-->
      <!--                                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>-->
      <!--                                        <button (click)="deleteAgendaAudience(row)"-->
      <!--                                                class="btn btn-outline-danger btn-sm m-1"-->
      <!--                                                type="button" *ngIf="row.etatProgression  == false">-->
      <!--                                            <i class="i-Close-Window"> </i>-->
      <!--                                        </button>-->
      <!--                                        <span *ngIf="row.etatProgression  == true"> <strong> - </strong> </span>-->
      <!--                                    </ng-template>-->
      <!--                                </ngx-datatable-column>-->
      <!--                            </ngx-datatable>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </ng-template>-->
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Nouvelle instruction</a>
      <ng-template ngbNavContent>
        <div class="mb-2">
          <form [formGroup]="validateFormAudience">
            <div class="row">
              <div
                class="card-body row"
                style="
                  border-radius: 10px;
                  box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%),
                    0 1px 4px rgb(0 0 0 / 8%);
                  border: 0;
                  margin: 6px;
                "
              >
                <div
                  class="col-md-12 mb-3"
                  style="
                    border-bottom: 1px solid #eee;
                    border-top-left-radius: 0.3rem;
                    border-top-right-radius: 0.3rem;
                  "
                >
                  <h5 class="card-title">Programme</h5>
                </div>

                <div class="col-md-4 form-group mb-3">
                  <label>Salle</label>
                  <ng-select
                    [compareWith]="compareFn"
                    formControlName="salle"
                    bindLabel="libelle"
                    placeholder="Entrer la salle"
                  >
                    <ng-option *ngFor="let salle of salleList" [value]="salle">
                      {{ salle.libelle }}</ng-option
                    >
                  </ng-select>
                  <div
                    *ngIf="
                      validateFormAudience.controls.salle.errors &&
                      (validateFormAudience.controls.salle.dirty ||
                        validateFormAudience.controls.salle.touched)
                    "
                    class="error-field"
                  >
                    Ce champs est obligatoire !
                  </div>
                </div>

                <div class="col-md-5 form-group mb-3">
                  <label>Évènement prévu</label>
                  <ng-select
                    [compareWith]="compareFn"
                    formControlName="evenement"
                    id="salleId"
                    name="salleId"
                    placeholder="Entrer l'évenement"
                  >
                    <ng-option
                      *ngFor="let evenementp of evenementList"
                      [value]="evenementp"
                    >
                      {{ evenementp.libelle }}</ng-option
                    >
                  </ng-select>
                  <div
                    *ngIf="
                      validateFormAudience.controls.evenement.errors &&
                      (validateFormAudience.controls.evenement.dirty ||
                        validateFormAudience.controls.evenement.touched)
                    "
                    class="error-field"
                  >
                    Ce champs est obligatoire !
                  </div>
                </div>

                <div class="col-md-3 form-group mb-3">
                  <label for="date"
                    >Date<span class="required-field">*</span>
                  </label>
                  <input
                    class="form-control"
                    formControlName="date"
                    id="date"
                    type="date"
                  />
                  <div
                    *ngIf="
                      validateFormAudience.controls.date.errors &&
                      (validateFormAudience.controls.date.dirty ||
                        validateFormAudience.controls.date.touched)
                    "
                    class="error-field"
                  >
                    Ce champs est obligatoire !
                  </div>
                </div>

                <div class="col-md-12 form-group mb-3">
                  <label for="partie">Partie concernée</label>
                  <!--<ng-select [compareWith]="compareFn" formControlName="partie" id="partie"
                                               name="partie" placeholder="Sélectionner la patie concernée">
                                        <ng-option *ngFor="let row of partiesConcerneList" [value]="row">
                                            {{ row.personnephysique != null ? (row.personnephysique.nom + ' ' +
                                            row.personnephysique.prenom) : '' }}
                                            {{ row.personnemorale != null ? (row.personnemorale.raisonsociale) :'' }} 
                                            {{ row.typePartieLibelle == 'DEMANDEUR' ? ' (DEMANDEUR)' : ' (DEFENDEUR)' }} 
                                        </ng-option>
                                    </ng-select>-->

                  <ng-select
                    [compareWith]="compareFn"
                    [multiple]="true"
                    bindLabel="nom"
                    formControlName="partie"
                    id="partie"
                    name="partie"
                  >
                    <ng-option
                      *ngFor="let row of partiesConcerneList"
                      [value]="row"
                    >
                      {{
                        row.personnephysique != null
                          ? row.personnephysique.nom +
                            " " +
                            row.personnephysique.prenom
                          : ""
                      }}
                      {{
                        row.personnemorale != null
                          ? row.personnemorale.raisonsociale
                          : ""
                      }}
                      {{
                        row.typePartieLibelle == "DEMANDEUR"
                          ? " (DEMANDEUR)"
                          : " (DEFENDEUR)"
                      }}
                    </ng-option>
                  </ng-select>

                  <div
                    *ngIf="
                      validateFormAudience.controls.partie.errors &&
                      (validateFormAudience.controls.partie.dirty ||
                        validateFormAudience.controls.partie.touched)
                    "
                    class="error-field"
                  >
                    Ce champs est obligatoire !
                  </div>
                </div>

                <div class="col-md-12 form-group mb-3">
                  <label for="observation">Observation(s)</label>
                  <textarea
                    class="form-control"
                    formControlName="observation"
                    id="observation"
                    placeholder="Entrer l'observation "
                    type="textarea"
                  >
                  </textarea>
                </div>
              </div>
              <hr />

              <div class="col-md-12" style="margin-top: 1px">
                <btn-loading
                  (click)="enregistrerAll()"
                  [loading]="isLoadingData"
                  btnClass="btn-save"
                  class="float-right"
                  >Enregistrer
                </btn-loading>
                <button
                  (click)="resetForm()"
                  class="btn m-1 ml-2 btn-cancel"
                  type="button"
                >
                  Annuler
                </button>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
