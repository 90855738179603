import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Console } from "console";
import { NzButtonSize } from "ng-zorro-antd/button";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Discussion } from "src/app/models/common/discussion";
import { User } from "src/app/models/gestion/utilisateur/user";
import { AuthService } from "src/app/services/common/auth.service";
import { ChatService } from "src/app/services/common/chat.service";
import { WebsocketService } from "src/app/services/common/websocket.service";
import { DossierAuxilliaireService } from "src/app/services/gestion/dossier-juridique/dossier-auxilliaire.service";
import { DossiersService } from "src/app/services/gestion/dossier-juridique/dossiers.service";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
  @ViewChild("chatContainer") chatContainer: ElementRef;
  @ViewChild("personElement") personElement: ElementRef;

  audienceIdChoice: number = null;
  userIdChoice: number = null;
  dossierIdChoice: number = null;

  dossierId: number = 0;
  messagesCount: { [key: number]: number } = {};
  lu: boolean = false;

  selectedPersonId: number | null = null; // Ajoutez cette ligne
  selectedPerson = null;
  messages: Discussion[] = [];
  message: any;
  newMessage = "";
  isChecked = false;
  userList: any[] = [];
  currentIntervenant: any;
  discussion: any;
  expediteur: number = 0;
  currentUser: User;
  unreadCount: number = 0;
  dossier: any;
  searchTerm: string = "";
  userListFiltered: any[] = [];
  size: NzButtonSize = "large";
  radioValue = "A";
  returnToPublic: boolean = false;
  discussionDto: Discussion = new Discussion();
  listDiscussion: Discussion[] = [];
  isSendingMessage: boolean = false;
  receveur: number = 0;
  privateMessage: boolean = false;
  nbNotif: number = 0;
  selectedItems: Set<number> = new Set();
  selectedPersonIds: Set<number> = new Set();
  selectedPersons: Set<User> = new Set();
  select: string = "Tous séléctionner";

  constructor(
    private dossierAuxilliaireService: DossierAuxilliaireService,
    private chatService: ChatService,
    private auth: AuthService,
    private dossierService: DossiersService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private webSocketService: WebsocketService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.scrollToBottom(); // Testez le défilement après la vue initiale
  }

  ngOnInit(): void {
    this.webSocketService.joinRoom("chat");
    this.route.params.subscribe((params) => {
      this.dossierId = params["id"];
    });
    const token = localStorage.getItem("access_token");
    if (token) {
      this.currentUser = this.auth.getUserFromJwtToken(token);
      if (this.currentUser != null) {
        this.expediteur = this.currentUser.id;
      }
    }

    this.webSocketService.notificationSubject.subscribe((notification) => {
      if (notification.receveur === this.expediteur) {
        // Vérifiez si le 'receveur' correspond à l'expéditeur
        if (notification.receveur === this.expediteur) {
          this.nbNotif++; // Incrémente le compteur de notifications
          console.log("Nombre de notifications :", this.nbNotif); // Log le nombre actuel de notifications

          this.getNombreMessage(notification.expediteur).subscribe((count) => {
            this.messagesCount[notification.expediteur] = count;
          });

          // // Affiche le message de notification
          // this.toastr.success(
          //   `${notification.fonction} : ${notification.expediteurName} a envoyé un nouveau message sur le dossier numéro : ${this.dossier?.numerorg}`,
          //   "Succès !", // Message du toast
          //   { progressBar: true }
          // );
        }
      }
    });

    this.getDossier(this.dossierId);
    this.getUserList();
    this.getCurrentIntervenant();
    this.loadMessages();
  }

  selectPersons(event: MouseEvent, person) {
    if (event.ctrlKey || event.metaKey) {
      // Si Ctrl est maintenu, on toggle la sélection

      if (this.selectedPersons.has(person)) {
        this.selectedPersons.delete(person);
        //this.selectedPersonId = 0;
      } else {
        // this.selectedPersonIds.add(person.id);
        this.selectedPersons.add(person);
      }
    } else {
      this.selectedPersons.clear();
      this.selectedPersons.add(person);
      this.selectedPerson = person;
      this.getChatList(this.selectedPerson?.id);
      this.getUserList();
      this.selectedPersonId = person.id; // Mettez à jour l'ID de la personne sélectionnée
      this.privateMessage = true;
    }
  }

  get selectedPersonsArray() {
    return Array.from(this.selectedPersons); // Convertit le Set en tableau pour les opérations
  }

  selectAll() {
    if (this.selectedPersons.size === this.userListFiltered.length) {
      // Si toutes les personnes sont déjà sélectionnées, on les désélectionne
      this.selectedPersons.clear();
      this.select = "Sélectionner Tous";
    } else {
      this.select = "Désélectionner Tous";
      // Sinon, on sélectionne toutes les personnes
      this.userListFiltered.forEach((person) =>
        this.selectedPersons.add(person)
      );
    }
  }

  isSelected(person: any): boolean {
    return this.selectedPersons.has(person);
  }

  clearSelection() {
    this.selectedPersons.clear();
  }

  loadMessages() {
    this.webSocketService.getMessageSubject().subscribe((newMessage) => {
      // Si this.messages est déjà défini, ajoutez le nouveau message à la liste

      console.log("Nouveau message: ", newMessage);

      this.messages = newMessage.map((item: Discussion) => ({
        ...item,
        type: item.expediteur === this.expediteur ? "me" : "you",
      }));

      // this.messages.forEach((message) => {
      //   this.getNombreMessage(message?.expediteur).subscribe((count) => {
      //     this.messagesCount[message?.expediteur] = count;
      //   });
      // });

      if (this.selectedPerson?.id != null) {
        this.getChatList(this.selectedPerson?.id);
        this.scrollToBottom();
        this.cdr.detectChanges();
      }
    });
  }

  getUserList(): void {
    this.dossierAuxilliaireService
      .getAllIntervenant(this.dossierId, this.expediteur)
      .subscribe(
        (data: any) => {
          this.userList = data;
          this.userListFiltered = data;
          console.log("Liste des utilisateurs: ", this.userList);
          this.userList.forEach((user) => {
            this.getNombreMessage(user?.id).subscribe((count) => {
              this.messagesCount[user?.id] = count;
            });
          });
        },
        (error) => {
          this.userList = [];
          console.log(
            "Erreur lors de la récupération de la liste des utilisateurs: ",
            error
          );
        }
      );
  }

  getCurrentIntervenant(): void {
    console.log("Intervent current", this.expediteur);

    console.log(
      "L'identifiant de l'utilisateur courant est: ",
      this.expediteur
    );
    console.log("L'identifiant du dossier est: ", this.dossierId);

    this.dossierAuxilliaireService
      .getCurrentIntervenant(this.dossierId, this.expediteur)
      .subscribe(
        (data: any) => {
          this.currentIntervenant = data;
          console.log("Intervenant : ", this.currentIntervenant);
        },
        (error) => {
          this.userList = null;
          console.log("Erreur: ", error);
        }
      );
  }

  search(): void {
    if (this.searchTerm.trim() === "") {
      // Si l'input est vide, réinitialiser la liste filtrée
      this.userListFiltered = this.userList;
    } else {
      // Sinon, filtrer les données
      this.userListFiltered = this.userList.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  getChatList(youId: number): void {
    this.chatService
      .getChatList(this.dossierId, youId, this.expediteur, "chat")
      .subscribe(
        (data: any) => {
          // this.messages = Array.isArray(data) ? data : [];
          this.messages = data;
          this.scrollToBottom();
          this.cdr.detectChanges;
        },
        (error) => {
          this.messages = [];
          console.log("Erreur lors de la récupération des messages: ", error);
        }
      );
    //  this.cdr.detectChanges();
  }

  selectPerson(person) {
    this.selectedPerson = person;
    this.getChatList(this.selectedPerson?.id);
    this.getUserList();
    this.selectedPersonId = person.id; // Mettez à jour l'ID de la personne sélectionnée
    localStorage.setItem("personSelected", JSON.stringify(person));
    this.privateMessage = true;
  }

  sendMessagePrivate(textarea: HTMLTextAreaElement) {
    if (!this.newMessage.trim()) {
      console.warn("Le message ne peut pas être vide.");
      return;
    }

    if (this.selectedPerson == null || this.selectedPersons.size == 0) {
      this.toastr.error(
        "Veuillez sélectionner au moins un destinataire",
        "Erreur"
      );
      return;
    }

    this.discussionDto.dossier = parseInt(this.dossierId.toString(), 10);
    this.discussionDto.expediteur = this.expediteur;
    this.discussionDto.receveur = this.selectedPerson?.id;
    this.discussionDto.message = this.newMessage;
    this.discussionDto.messagePublic = this.isChecked;
    this.discussionDto.room = "chat";
    this.discussionDto.type = "me";
    this.discussionDto.expediteurName = this.currentIntervenant?.name;
    this.discussionDto.fonction = this.currentIntervenant?.fonction;

    if (this.selectedPersons.size == 1) {
      // console.log("c'est rentrer ici en cas d'un seul destinataire");
      this.messages.push(this.discussionDto);
      this.webSocketService.sendMessage("chat", this.discussionDto);
      this.scrollToBottom();
      this.cdr.detectChanges();
    } else {
      console.log("c'est rentrer ici dans envoyer a tous le monde");
      for (let intervenant of this.selectedPersons) {
        if (intervenant?.id != null && this.selectedPerson?.id == null) {
          this.discussionDto.receveur = intervenant?.id;

          this.messages.push(this.discussionDto);
          this.webSocketService.sendMessage("chat", this.discussionDto);
          this.scrollToBottom();
          this.cdr.detectChanges();
        }
      }

      this.toastr.success(
        "Message envoyé aux " +
          this.selectedPersons.size +
          " destinataires avec succès",
        "Success!",
        {
          progressBar: true,
          timeOut: 10000,
        }
      );
    }

    this.newMessage = "";
    textarea.style.height = "auto"; // Réinitialiser la hauteur
  }

  scrollToBottom() {
    if (this.chatContainer) {
      setTimeout(() => {
        this.chatContainer.nativeElement.scrollTop =
          this.chatContainer.nativeElement.scrollHeight;
      }, 0);
    }
  }

  adjustHeight(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto"; // Réinitialiser la hauteur
    const newHeight = Math.min(textarea.scrollHeight, 80); // Ajustez 200 à la hauteur maximale souhaitée
    textarea.style.height = `${newHeight}px`;

    // Ajouter la condition pour afficher le scroll
    if (textarea.scrollHeight > 80) {
      textarea.style.overflowY = "auto"; // Afficher le scroll si le contenu dépasse la hauteur maximale
    } else {
      textarea.style.overflowY = "hidden"; // Cacher le scroll si le contenu est dans la limite
    }
  }

  getNombreMessage(youId: number): Observable<number> {
    console.log("L'identifiant de you est: ", youId);
    return this.chatService
      .getNombreMessage(this.dossierId, youId, this.expediteur, "chat")
      .pipe(
        map((data: any) => {
          return data; // ou le format que vous souhaitez
        }),
        catchError((error) => {
          console.log("Erreur: ", error);
          return of(0); // Retourne 0 en cas d'erreur
        })
      );
  }

  sendNotification() {
    this.webSocketService.sendNotification("Nouvelle notification!");
  }

  getLastMessage(youId: number): Observable<Object> {
    console.log("L'identifiant de you est: ", youId);
    return this.chatService
      .getLastMessage(this.dossierId, youId, this.expediteur, "chat")
      .pipe(
        map((data: any) => {
          console.log("Liste des messages: ", data);
          return data; // ou le format que vous souhaitez
        }),
        catchError((error) => {
          console.log("Erreur: ", error);
          return of(0); // Retourne 0 en cas d'erreur
        })
      );
  }

  sendToPublic(): void {
    this.isChecked = false;
    this.messages = [];
    this.privateMessage = false;
    this.selectedPerson = null;
    //  localStorage.removeItem("personSelected");
  }

  sendToPrivate(): void {
    this.isChecked = true;
    this.getUserList();
    if (this.selectedPerson !== null) {
      this.loadMessages();
    } else {
      // this.selectedPerson = JSON.parse(localStorage.getItem("personSelected"));

      console.log("Person selectionner", this.selectedPerson);
    }
  }

  close() {
    this.router.navigate(["/gestion/audience/audiencement"]);
  }

  getDossier(idDossier: number) {
    this.dossierService.getById(idDossier).subscribe((data: any) => {
      this.dossier = data;
    });
  }

  retunToPublic() {
    this.returnToPublic = true;
  }

  closeChat() {
    this.router.navigate(["gestion/dashboard"]);
  }
}
