import { NoteService } from './../../services/gestion/dossier-juridique/note.service';
import { Note } from './../../models/gestion/dossier-juridique/note';
import { Component, OnInit, Input } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-saisie-note',
  templateUrl: './saisie-note.component.html',
  styleUrls: ['./saisie-note.component.css']
})
export class SaisieNoteComponent implements OnInit {

  @Input() audienceId?: number;
  @Input() userId?: number;
  @Input() dossierId?: number;


  audienceIdChoice: number = null;
  userIdChoice: number = null;
  dossierIdChoice: number = null;

  notetxt: string = '';
  portee: string = '';

  validateForm: FormGroup;
  loading: boolean;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private noteService: NoteService,
   // public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {

    if(this.audienceId != null && this.audienceId != undefined) this.audienceIdChoice = this.audienceId;
    if(this.userId != null && this.userId != undefined) this.userIdChoice = this.userId;
    if(this.dossierId != null && this.dossierId != undefined) this.dossierIdChoice = this.dossierId;

    console.log('laudience .......')
    console.log(this.audienceIdChoice);
    console.log('le user ........')
    console.log(this.userIdChoice);
    console.log('le dossier .......')
    console.log(this.dossierIdChoice);

    this.makeForm(null);

  }

  makeForm(note: Note): void {
    this.validateForm = this.fb.group({
      id: [note != null ? note.id : null],
      prive: [note != null ? note.prive : null, [Validators.required]],
      message: [note != null ? note.message : null, [Validators.required]],
    });
  }

  enregistrerNotes(){

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid == false) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.toastr.error('Veuillez remplir les champs convenablement.', ' Erreur !', {progressBar: true});
      }, 2000);
    } else {

      this.loading = true;

      const formData = this.validateForm.value;
      console.log(formData);

      if (formData.id == null) {

        let newNote: Note = new Note();
        newNote.audienceId = this.audienceIdChoice;
        newNote.dossierId = this.dossierIdChoice;
        newNote.userNoteId = this.userIdChoice;
        newNote.message = formData.message;
        newNote.prive = formData.prive;

        this.noteService.createNote(newNote).subscribe(
          (data: any) => {
            console.log('La nouvelle note enregistré ===>');
            console.log(data);
            setTimeout(() => {
              this.toastr.success('Enregistrement nouvelle note effectuée!!!.', ' Erreur !', {progressBar: true});
              this.loading = false;
            }, 2000);
            this.resetForm();
          }
        )
      } 
    }
  }

  resetForm(): void {
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    this.makeForm(null);
  }

}
