import { Note } from './../../../models/gestion/dossier-juridique/note';
import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  url: string = environment.backend + '/notes';

  constructor(private http: HttpClient) {
  }

  createNote(note: Note): Observable<Object> {
    return this.http.post(`${this.url}`, note);
  }

  deleteNote(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateNote(note: Note): Observable<Object> {
    return this.http.put(`${this.url}`, note);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

  getById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/${id}`);
  }

  //getAllNoteByDossierIdAndPrivateIsFalse

  getAllNoteByDossierIdAndPrivateIsFalse(dossierId: number): Observable<Object> {
    return this.http.get(`${this.url}/dossiers/${dossierId}`);
  }

  getAllNoteByDossierIdAndUserId(userId: number, dossierId: number): Observable<Object> {
    return this.http.get(`${this.url}/user/dossier/${userId}/${dossierId}`);
  }


}
