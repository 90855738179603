import {Injectable} from '@angular/core';

import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DossierJuridique} from '../../../models/gestion/dossier-juridique/dossier-juridique';
import {ProgressionDossier} from '../../../models/gestion/dossier-juridique/progression-dossier';
import {Nac} from '../../../models/gestion/nomenclature/nac';
import {DossierFilter} from '../../../models/gestion/dossier-juridique/dossier-filter';
import {EnumDossierPaidNotPaid} from '../../../models/payloads/dossier-paid-notpaid';
import {EnumDossierPeriode} from 'src/app/models/payloads/dossier-parameter';
import {EnumDossierPeriodeService} from 'src/app/models/payloads/dossier-parameter-filter';


@Injectable({
    providedIn: 'root'
})
export class DossiersService {
  /*  url: string = environment.backend + '/dossiers';*/
    url: string = environment.backend + '/dossiers';
    url1: string = environment.backend + '/dossier';
    url2: string = environment.backend + '/progressiondossiers';
    url3: string = environment.backend + '/progressiondossiers/change-etat/';
    url4: string = environment.backend + '/placard/generate';
    url5: string = environment.backend + '/comptabilites';
    url6: string = environment.backend + '/rapports';
    url7: string = environment.backend + '/decision';
    url8: string = environment.backend + '/dossiers/vider';
    url10: string = environment.backend + '/dossiers/publier';
    url11: string = environment.backend + '/dossiers/radier';
    url12: string = environment.backend + '/dossiers/particulier';
    url13: string = environment.backend + '/audiences/service/contentieux';
    url58: string = environment.backend + '/generate-pv';
    /*urlPdf: string = environment.backend;*/
    urlPdf: string = environment.backend + '/decision/save';
    urlDocx: string = environment.backend + '/decision-temp';
    url9: string = environment.backend  ;
    url120: string = environment.backend + '/chat';
    /*urlPdf: string = environment.backend;*/

    constructor(private http: HttpClient) {
    }

    /*createPdf(): Observable<Object>{
      return this.http.get(`${this.urlPdf}/pdf`, {responseType: 'blob'});
    }*/
   // Récupérer la liste des décisions
   getChambreList(): Observable<any> {
    return this.http.get(`${this.url9}/services/public`);
  }

  getNotificationNumber(): Observable<any> {
    return this.http.get(`${this.url120}/notification-number`);
  }

    sendDecisionWithPdf(dossierId: number, docxBlob: Blob): Observable<any> {
        const formData = new FormData();

        formData.append('dossierId', dossierId.toString());
        // formData.append('file', docxBlob, 'blank.docx');
        formData.append('file', docxBlob, 'document.pdf');
        return this.http.post(`${this.urlPdf}`, formData);
    }

    sendDecisionWithDocx(dossierId: number, docxBlob: Blob): Observable<any> {
        const formData = new FormData();
        formData.append('dossierId', dossierId.toString());
        // formData.append('file', docxBlob, 'blank.docx');
        formData.append('file', docxBlob, 'decisionGen.docx');
        return this.http.post(`${this.urlDocx}/save`, formData);
    }

    sendDecisionWithXml(dossierId: number, xmlBlob: Blob): Observable<any> {
        const formData = new FormData();
        formData.append('dossierId', dossierId.toString());
        // formData.append('file', docxBlob, 'blank.docx');
        formData.append('file', xmlBlob, 'decisionEdit.xml');
        return this.http.post(`${this.urlDocx}/save`, formData);
    }

    listDossiersViderByAnnee(anneeId: number): Observable<Object> {
        return this.http.get(`${this.url8}/annee/${anneeId}?page=0&size=10000000`);
    }

    listDossierViderByServiceAndAnnee(serviceId: number, anneeId: number): Observable<Object> {
        return this.http.get(`${this.url8}/annee/${anneeId}/service/${serviceId}?page=0&size=10000000`);
    }

    listDossiersPublishByAnnee(anneeId: number): Observable<Object> {
        return this.http.get(`${this.url10}/annee/${anneeId}?page=0&size=10000000`);
    }

    listDossierPublishByServiceAndAnnee(serviceId: number, anneeId: number): Observable<Object> {
        return this.http.get(`${this.url10}/annee/${anneeId}/service/${serviceId}?page=0&size=10000000`);
    }
    listDossiersRadierByAnnee(anneeId: number): Observable<Object> {
        return this.http.get(`${this.url11}/annee/${anneeId}?page=0&size=10000000`);
    }

    listDossierRadierByServiceAndAnnee(serviceId: number, anneeId: number): Observable<Object> {
        return this.http.get(`${this.url11}/annee/${anneeId}/service/${serviceId}?page=0&size=10000000`);
    }


    /*createPdf(): Observable<Object>{
      return this.http.get(`${this.urlPdf}/pdf`, {responseType: 'blob'});
    }*/

    downloadDecision(urlFichier: string): Observable<any> {
        return this.http.get(`${this.url7}/download?fileName=${urlFichier}`, {responseType: 'blob'});
    }

    createDossier(dossierJuridique: DossierJuridique): Observable<Object> {
        return this.http.post(`${this.url}`, dossierJuridique);
    }

    createDossierParticulier(dossierJuridique: DossierJuridique): Observable<Object> {
        return this.http.post(`${this.url12}`, dossierJuridique);
    }
    generatePdfFormNormaliser(formData: any){
        return this.http.post(`${this.url12}/generate-pdf`, formData, { responseType: 'blob' });
    }
    // updateDossierParticulier(numeroDossier: number , typeProcedureId: number, origineProcedureId: number, audiencedistributionId: number): Observable<Object> {
    //     return this.http.get(`${this.url12}/update/${numeroDossier}/${typeProcedureId}/${origineProcedureId}/${audiencedistributionId}`);
    // }
    updateDossierParticulier(dossierJuridique: DossierJuridique): Observable<Object> {
        return this.http.post(`${this.url12}/update`, dossierJuridique);
    }

    deleteDossier(id: number): Observable<Object> {
        return this.http.delete(`${this.url}/${id}`);
    }

    updateNewStatutDossier(idDossier: number, idStatut: number): Observable<Object> {
        return this.http.get(`${this.url}/update-new-statut/${idDossier}/${idStatut}`);
    }

    list(): Observable<Object> {
        return this.http.get(`${this.url}/?page=0&size=10000000`);
    }

    listByStatutAndTypeProcedure(statutId: number, typeProcedureId: number): Observable<Object> {
        return this.http.get(`${this.url}/statut/type-procedure/${statutId}/${typeProcedureId}`);
    }

    listPCL(): Observable<Object> {
        return this.http.get(`${this.url}/statut/type-procedure/nacs/pc`);
    }

    listByAnnee(anneeId: number): Observable<Object> {
        return this.http.get(`${this.url}/annee/${anneeId}?page=0&size=10000000`);
    }

    listDossierPetiteCreance(): Observable<Object> {
        return this.http.get(`${this.url12}`);
    }
    listDesAudiencesPetiteCreance(): Observable<Object> {
        return this.http.get(`${this.url13}`);
    }

    list_pd_new(): Observable<Object> {
        return this.http.get(`${this.url2}/new`);
    }

    pv(): Observable<Object> {
        return this.http.get(`${this.url58}`, {responseType: 'blob'});
    }

    downloadFile(): void {
        this.pv().subscribe((data: Blob) => {
            const fileURL = URL.createObjectURL(data);
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = 'PVCARENCECC.docx';
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(fileURL);
        }, error => {
            console.error('Erreur lors du téléchargement du fichier:', error);
        });
    }

    createProgressionDossier(progressionDossier: ProgressionDossier): Observable<Object> {
        return this.http.post(`${this.url2}`, progressionDossier);
    }

    createProgressionDossierForProcedureCollective(progressionDossier: ProgressionDossier): Observable<Object> {
        return this.http.post(`${this.url2}/procedure-collective`, progressionDossier);
    }

    updateProgressionDossier(progressionDossier: ProgressionDossier): Observable<Object> {
        return this.http.put(`${this.url2}`, progressionDossier);
    }

    listByAudience(audienceId: number): Observable<Object> {
        return this.http.get(`${this.url}/audience/${audienceId}?page=0&size=10000000`);
    }

    listByService(serviceId: number): Observable<Object> {
        return this.http.get(`${this.url}/service/${serviceId}?page=0&size=10000000`);
    }

    listByServiceAndAnnee(serviceId: number, anneeId: number): Observable<Object> {
        return this.http.get(`${this.url}/annee/service/${anneeId}/${serviceId}?page=0&size=10000000`);
    }

    getAllDossierByAudienceId(audienceid: number): Observable<Object> {
        return this.http.get(`${this.url}/audience/${audienceid}`);
    }

    getById(id: number): Observable<Object> {
        return this.http.get(`${this.url}/${id}`);
    }

    listByAnneeService(anneeId: number, serviceId: number): Observable<Object> {
        return this.http.get(`${this.url}/annee/service/${anneeId}/${serviceId}?page=0&size=10000000`);
    }

    getListByNumerorg(numerorg: String): Observable<Object> {
        return this.http.get(`${this.url}/by-numerorg?numerorg=${numerorg}`);
    }

    getListByServiceAndStatut(serviceId: number, statutId: number): Observable<Object> {
        return this.http.get(`${this.url}/service/statut/${serviceId}/${statutId}`);
    }

    getDossierByNumRG(numerorg: String) {
        return this.http.get<any[]>(`${this.url}/by-numerorg?numerorg=${numerorg}`);
    }

    getLastProgressionDossierById(id: number) {
        return this.http.get(`${this.url2}/last?dossierId=${id}`);
    }

    getProgressionDossierByDossierIdForMiseEnEtat(dossierId: number) {
        return this.http.get(`${this.url2}/agenda/${dossierId}`);
    }

    getAuxilliaireByDossierId(dossierId: number): Observable<Object> {
        return this.http.get(`${this.url}/auxilliaire/${dossierId}`);
    }

    getFraisByDossierId(dossierId: number): Observable<Object> {
        return this.http.get(`${this.url}/frais/${dossierId}`);
    }

    getAudiencesByDossierId(dossierId: number): Observable<Object> {
        return this.http.get(`${this.url}/audiences/all/${dossierId}?page=0&size=100000000000`);
    }

    addNacToDossierId(dossierId: number, nac: Nac): Observable<Object> {
        return this.http.post(`${this.url}/add-nac/${dossierId}`, nac);
    }

    listProgressionDossierByAudienceId(audiencreId: number): Observable<Object> {
        return this.http.get(`${this.url2}/audiene/${audiencreId}?page=0&size=10000000`);
    }

    updatedProgressionDossierEtat(progressionDossierId: number, etat: number, progressiondossier: ProgressionDossier) {
        return this.http.put(`${this.url3}?etatId=${etat}&progressionId=${progressionDossierId}`, progressiondossier);
    }

    getEvenementByNumRG(numorg: String) {
        return this.http.get(`${this.url}/evenements-by-dossier-numerorg?numerorg=${numorg}`);
    }

    // list evenements by serviceId
    listEvenementsByServiceId(serviceId: number): Observable<Object> {
        return this.http.get(`${this.url}/evenements-by-user-and-service/${serviceId}?page=0&size=10000000`);
    }

    // list evenements with delai by serviceId
    listEvenementsWithDelaiByServiceId(serviceId: number): Observable<Object> {
        return this.http.get(`${this.url}/evenements-service-dossier-with-delais/${serviceId}?page=0&size=10000000`);
    }

    // list evenements by service connected
    listEvenementsWithDelaiAndNoDelaiByServiceId(serviceId: number): Observable<Object> {
        return this.http.get(`${this.url}/out-time/${serviceId}?page=0&size=10000000`);
    }

    // list evenements by service connected ..and user
    listEvenementsWithDelaiAndNoDelaiByServiceIdAndUserId(serviceId: number, userId: number): Observable<Object> {
        return this.http.get(`${this.url}/out-time/${serviceId}/${userId}?page=0&size=10000000`);
    }

    getDossierByStatutAndOrigineProcedure(origineProcedureId: number, statutId: number) {
        return this.http.get(`${this.url}/origine-procedure/statut/${origineProcedureId}/${statutId}?page=0&size=100000000&sort=datesaisine,desc`);
    }

    getDossierStatCreatedByUser(userId: number) {
        return this.http.get(`${this.url}/stats-day-week-month-year-by-auxilliaire/${userId}?page=0&size=100000000`);
    }

    getDossiersCreatedByUser(userId: number) {
        return this.http.get(`${this.url}/by-user-connected/${userId}?page=0&size=100000000`);
    }

    getDossierByOrigineProcedureAndStatutAndAnnee(origineProcedureId: number, statutId: number, anneeId: number) {
        return this.http.get(`${this.url}/origine-procedure/statut/annee/${origineProcedureId}/${statutId}/${anneeId}?page=0&size=100000000&sort=datesaisine,desc`);
    }

    downloadRecepisseold(dossierId: number, idTransaction: number): Observable<any> {
        return this.http.get(`${this.url1}/recepisse/${dossierId}?transactionId=${idTransaction}`, {responseType: 'blob'});
    }

    downloadRecepisse(dossierId: number, idTransaction: number): Observable<any> {
        return this.http.get(`${this.url1}/recepisse-petite-creance/${dossierId}?transactionId=${idTransaction}`, {responseType: 'blob'});
    }
    downloadRoleAnnee(anneeId: number): Observable<any> {
        return this.http.get(`${this.url2}/role/${anneeId}`, {responseType: 'blob'});
    }

    downloadListJuges(anneeId: number): Observable<any> {
        return this.http.get(`${this.url2}/role/${anneeId}`, {responseType: 'blob'});
    }

    updateDossier(dossierJuridique: DossierJuridique): Observable<Object> {
        return this.http.put(`${this.url}`, dossierJuridique);
    }

    updateStatutDossier(dossierJuridique: DossierJuridique): Observable<Object> {
        return this.http.put(`${this.url}`, dossierJuridique);
    }

    generateNumOrdonnance(idDossier: number): Observable<Object> {
        return this.http.get(`${this.url}/update-num-ordonnance/${idDossier}`);
    }

    getDossierById(idDossier: number) {
        return this.http.get(`${this.url}/${idDossier}`);
    }

    faireObservation(dossierId: number, observation: string): Observable<Object> {
        return this.http.get(`${this.url}/observe-dossier/${dossierId}?observation=${observation}`);
    }

    dossierFilter(dossierFilter: DossierFilter): Observable<Object> {
        return this.http.post(`${this.url}/dossiers-filter`, dossierFilter);
    }

    dossierFilters(dossierFilter: DossierFilter, anneeId: number, serviceId: number): Observable<Object> {
        return this.http.post(`${this.url}/dossiers-filters/${anneeId}/${serviceId}`, dossierFilter);
    }

    downloadPlacard(dateProcede: string, description: string, localisation: string, caracteristiques: string, creancier: string, avocat: string, procedureUn: string, procedureDeux: string, preteur: string, avocatPreteur: string, prix: string, conditionUn: string, conditionDeux: string, contact: string, signataire: string, pm: string): Observable<Object> {
        return this.http.get(`${this.url4}/${dateProcede}/${description}/${localisation}/${caracteristiques}/${creancier}/${avocat}/${procedureUn}/${procedureDeux}/${preteur}/${avocatPreteur}/${prix}/${conditionUn}/${conditionDeux}/${contact}/${signataire}/${pm}`, {responseType: 'blob'});
    }

    ListDossierImpayeByPeriode(enumDossierPaidNotPaid: EnumDossierPaidNotPaid): Observable<Object> {
        return this.http.post(`${this.url}/search-paiement-filter/`, enumDossierPaidNotPaid);
    }

    ValiderPaiementByDossierId(idDossier: number): Observable<Object> {
        return this.http.get(`${this.url5}/valider-paiement/${idDossier}`);
    }
    // ValiderPaiementByEmailAndDossierId(idDossier: number): Observable<Object> {
    //     return this.http.get(`${this.url5}/valid-paiement-send-mail/${idDossier}`);
    // }

    ValiderPaiementByEmailAndDossierId(idDossier: number): Observable<Object> {
        return this.http.get(`${this.url5}/valid-paiement-particuliers/${idDossier}`);
    }

    ImprimerRecuPaiement(idDossier: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/recu-paiement-enrolement/${idDossier}`, {responseType: 'blob'});
    }

    ImprimerRecepisseEnrolement(idDossier: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/recepisse-enrolement/${idDossier}`, {responseType: 'blob'});
    }

    /* emile */
    getAllForPvOrPieceCommunication(paramId: number, choice: string): Observable<Object> {
        return this.http.get(`${this.url}/find-piece-or-pv-attente/${choice}/${paramId}`);
    }

    list_stats_dossier_per_chambre(): Observable<Object> {
        return this.http.get(`${this.url2}/count-dossier-per-chambre`);
    }

    getRenvoiProgressionDossier(dossierId: number): Observable<Object> {
        return this.http.get(`${this.url2}/find-renvoi-dossier/${dossierId}`);
    }

    // impression de role generale
    ImprimerRoleGeneral(anneId: number, serviceId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/role-general/${anneId}/${serviceId}`, {responseType: 'blob'});
    }

    // impression de role audience
    imprimerRoleAudience(audienceId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/role-audience/${audienceId}`, {responseType: 'blob'});
    }

    // impression de decision
    imprimerDecision(dossierId: number, audienceId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/decision/${dossierId}/${audienceId}`, {responseType: 'blob'});
    }

    putToAppeal(dossierId: number){
        return this.http.get(`${this.url}/vider/annee/to-appeal/${dossierId}`);
    }

    // imprimer ordonnance
    imprimerOrdonnance(dossierId: number, audienceId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/ordonnance/${dossierId}/${audienceId}`, {responseType: 'blob'});
    }

    findStatCountDossierByOrigineProcedure(): Observable<Object> {
        return this.http.get(`${this.url}/stats-by-origine-procedure`);
    }

    findStatCountDossierByNac(): Observable<Object> {
        return this.http.get(`${this.url}/stats-by-nac`);
    }

    // imprimer ordonnance cloture mise en etat
    imprimerOrdonnanceClotureMiseEnEtat(dossierId: number, audienceId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/ordonnance-cloture-mise-en-etat/${dossierId}/${audienceId}`, {responseType: 'blob'});
    }


    // Léonel
    dossierFilterStatutPeriode(enumDossierPeriode: EnumDossierPeriode): Observable<Object> {
        return this.http.post(`${this.url}/dossiers-filter-statut-periode`, enumDossierPeriode);
    }

    dossierFilterPeriode(enumDossierPeriode: EnumDossierPeriode): Observable<Object> {
        return this.http.post(`${this.url}/dossiers-filter-periode`, enumDossierPeriode);
    }

    dossierFilterServicePeriode(enumDossierPeriodeService: EnumDossierPeriodeService): Observable<Object> {
        return this.http.post(`${this.url}/dossiers-filter-service-periode`, enumDossierPeriodeService);
    }

    listByServiceAndPeriode(serviceId: number, startDate: string, endDate: string): Observable<Object> {
        return this.http.get(`${this.url}/service/${serviceId}?startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
    }

    listByModeSaisineAndPeriode(modeSaisineId: number, startDate: string, endDate: string): Observable<Object> {
        return this.http.get(`${this.url}/mode-saisine/${modeSaisineId}?startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
    }

    listByNatureAffaireAndPeriode(nacId: number, startDate: string, endDate: string): Observable<Object> {
        return this.http.get(`${this.url}/nature-affaire-commmercial/${nacId}?startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
    }


    // imprimer ordonnance conference preparatoire calendrier
    imprimerCalendrierConferencePreparatoire(dossierId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/conference-preparatoire-calendrier/${dossierId}`, {responseType: 'blob'});
    }

    // impression de role apres audience
    imprimerRoleApresAudience(audienceId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/role-apres-audience/${audienceId}`, {responseType: 'blob'});
    }

    // impression de pv de carence
    imprimerPvCarence(dossierId: number, partieId: number, audienceId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/pv-carence/${dossierId}/${partieId}/${audienceId}`, {responseType: 'blob'});
    }

    // impression de pv de progation de délai
    imprimerDemandeProrogationDelai(dossierId: number, audienceId: number, partieId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/demande-prorogation-delai/${dossierId}/${audienceId}/${partieId}`, {responseType: 'blob'});
    }

    // imprimer ordonnance desistement Instance
    imprimerOrdDesistementInstance(dossierId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/ordonnance-constat-desistement-instance/${dossierId}`, {responseType: 'blob'});
    }

    // imprimer ordonnance annulation
    imprimerOrdAnnulation(dossierId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/ordonnance-annulation/${dossierId}`, {responseType: 'blob'});
    }

    // impression de pv de commission d'expert
    imprimerCommissionExpert(dossierId: number, personnePhysiqueId: number): Observable<any> {
        return this.http.get(`${this.url6}/dossier/commission-expert/${dossierId}/${personnePhysiqueId}`, {responseType: 'blob'});
    }

    checkTypeDocument(dossierId: number, typeDocumentLibelle: string): Observable<any> {
        return this.http.get(`${this.url}/check-type-document/${dossierId}/${typeDocumentLibelle}`);
    }

    listAllDossierNotPaid(): Observable<Object> {
        return this.http.get(`${this.url}/list-dossier-impaye`);
    }

    // ressource to check if decison is generated
    checkDecisionIsGenerated(dossierId: number): Observable<Object> {
        return this.http.get(`${this.urlDocx}/check-if-decision-generated/${dossierId}`);
    }

    editDecision(dossierId: number, audienceId: number): Observable<any> {
        return this.http.get(`${this.urlDocx}/view-decision-temp-doc/${dossierId}/${audienceId}`, {responseType: 'blob'});
    }

    getEditDecisionDocx(dossierId: number): Observable<any> {
        return this.http.get(`${this.urlDocx}/by-dossier-id/${dossierId}`, {responseType: 'blob'});
    }

}
