import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PieceJuridique} from "../../../models/gestion/dossier-juridique/piece-juridique";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  url: string = environment.backend + '/documents';

  constructor(private http: HttpClient) {
  }

  createDossier(pieceJuridique: PieceJuridique): Observable<Object> {
    return this.http.post(`${this.url}`, pieceJuridique);
  }

  deleteDossier(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateDossier(pieceJuridique: PieceJuridique): Observable<Object> {
    return this.http.put(`${this.url}`, pieceJuridique);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }
  getDocumentslist(): Observable<Object> {
    return this.http.get(`${this.url}/helper-by-fonction/`);
  }

  listByIdDossier(dossierId: number): Observable<Object> {
    return this.http.get(`${this.url}/dossiers/${dossierId}`);
  }

  validateDocument(id: number): Observable<Object> {
    return this.http.get(`${this.url}/validate/${id}`);
  }

  downloadFile(fileName: string): Observable<any> {
    return this.http.get(`${this.url}/download/?refdocument=${fileName}`, { responseType: 'blob'});
  }

  getDocumentsByDossier(id: number): Observable<Object> {
    return this.http.get(`${this.url}/dossiers/${id}`);
  }

  getDocumentsSortByCreatedDateDesc(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000&sort=createdDate,desc`);
  }

  getLast20DocumentsSortByCreatedDateDesc(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=20&sort=createdDate,desc`);
  }

  sendFilToBack(data:any): Observable<any> {
    return this.http.post(`${this.url}/upload`, data);
  }


}
