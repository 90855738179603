import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnumFormatEventAudits } from 'src/app/models/payloads/enum-format-events-audits';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserEventsAuditService {
  url: string = environment.backend + '/app/audits';

  constructor(private http: HttpClient) { }
  
  getEventsByLogin(login:string): Observable<Object> {
    return this.http.get(`${this.url}/find-by-login?login=${login}&page=0&size=100000&sort=id,desc`);
  }
  get10EventsByLogin(login:string): Observable<Object> {
    return this.http.get(`${this.url}/find-by-login?login=${login}&page=0&size=10&sort=id,desc`); 
  }
  getEventsByLoginByPeriod(login:string, debut:Date, fin:Date): Observable<Object> {
    return this.http.get(`${this.url}/find-by-login-and-date-range?login=${login}&debut=${debut}&fin=${fin}&page=0&size=100000&sort=id`);
  }

  listEventsByLoginByPeriod(loginUser:string ,enumFormatEventAudits: EnumFormatEventAudits): Observable<Object>{
    return this.http.post(`${this.url}/find-by-login-and-date-range?login=${loginUser}&pageNumber=0&pageSize=1000000`, enumFormatEventAudits);
  }
}
