import { SaisieNoteComponent } from './saisie-note/saisie-note.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BtnLoadingComponent} from './btn-loading/btn-loading.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {WizardComponent} from './wizard/wizard.component';
import {WizardStepComponent} from './wizard-step/wizard-step.component';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import {PlacardDrawingComponent} from './placard-drawing/placard-drawing.component';
import { ProjectionDossierComponent } from './projection-dossier/projection-dossier.component';
import {AudienceDetailsComponent} from './audience-details/audience-details.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NzTableModule } from 'ng-zorro-antd/table';
import { CustomizerComponent } from './customizer/customizer.component';
import {DossierDetailsComponent} from './dossier-details/dossier-details.component';
import {GenererPlacardComponent} from './generer-placard/generer-placard.component';
import { AjouterDocumentComponent } from './ajouter-document/ajouter-document.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {DocumentDossierDetailsComponent} from './document-dossier-details/document-dossier-details.component';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {AgendaMiseEnEtatComponent} from './agenda-mise-en-etat/agenda-mise-en-etat.component';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import { AjouterAvocatComponent } from './ajouter-avocat/ajouter-avocat.component';
import { MessageInfoComponent } from './message-info/message-info.component';
import {DashboardModule} from '../gestion/dashboard/dashboard.module';
import {AudienceModule} from '../gestion/audience/audience.module';
import {DossierJuridiqueModule} from '../gestion/dossier-juridique/dossier-juridique.module';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {AudiencerPetiteCreanceComponent} from './audiencer-petite-creance/audiencer-petite-creance.component';
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import { ChatComponent } from './chat/chat.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputModule } from 'ng-zorro-antd/input';


const components = [
  BtnLoadingComponent,
  WizardComponent,
  WizardStepComponent,
  // SearchComponent,
  PlacardDrawingComponent, AudienceDetailsComponent, CustomizerComponent,
  ProjectionDossierComponent,
  AudienceDetailsComponent,
  DossierDetailsComponent,
  AjouterDocumentComponent,
  GenererPlacardComponent, SaisieNoteComponent, DocumentDossierDetailsComponent, AgendaMiseEnEtatComponent,
  MessageInfoComponent, AjouterAvocatComponent, AudiencerPetiteCreanceComponent, ChatComponent
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule,
        SharedDirectivesModule,
        SharedPipesModule,
        NgxPaginationModule,
        ToastrModule.forRoot(),
        NzGridModule,
        NgxDatatableModule,
        NzTableModule,
        NzCardModule,
        NzEmptyModule,
        NgSelectModule,
        NzPopconfirmModule,
        NzModalModule,
        NzIconModule,
        NzButtonModule,
        NzSpaceModule,
        NzToolTipModule,
        NzRadioModule,
        NzInputModule
        // DashboardModule,
        // AudienceModule,
        // DossierJuridiqueModule

    ],
  providers: [ToastrService],
  declarations: components,
  exports: components,
  entryComponents: [
    DossierDetailsComponent,
    AjouterDocumentComponent,
    GenererPlacardComponent, SaisieNoteComponent, DocumentDossierDetailsComponent,
      AgendaMiseEnEtatComponent, MessageInfoComponent, AjouterAvocatComponent
  ]
})
export class SharedModule { }
