import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import { CalendarEventDB } from '../../shared/inmemory-db/calendarEvents';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CalendarAppEvent} from "../../../models/gestion/audience/calendar-event.model";
import {CalendarEventDB} from "../../../models/gestion/audience/calendar-events";
import {environment} from "../../../../environments/environment";
import {Evenement} from "../../../models/gestion/parametrage/evenement";
import {AgendaAudience} from "../../../models/gestion/audience/agenda-audience";

@Injectable({
  providedIn: 'root'
})
export class AgendaAudienceService {

  url: string = environment.backend + '/agenda-audience';

  constructor(
    private http: HttpClient
  ) {
  }

  listAgenda(dossierId, serviceId): Observable<Object> {
    return this.http.get(`${this.url}/dossier/service/${dossierId}/${serviceId}`);
  }

  listAgendaByDossierAndAudience(dossierId: number, audienceId: number): Observable<Object> {
    return this.http.get(`${this.url}/dossier/audience/${dossierId}/${audienceId}`);
  }

  listAgendaByDossier(dossierId: number): Observable<Object> {
    return this.http.get(`${this.url}/dossier/${dossierId}`);
  }

  createAgendaAudience(agendaAudience: AgendaAudience): Observable<Object> {
    return this.http.post(`${this.url}`, agendaAudience);
  }

  deleteAgendaAudience(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

}
