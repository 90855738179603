export class PlacardData {
  dateProcede:string;
  description:string;
  localisation:string;
  caracteristiques:string;
  creancier:string;
  avocat:string;
  procedureUn:string;
  procedureDeux:string;
  preteur:string;
  avocatPreteur:string;
  prix:string;
  conditionUn:string;
  conditionDeux:string;
  contact:string;
  signataire:string;
  pm:string;
}
  