import { Injectable } from "@angular/core";
import { element } from "protractor";
import {IMenuItem} from "../../services/common/navigation.service";

@Injectable()
export class MenuUserHabilitation {

    constructor(){

    }

    contruireTableauIMenuItem(menuUserList: IMenuItem[]): IMenuItem[]{

       // static menuList: IMenuItem[] = [];
        //let allMenuUser = {} as IMenuItem[];
        var allMenuUser = [] as Array<IMenuItem>
        menuUserList.forEach(element =>{
            let menuUser= {} as IMenuItem;
            menuUser.name = element.name;
            menuUser.description = element.description;
            menuUser.type = element.type;
            menuUser.icon = element.icon;
            menuUser.state = element.state;
            menuUser.sub = element.sub;
            
            allMenuUser.push(menuUser);
        })



        return allMenuUser;

    }

}