import { PersonneMorale } from "../personnes/personne-morale";
import { PersonnePhysique } from "../personnes/personne-physique";
import { TypeBien } from "./type-bien";

export class BienJuridique{
    caracteristique: string;
    code: string;
    documentId: number;
    id: number;
    libelle: string;
    personnemoraleAcheteur: PersonneMorale;
    personnemoraleAcheteurId: number;
    personnemoraleProprietaireId: number;
    personnephysiqueAcheteur: PersonnePhysique;
    personnephysiqueAcheteurId: number;
    personnephysiqueProprietaireId: number;
    acheteur: string;
    refdocument: string;
    typeBien: TypeBien;
    typeBienId: number;
    typeBienLibelle: string;
    valeurAcquisition: number;
    valeurCession: number;
    codeRG: string;

}