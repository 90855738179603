import {IMenuItem} from "../../services/common/navigation.service";

export class VicePresidenteHabilitation {
    static menuList: IMenuItem[] = [
        {
            name: 'Accueil',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/gestion/dashboard'
        },
        {
            name: 'Répertoire judiciaire',
            description: 'Répertoire des intervenants.',
            type: 'dropDown',
            icon: 'i-Conference',
            sub: [
              {
                icon: 'i-Arrow-Right',
                name: 'Avocats',
                state: '/gestion/repertoires-auxiliaires/avocat',
                type: 'link'
              },
              {
                icon: 'i-Arrow-Right',
                name: 'Commissaire Priseur',
                state: '/gestion/repertoires-auxiliaires/commissaire-priseur',
                type: 'link'
              },
              {icon: 'i-Arrow-Right',
               name: 'Experts',
               state: '/gestion/repertoires-auxiliaires/expert', 
               type: 'link'
              },
              {icon: 'i-Arrow-Right',
               name: 'Greffiers',
               state: '/gestion/repertoires-auxiliaires/greffier', 
               type: 'link'
              },
              {
                icon: 'i-Arrow-Right',
                name: 'Huissiers',
                state: '/gestion/repertoires-auxiliaires/huissier',
                type: 'link'
              },
              {
                icon: 'i-Arrow-Right',
                name: 'Magistrats',
                state: '/gestion/repertoires-auxiliaires/magistrat',
                type: 'link'
              },
              {
                icon: 'i-Arrow-Right',
                name: 'Mandataires',
                state: '/gestion/repertoires-auxiliaires/mandataires',
                type: 'link'
              },
              {
                icon: 'i-Arrow-Right',
                name: 'Notaires',
                state: '/gestion/repertoires-auxiliaires/notaire',
                type: 'link'
              },
            ]
          },

        {
            name: 'Audiences',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Receipt-3',
            sub: [
                {
                    icon: 'i-Arrow-Right',
                    name: 'Calendrier des audiences',
                    state: '/gestion/audience/calendrier-audience',
                    type: 'link'
                },
                {
                    icon: 'i-Arrow-Right',
                    name: 'Gestion des audiences',
                    state: '/gestion/audience/role-avant-et-apres-audience',
                    type: 'link'
                },
                {
                    icon: 'i-Arrow-Right',
                    name: 'Rôle Audience',
                    state: '/gestion/audience/role-audience',
                    type: 'link'
                },
            ]
        },
        {
            name: 'Dossiers',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Windows-2',
            sub: [
                {
                    icon: 'i-Folders',
                    name: 'Role général',
                    state: '/gestion/dossier-juridique/role-general',
                    type: 'link'
                },
                {
                    icon: 'i-Add-File',
                    name: 'Nouveau dossier',
                    state: '/gestion/dossier-juridique/enregistrer-dossier',
                    type: 'link'
                },
                {icon: 'i-Folders',
                name: 'Rechercher dossier',
                state: '/search',
                type: 'link'
              },
                /*{
                  icon: 'i-Link-2',
                  name: 'Distribuer dossier',
                  state: '/gestion/dossier-juridique/distribuer-dossier',
                  type: 'link'
                },*/

            ]
        },
    ];
}
