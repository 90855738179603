import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {DossiersService} from '../services/gestion/dossier-juridique/dossiers.service';
import {jsPDF} from 'jspdf';
import {ToastrService} from 'ngx-toastr';
import * as mammoth from 'mammoth';
import html2pdf from 'html2pdf.js';
import {AuthService} from '../services/common/auth.service';
import {User} from '../models/gestion/utilisateur/user';
import saveAs from 'file-saver';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-document-editor',
    templateUrl: './document-editor.component.html',
    styleUrls: ['./document-editor.component.css']
})
export class DocumentEditorComponent implements OnInit {
    content: string = '';
    user: User | null = null;
    dossierId: number | null = null;
    @ViewChild('agreedUserDecision') agreedUserDecision: TemplateRef<any>;

    constructor(private route: ActivatedRoute, private http: HttpClient,
                private modalService: NgbModal,
                private decisionService: DossiersService,
                private toastr: ToastrService, private auth: AuthService) {
    }

    ngOnInit(): void {
        this.getUser();
        // Get the file URL from the query parameter
        this.route.queryParams.subscribe(params => {
            if (params['file']) {
                const fileUrl = params['file'] || '';
                this.dossierId = params['dossierId'] ? Number(params['dossierId']) : null;

                if (fileUrl) {
                    this.http.get(fileUrl, {responseType: 'text'}).subscribe(
                        data =>  this.content = data,
                        err => console.error('Error loading HTML content:', err)
                    );
                    console.log("Le contenu", this.content);
                }
            } else if (params['content']) {
                const xmlContent = params['content'];
                if (xmlContent) {
                    this.parseXML(decodeURIComponent(xmlContent));
                }
            }
        });
    }


    parseXML(xmlContent: string): void {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlContent, 'application/xml');

        // Extract content from the XML document
        const contentElement = xmlDoc.getElementsByTagName('content')[0];
        if (contentElement) {
            this.content = contentElement.textContent || ''; // Set the editor content
        } else {
            console.error('No <content> element found in the XML.');
        }
    }

        saveContent(): void {
            this.modalService
                .open(this.agreedUserDecision,{ariaLabelledBy: 'modal-basic-title', centered: true})
                .result.then(
                (result) => {
                    console.log(`Confirmed with : ${result}`);
                    // Create a temporary container to hold the HTML content
                    const element = document.createElement('div');
                    element.innerHTML = this.content;

                    // PDF options
                    const options = {
                        margin: 0.5,
                        filename: 'Decision.pdf',
                        image: {type: 'jpeg', quality: 0.98},
                        html2canvas: {scale: 2},
                        jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
                    };

                    // Generate PDF
                    html2pdf().from(element).set(options).output('blob').then((pdfBlob) => {
                        // Send PDF blob
                        // saveAs(pdfBlob, 'Decision.pdf');
                        if (this.dossierId !== undefined) {
                            this.sendPdf(this.dossierId, pdfBlob);
                        }
                    });
                },
                (reason) => {
                    console.log(`Dismissed with: ${reason}`);
                }
            );
    }


    /*saveContentDocx(): void {
        this.wordService.createDocument(this.content);
    }*/

    saveContentAsXml() {
        // Convert the content to XML format
        const xmlContent = this.convertToXML(this.content);

        // Create a Blob from the XML content
        const blob = new Blob([xmlContent], {type: 'application/xml'});

        this.sendXml(1, blob);
        // this.sendXml(this.dossierId, blob);
        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'document.xml'; // Set the file name

        // Append to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up and remove the link
        document.body.removeChild(link);
    }

    private convertToXML(content: string): string {
        // Create the XML content without leading whitespace
        return `
<?xml version="1.0" encoding="UTF-8"?>
<document>
    <content>${this.escapeXML(content)}</content>
</document>
    `.trim(); // Trim any leading or trailing whitespace
    }

    private escapeXML(unsafe: string): string {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&apos;');
    }


    sendPdf(dossierId: number, pdfBlob: Blob): void {
        this.decisionService.sendDecisionWithPdf(dossierId, pdfBlob).subscribe(
            response => {
                this.toastr.success('Décision enregistrée avec succès!', 'Success!', {
                    progressBar: true,
                });
            },
            error => {
                console.error('Error sending PDF:', error);
                this.toastr.error('Erreur lors de l\'envoi de la décision', 'Error');
            }
        );
    }


    sendDocx(dossierId: number, xmlBlob: Blob): void {
        this.decisionService.sendDecisionWithDocx(dossierId, xmlBlob).subscribe(
            response => {
                this.toastr.success('Décision enregistrée avec succès!', 'Success!', {
                    progressBar: true,
                });
            },
            error => {
                console.error('Error sending PDF:', error);
                this.toastr.error('Erreur lors de l\'envoi de la décision', 'Error');
            }
        );
    }


    sendXml(dossierId: number, xmlBlob: Blob): void {
        this.decisionService.sendDecisionWithXml(dossierId, xmlBlob).subscribe(
            response => {
                this.toastr.success('Décision enregistrée avec succès!', 'Success!', {
                    progressBar: true,
                });
            },
            error => {
                console.error('Error sending PDF:', error);
                this.toastr.error('Erreur lors de l\'envoi de la décision', 'Error');
            }
        );
    }


    private getUser(): User {
        const token = localStorage.getItem('access_token');
        if (token) {
            const user = this.auth.getUserFromJwtToken(token);
            this.user = user;
            console.log("user : ", user);
            return user;
        }
        return null;
    }

    // Write fun tion to display buttton if user group name is "ADMIN"
    isClerk(): boolean {
        return !!this.user?.groupesId?.find((g) => g === 2);
    }

    isJudge(): boolean {
        // return
        /**
         * Ici on a besoin d'appliquer le bouton valider la décision
         * et au juge de la chambre
         */
        return !!this.user?.groupesId?.find((g) => g === 1) || !!this.user?.groupesId?.find((g) => g === 4652);
    }

    // flow to follow
    // 1. API call to check if document has been created
    // 2. if document has been created, edit the document
    // 3. if document has not been created, create the document and edit it
    // 4. save the document from user profile (if clerk save the document format docx and if judge save the document format pdf)
    // 5. save the document from user profile

    closeContent(): void {
        // close window
        window.close();
    }

}
