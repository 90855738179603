import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExerciceFonction} from '../../models/gestion/utilisateur/exercice-fonction';
import {AuthService} from '../../services/common/auth.service';
import {Salle} from '../../models/gestion/org-judiciaire/salle';
import {SalleService} from '../../services/gestion/org-judiciaire/salle.service';
import {HttpErrorResponse} from '@angular/common/http';
import {EvenementService} from '../../services/gestion/parametrage/evenement.service';
import {Evenement} from '../../models/gestion/parametrage/evenement';
import {DossierJuridique} from '../../models/gestion/dossier-juridique/dossier-juridique';
import {DossiersService} from '../../services/gestion/dossier-juridique/dossiers.service';
import {Nac} from '../../models/gestion/nomenclature/nac';
import {PartieJuridique} from '../../models/gestion/dossier-juridique/partie-juridique';
import {AgendaAudience} from '../../models/gestion/audience/agenda-audience';
import {AgendaAudienceService} from '../../services/gestion/audience/agenda-audience.service';
import {ServiceJuridiqueService} from '../../services/gestion/org-judiciaire/service-juridique.service';
import {ServiceJuridique} from '../../models/gestion/org-judiciaire/service-juridique';
import { AgendaAudiencePartieService } from 'src/app/services/gestion/audience/agenda-audience-partie.service';
import { AgendaAudiencePartie } from 'src/app/models/gestion/audience/agenda-audience-partie';
import {map, switchMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';

@Component({
    selector: 'app-agenda-mise-en-etat',
    templateUrl: './agenda-mise-en-etat.component.html',
    styleUrls: ['./agenda-mise-en-etat.component.css']
})
export class AgendaMiseEnEtatComponent implements OnInit {

    @Input() dossierId?: number;

    validateFormProgressionDossier: FormGroup;
    validateFormAudience: FormGroup;

    serviceAgendaId = 0;
    serviceAgendaCode = '';
    dossierJuridique: DossierJuridique = null;

    nacChoicedList: Nac[] = [];
    partiesChoicedDemandeurList: PartieJuridique[] = [];
    partiesChoicedDefendeurList: PartieJuridique[] = [];
    partiesConcerneList: PartieJuridique[] = [];

    salleList: Salle[] = [];
    evenementList: Evenement[] = [];

    agendaList: AgendaAudience[] = [];
    activeTabsNav;
    serviceJuridiqueTemoin: ServiceJuridique = null;

    constructor(
        private modal: NzModalService,
        private viewContainerRef: ViewContainerRef,
        private modalRef: NzModalRef,
        private toastr: ToastrService,
        private fb: FormBuilder,
        private auth: AuthService,
        private salleService: SalleService,
        private evenementService: EvenementService,
        private dossiersService: DossiersService,
        private agendaAudienceService: AgendaAudienceService,
        private agendaAudiencePartieService: AgendaAudiencePartieService,
        private serviceJuridiqueService: ServiceJuridiqueService,
    ) {
    }

    compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.id === o2.id : o1 === o2);

    ngOnInit(): void {

        this.makeFormProgressionDossier();

        if (this.dossierId !== undefined && this.dossierId !== null) {
            console.log(this.dossierId);
            this.dossiersService.getById(this.dossierId).subscribe(
                (data: DossierJuridique) => {
                    this.dossierJuridique = data;
                    console.log('le dossier ===>', this.dossierJuridique);
                    // console.log(this.dossierJuridique);
                    if (this.dossierJuridique.nacs) {
                        this.nacChoicedList = this.dossierJuridique.nacs;
                    }
                    if (this.dossierJuridique.parties) {
                        this.partiesChoicedDemandeurList = this.dossierJuridique.parties.filter(p => p.typePartieLibelle == 'DEMANDEUR');
                        this.partiesChoicedDefendeurList = this.dossierJuridique.parties.filter(p => p.typePartieLibelle == 'DEFENDEUR');

                        this.partiesConcerneList.push(...this.partiesChoicedDemandeurList);
                        this.partiesConcerneList.push(...this.partiesChoicedDefendeurList);

                    }
                    // chargement de l'agenda existant
                    this.listAgenda();
                },
                (error: HttpErrorResponse) => {
                    this.toastr.error('Dossier inconnu.', ' Erreur !', {progressBar: true});
                    this.modal.closeAll();
                });
        }
        this.auth.getCurrentExerciceFonction().subscribe((data: ExerciceFonction) => {
            console.log(data);
            if (data) {
                this.serviceAgendaId = data?.serviceId;
                this.serviceAgendaCode = data?.serviceCode;
                console.log('serviceId', this.serviceAgendaId);
                this.serviceJuridiqueService.getServiceById(this.serviceAgendaId).subscribe(
                    (data: any) => {
                        console.log('service', data);
                        this.serviceJuridiqueTemoin = data;
                    });
            }
        });

        this.salleService.list().subscribe(
            (data: any) => {
                this.salleList = [...data];
            });

        this.evenementService.listMiseEnEtat().subscribe(
            (data: any) => {
                this.evenementList = [...data];
            });

    }

    // listAgenda(): void {
    //     this.agendaAudienceService.listAgenda(this.dossierId, this.dossierJuridique.serviceCourantId).subscribe(
    //         (data: AgendaAudience[]) => {
    //
    //             this.agendaList = [...data];
    //
    //             if(this.agendaList != null && this.agendaList.length > 0 && this.validateFormAudience) {
    //                 //this.validateFormAudience.get('salle').setValue(this.agendaList[0].salle);
    //                 console.log('agenda one', this.agendaList[0]);
    //                 console.log('agenda List', this.agendaList);
    //
    //                 //charger les parties relié a chaque agenda du calendrier
    //                 for(let agenda of this.agendaList){
    //                     this.agendaAudiencePartieService.listByAgendaAudienceId(agenda.id).subscribe(
    //                         (dataP: AgendaAudiencePartie[]) => {
    //                             console.log('LagendaPartie');
    //                             console.log(dataP);
    //                             for(let agendaP of dataP){
    //                                 console.log(agendaP);
    //                                 console.log(agendaP.partie);
    //                                 let partieJ =  new PartieJuridique();
    //                                 partieJ = agendaP.partie;
    //                                 agenda.parties.push(partieJ);
    //                             }
    //                         }
    //                     );
    //                 }
    //
    //                 if(this.agendaList != null && this.agendaList.length > 0) {
    //                     this.validateFormAudience.get('salle').setValue(this.agendaList[0].salle);
    //                 }
    //
    //                 console.log('AGENDA LISTERRRRRRRRRRRRRRRRR ::::::::::::::::')
    //                 console.log('AGENDA LISTERRRRRRRRRRRRRRRRR ::::::::::::::::',this.agendaList)
    //                 console.log('AGENDA LISTERRRRRRRRRRRRRRRRR ::::::::::::::::')
    //             }
    //         },
    //         (error: HttpErrorResponse) => {
    //             this.toastr.error('échec de chargement de l\'agenda.', ' Erreur !', {progressBar: true});
    //         });
    // }

    listAgenda(): void {
        this.agendaAudienceService.listAgenda(this.dossierId, this.dossierJuridique.serviceCourantId).pipe(
            switchMap((data: AgendaAudience[]) => {
                this.agendaList = data.map(agenda => ({ ...agenda, parties: [] }));

                if (this.agendaList.length > 0 && this.validateFormAudience) {
                    console.log('Agenda list', this.agendaList);

                    const agendaRequests = this.agendaList.map(agenda =>
                        this.agendaAudiencePartieService.listByAgendaAudienceId(agenda.id).pipe(
                            map((dataP: AgendaAudiencePartie[]) => {
                                agenda.parties = dataP.map(agendaP => agendaP.partie);
                                return agenda;
                            })
                        )
                    );

                    return forkJoin(agendaRequests);
                }

                return of(this.agendaList);
            })).subscribe(
            (updatedAgendaList: AgendaAudience[]) => {
                this.agendaList = updatedAgendaList;

                if (this.agendaList.length > 0) {
                    this.validateFormAudience.get('salle').setValue(this.agendaList[0].salle);
                }
                console.log('Updated agenda list:', this.agendaList);
            },
            (error: HttpErrorResponse) => {
                this.toastr.error('Échec de chargement de l\'agenda.', 'Erreur !', { progressBar: true });
            }
        );
    }

    makeFormProgressionDossier(): void {
        this.validateFormAudience = this.fb.group({
            id: [null],
            chambre: [null],
            dossier: [null],
            date: [null, [Validators.required]],
            salle: [null, [Validators.required]],
            evenement: [null, [Validators.required]],
            partie: [null, [Validators.required]],
            observation: [null],
            /*  heure: [null, [Validators.required]],*/
        });

    }

    resetForm(): void {
        this.validateFormAudience.reset();
        for (const key in this.validateFormAudience.controls) {
            this.validateFormAudience.controls[key].markAsPristine();
            this.validateFormAudience.controls[key].updateValueAndValidity();
        }
        this.makeFormProgressionDossier();
    }

    enregistrerAll(): void {
        for (const i in this.validateFormAudience.controls) {
            this.validateFormAudience.controls[i].markAsDirty();
            this.validateFormAudience.controls[i].updateValueAndValidity();
        }

        if (this.validateFormAudience.valid === false) {
            this.toastr.error('Veuillez remplir les champs convenablement.', ' Erreur !', {progressBar: true});
        } else {

            const formData = this.validateFormAudience.value;
            formData.chambre = this.serviceJuridiqueTemoin;
            formData.dossier = this.dossierJuridique;
            console.log(formData);
            const i = this.agendaList.findIndex(a => a.evenement.id === formData.evenement.id);
            // if (i > -1) {
            //    this.toastr.error('Cet évènement est déjà programmé.', 'Erreur!', {progressBar: true});
            // } else {

            const newAgendaAudience: AgendaAudience = new AgendaAudience();
            newAgendaAudience.chambre = formData.chambre;
            newAgendaAudience.date = formData.date;
            newAgendaAudience.dossier = formData.dossier;
            newAgendaAudience.evenement = formData.evenement;
            newAgendaAudience.id = formData.id;
            newAgendaAudience.observation = formData.observation;
            newAgendaAudience.salle = formData.salle;

            console.log('New agenda auddience ===>', newAgendaAudience);

            this.agendaAudienceService.createAgendaAudience(newAgendaAudience).subscribe(
                    (data: any) => {
                        console.log('Objet de retour+++++', data);
                        this.agendaList.push(data);

                        /// start Boucle for pour save partie pour AgendaAudience
                        for (const p of formData.partie ){

                            const newAgendaAudiencePartie: AgendaAudiencePartie = new AgendaAudiencePartie();

                            newAgendaAudiencePartie.id = formData.id;
                            newAgendaAudiencePartie.agendaAudience = data;
                            newAgendaAudiencePartie.partie = p;

                            console.log('La nouvelle Agenda Audience Partie ===>', newAgendaAudiencePartie);

                            this.agendaAudiencePartieService.createAgendaAudiencePartie(newAgendaAudiencePartie).subscribe(
                                (data: any) => {
                                    console.log(data);
                                    this.toastr.success('Enregistrement Partie AgendaAudience effectué avec succès.', 'Success!', {progressBar: true});
                                },
                                (error: HttpErrorResponse) => {
                                    console.log('Echec status save Partie AgendaAudience ===> ' + error.status);
                                    this.toastr.error('Erreur Partie AgendaAudience avec le status ' + error.status, ' Erreur !', {progressBar: true});
                                });
                        }
                        /// end Boucle For pour save partie de lié a lagendaAudience

                        this.resetForm();
                        this.toastr.success('Enregistrement effectué avec succès.', 'Success!', {progressBar: true});
                        // basculer vers la tab contenant la liste apres enregistrement
                        this.activeTabsNav = 1;
                        // pour mettre par défaut le bureau dans le champs de sélection
                        if (this.agendaList != null && this.agendaList.length > 0) {
                            this.validateFormAudience.get('salle').setValue(this.agendaList[0].salle);
                        }
                    },
                    (error: HttpErrorResponse) => {
                        console.log('Echec status ==> ' + error.status);
                        this.toastr.error('Erreur avec le status ' + error.status, ' Erreur !', {progressBar: true});
                    });


                /*this.agendaAudienceService.createAgendaAudience(formData).subscribe(
                    (data: any) => {
                        console.log(data);
                        this.agendaList.push(data);
                        this.resetForm();
                        this.toastr.success('Enregistrement effectué avec succès.', 'Success!', {progressBar: true});
                        //basculer vers la tab contenant la liste apres enregistrement
                        this.activeTabsNav = 1;
                        //pour mettre par défaut le bureau dans le champs de sélection
                        if(this.agendaList != null && this.agendaList.length > 0) {
                            this.validateFormAudience.get('salle').setValue(this.agendaList[0].salle);
                        }
                    },
                    (error: HttpErrorResponse) => {
                        console.log('Echec atatus ==> ' + error.status);
                        this.toastr.error('Erreur avec le status ' + error.status, ' Erreur !', {progressBar: true});
                    });*/
            // }

        }

    }

    deleteAgendaAudience(agendaAudience: AgendaAudience): void {
        this.agendaAudienceService.deleteAgendaAudience(agendaAudience?.id).subscribe(
            (data: any) => {
                console.log(data);
                const i = this.agendaList.findIndex(l => l.id === agendaAudience?.id);
                if (i > -1) {
                    this.agendaList.splice(i, 1);
                    this.agendaList = [...this.agendaList];
                }
                this.toastr.success('Suppression effectuée avec succès.', 'Success!', {progressBar: true});
                this.resetForm();
            },
            (error: HttpErrorResponse) => {
                console.log('Echec atatus ==> ' + error.status);
                this.toastr.error('Erreur avec le status ' + error.status, ' Erreur !', {progressBar: true});
            });
    }

    imprimerAgenda(): void {


        if (this.dossierId !== undefined && this.dossierId !== null) {

            this.dossiersService.imprimerCalendrierConferencePreparatoire(this.dossierId).subscribe(
                (data) => {
                    console.log(data);

                    console.log('data ===> ', data);
                    let file, fileURL;

                    // file = new Blob([data], {type: 'application/doc'});
                    file = new Blob([data], {type: 'application/octet-stream'});
                    fileURL = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = fileURL;
                    // the filename you want
                    a.download = 'calendrierConferencePreparatoire.docx';
                    document.body.appendChild(a);
                    a.click();

                    window.URL.revokeObjectURL(fileURL);

                },
                (error: HttpErrorResponse) => {
                    console.log('Echec atatus ==> ' + error.status);
                    // this.toastr.error('Erreur avec le status ' + error.status, ' Erreur !', {progressBar: true});
                });

        }
        else{

            console.log('Echec impression calendrier ==> dossier inexistant');
            this.toastr.error('Echec impression calendrier ==> dossier inexistant', ' Erreur !', {progressBar: true});

        }

    }
}
