
export class Discussion {

    id: number;

    dossier: number;

    expediteur: number;

    expediteurName: string;

    receveur: number;

    receveurName: string;

    fonction: string;

    numerorg: string;

    message: string;

    room: string;

    messagePublic: boolean;

    lu: boolean;

    type: string;

}