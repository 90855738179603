<h3>
    Audiencer le dossier numéro : <strong> {{ numerorg }} </strong>
</h3>
<div class="modal-body">
    <form [formGroup]="dossierJuridiqueForm">
        <div class="row">
            <div class="col-md-6">
                <label for="audiencedistributionId">Choisir une Date d' Audience<span class="error-field">*<i nz-icon nz-tooltip nzTheme="outline" nzTooltipTitle="Date fixée par le tribunal de commerce pour entendre les parties et examiner le dossier présenté dans le cadre de la procédure." nzType="question-circle" style="cursor: help; color: red"></i></span>
                </label>
                <ng-select id="audiencedistributionId" formControlName="audiencedistributionId">
                    <ng-option
                            *ngFor="let audience of audienceList"
                            [value]="audience.id">{{ audience.date | date : "dd MMMM yyyy" }}
                    </ng-option>
                </ng-select>
                <div
                        *ngIf="dossierJuridiqueForm.controls.audiencedistributionId.errors && (dossierJuridiqueForm.controls.audiencedistributionId.dirty || dossierJuridiqueForm.controls.audiencedistributionId.touched)" class="error-field">Ce champ est obligatoire !
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
<!--                <btn-loading [loading]="loading" btnClass="btn-save">Enregistrer</btn-loading>-->
                <button (click)="submit(numerorg)" class="btn m-1 ml-2 btn-save" type="button">
                    Enregistrer
                </button>
                <button (click)="resetForm()" class="btn m-1 ml-2 btn-cancel" type="button">
                    Annuler
                </button>
            </div>
        </div>
    </form>
</div>
