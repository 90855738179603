import { Etat } from "./etat";
import {PieceJuridique} from "./piece-juridique";

export class ProgressionDossier {

  annee: string;
  audienceId: number;
  audienceUuid: string;
  date: Date;
  delaieffectif: string;
  documents: PieceJuridique[];
  dossierId: number;
  dossierUuid: string;
  evenementCode: string;
  evenementId: number;
  expertId: number;
  expertUuid: string;
  id: number;
  instigateurId: number;
  instigateurUuid: string;
  observation: string;
  servicedestinataireCode: string;
  servicedestinataireId: number;
  serviceexpediteurCode: string;
  serviceexpediteurId: number;
  statutCode: string;
  statutId: number;
  motifRenvoie: string;
  noteAlire: string;
  etatId: number;
  etat: Etat[];
  etatProgression: boolean;

  isSelected: boolean;
  servicedestinataireLibelle: string;
  salleLibelle: string;
  dateAudience: string;
  dateSaisineDossier: string;

}
