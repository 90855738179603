import {Evenement} from "../parametrage/evenement";
import {DossierJuridique} from "../dossier-juridique/dossier-juridique";
import {Salle} from "../org-judiciaire/salle";
import {ServiceJuridique} from "../org-judiciaire/service-juridique";
import { Audience } from "./audience";
import { PartieJuridique } from "../dossier-juridique/partie-juridique";
import { AgendaAudience } from "./agenda-audience";

export class AgendaAudiencePartie {

  id: number;
  agendaAudience: AgendaAudience;
  partie: PartieJuridique;

}
