<div *ngIf="documentStorageList != null" style="text-align: center;">

  <div [nzGutter]="{ xs: 32, sm: 24, md: 16, lg: 8 }" nz-row nzJustify="center">
    <div [nzSpan]="6" class="gutter-row" nz-col  *ngFor="let placard of documentStorageList">
      <div class="inner-box">

        <nz-card [nzCover]="coverTemplate" nzHoverable>
          <nz-card-meta nzDescription="{{ placard.description }}" nzTitle="{{ 'Publié le '+ (placard.createdDate | date : 'dd MMMM yyyy') }}"></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
       <!--   <img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"/> -->
          <img alt="example" src="{{ url+'/documents/download?refdocument='+placard.refdocument }}" style="height: 80vh;" />
        </ng-template>

      </div>
    </div>
  </div>
</div>
<!--<div [nzGutter]="{ xs: 32, sm: 24, md: 16, lg: 8 }" nz-row>
  <div [nzSpan]="8" class="gutter-row" nz-col>
    <div class="inner-box">

      <nz-card [nzCover]="coverTemplate" nzHoverable>
        <nz-card-meta nzDescription="www.instagram.com" nzTitle="Europe Street beat"></nz-card-meta>
      </nz-card>
      <ng-template #coverTemplate>
        <img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"/>
      </ng-template>

    </div>
  </div>
  &lt;!&ndash;  <div nz-col class="gutter-row" [nzSpan]="8">
      <div class="inner-box">
        <nz-card nzHoverable  [nzCover]="coverTemplate">
          <nz-card-meta nzTitle="Europe Street beat" nzDescription="www.instagram.com"></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
          <img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />
        </ng-template>
      </div>
    </div>
    <div nz-col class="gutter-row" [nzSpan]="8"><div class="inner-box">
      <nz-card nzHoverable  [nzCover]="coverTemplate">
        <nz-card-meta nzTitle="Europe Street beat" nzDescription="www.instagram.com"></nz-card-meta>
      </nz-card>
      <ng-template #coverTemplate>
        <img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />
      </ng-template>
    </div></div>&ndash;&gt;
</div>-->

<nz-empty [nzNotFoundContent]="'Aucun placard n\'est projeté.'" *ngIf="documentStorageList == null"></nz-empty>

<br>
<!--<div class="row">
  <div class="col-md-4"> </div>
  <div class="col-md-4">
      <button (click)="retourBackHome()" class="btn btn-primary btn-block m-1" type="button">
        Retour
      </button>
  </div>
</div>-->
