import {IMenuItem} from "../../services/common/navigation.service";

export class JugeConsulaireHabilitation {
  static menuList: IMenuItem[] = [
    {
        name: 'Accueil',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        type: 'link',
        icon: 'i-Bar-Chart',
        state: '/gestion/dashboard'
      },
      
      {
        name: 'Audience',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        type: 'dropDown',
        icon: 'i-Receipt-3',
        sub: [
          {
            icon: 'i-Arrow-Right',
            name: 'Gestion des audiences',
            state: '/gestion/audience/calendrier-audience-consulaire',
            type: 'link'
          },
        ]
      },
      // **********************************************************/
    ];
}
