// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  stripeKey:
    'pk_test_51Iuws9DCoypC1TY2NTl9CpsDKIVLhiWE4jp9opKSP63VoDSX80DPLNtJ546WkvSwZ5XPeG3IgD4FcN4ePyC9KUIG00lfzU314Q',
  production: false,
  expirationTime: 5000000,

  // backend: "http://192.168.100.36:8020/api",
  // websocket: 'http://192.168.100.201:8020/chat-socket',
  ai_backend: 'http://192.168.100.201:8083/api',
  // backend: 'http://192.168.100.44:8020/api',
  // backend: 'http://localhost:8020/api',
  feda_public_key: 'pk_live_wXfXvVrb9lnk8UP4c6C1LcXM',
  feda_secret_key: 'sk_live_YyVusljOiXl4EA_IFKGRJNiL',
  baseUrl: 'http://10.227.111.240:4200',

  // backend: 'http://192.168.100.201:6182/api',
  // backend: 'http://localhost:6182/api',
  // baseUrl: 'http://173.249.57.60:4200',
  websocket: 'https://tccbackend.perogroupe.com/chat-socket',
  backend: 'https://tccbackend.perogroupe.com/api',
  // backend: 'http://192.168.100.36:6182/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
