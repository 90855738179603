import {Component, Input, OnInit} from '@angular/core';
import {DossiersService} from '../../services/gestion/dossier-juridique/dossiers.service';
import {DossierJuridique} from '../../models/gestion/dossier-juridique/dossier-juridique';
import {FraisDossier} from '../../models/gestion/dossier-juridique/frais-dossier';
import {Audience} from '../../models/gestion/audience/audience';
import {Evenement} from '../../models/gestion/parametrage/evenement';
import {PartieJuridique} from '../../models/gestion/dossier-juridique/partie-juridique';
import {ProgressionDossier} from '../../models/gestion/dossier-juridique/progression-dossier';
import {Nac} from '../../models/gestion/nomenclature/nac';
import {RoleAuxiliaire} from '../../models/gestion/parametrage/role-auxiliaire';
import {TypePartie} from '../../models/gestion/parametrage/type-partie';
import {DossierAuxilliaire} from '../../models/gestion/dossier-juridique/dossier-auxilliaire';
import {HttpErrorResponse} from '@angular/common/http';
import {Statut} from '../../models/gestion/parametrage/statut';
import {StatutService} from '../../services/gestion/parametrage/statut.service';
import {ToastrService} from 'ngx-toastr';
import {AudienceGenerateService} from '../../services/gestion/audience/audience-generate.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentService} from '../../services/gestion/dossier-juridique/document.service';
import {RoleAuxiliaireService} from '../../services/gestion/parametrage/role-auxiliaire.service';
import {TypePartieService} from '../../services/gestion/parametrage/type-partie.service';
import {DossierAuxilliaireService} from '../../services/gestion/dossier-juridique/dossier-auxilliaire.service';
import {EvenementService} from '../../services/gestion/parametrage/evenement.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-dossier-details',
  templateUrl: './dossier-details.component.html',
  styleUrls: ['./dossier-details.component.css']
})
export class DossierDetailsComponent implements OnInit {
  @Input() numerorg?: string;
  activeTabsNav = 1;
  dossierJuridique: DossierJuridique = null;
  fraisDossierList: FraisDossier[] = [];
  audienceList: Audience[] = [];
  evenementList: Evenement[] = [];
  demandeur: any;
  defendeur: any;
  partieJuridiques: PartieJuridique[];
  progressionDossier: ProgressionDossier;
  partiesChoicedDemandeurList: PartieJuridique[] = [];
  partiesChoicedDefendeurList: PartieJuridique[] = [];
  nacChoicedList: Nac[] = [];
  roleAuxiliaireList: RoleAuxiliaire[] = [];
  typePartieList: TypePartie[] = [];
  dossierAuxilliaireList: DossierAuxilliaire[] = [];

  statut: Statut;
  audience: Audience;
  paramKey: number;

  constructor(
    private dossierService: DossiersService,
    private statutService: StatutService,
    private toastr: ToastrService,
    private audienceService: AudienceGenerateService,
    private modalService: NgbModal,
    private documentService: DocumentService,
    private roleAuxiliaireService: RoleAuxiliaireService,
    private typePartieService: TypePartieService,
    private dossierAuxilliaireService: DossierAuxilliaireService,
    private evenementService: EvenementService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.dossierService.getListByNumerorg(this.numerorg).subscribe(
      (data: DossierJuridique) => {
        this.dossierJuridique = data;
        if (this.dossierJuridique != null) {
          this.paramKey = this.dossierJuridique.id;
          console.log('ID: ', this.paramKey);
          console.log('Les données: ', data);
          if (this.dossierJuridique.parties) {
            this.partiesChoicedDemandeurList = this.dossierJuridique.parties.filter(p => p.typePartieLibelle === 'DEMANDEUR');
            this.partiesChoicedDefendeurList = this.dossierJuridique.parties.filter(p => p.typePartieLibelle === 'DEFENDEUR');
          }
          if (this.dossierJuridique.nacs) {
            this.nacChoicedList = this.dossierJuridique.nacs;
          }
          this.partiesChoicedDemandeurList = [...this.partiesChoicedDemandeurList];
          this.partiesChoicedDefendeurList = [...this.partiesChoicedDefendeurList];
          this.nacChoicedList = [...this.nacChoicedList];

          this.dossierService.getAudiencesByDossierId(this.dossierJuridique?.id).subscribe(
            (data: any) => {
              this.audienceList = data;
              console.log('Audiences: ', this.audienceList);
            },
            (error: HttpErrorResponse) =>
            {
              console.log(error);
            });

          this.dossierService.getEvenementByNumRG(this.numerorg).subscribe(
            (data: any) => {
              this.evenementList = data;
              console.log('Event: ', this.evenementList);

            },
            (error: HttpErrorResponse) =>
            {
              console.log(error);
            });


          this.typePartieService.list().subscribe(
            (data: any) => {
              this.typePartieList = data;
            });
        }
      },
      (error: HttpErrorResponse) => {
        this.toastr.error('Aucun dossier ayant le numéro RG ' + this.numerorg + ' n\'existe !', ' Erreur !', { progressBar: true });
      });
  }

  /* obtenir les jours des semaines d'une date */
  getJourOfDate(dateParam: any): string {
    let rep = '';
    const jourOfData = (new Date(dateParam)).getDay();
    switch (jourOfData) {
      case 0: rep = 'dimanche';
              break;
      case 1: rep = 'lundi';
              break;
      case 2: rep = 'mardi';
              break;
      case 3: rep = 'mercredi';
              break;
      case 4: rep = 'jeudi';
              break;
      case 5: rep = 'vendredi';
              break;
      case 6: rep = 'samedi';
              break;
    }
    return rep;
  }


  getCumulMontantDefendeur(): number {
    let rep = 0;
    if (this.fraisDossierList.length > 0 && this.fraisDossierList != null) {
      this.fraisDossierList.forEach(f => {
        rep += f.montantDefendeur;
      });
    }
    return rep;
  }

  getCumulMontantDemandeur(): number {
    let rep = 0;
    if (this.fraisDossierList.length > 0 && this.fraisDossierList != null) {
      this.fraisDossierList.forEach(f => {
        rep += f.montantDemandeur;
      });
    }
    return rep;
  }

  getCumulMontantTresor(): number {
    let rep = 0;
    if (this.fraisDossierList.length > 0 && this.fraisDossierList != null) {
      this.fraisDossierList.forEach(f => {
        rep += f.montantTresor;
      });
    }
    return rep;
  }
  decisionDownloading(fileurl): void {
    this.dossierService.downloadDecision(fileurl).subscribe((data: Blob) => {
          console.log('la decision =============> ', data);
          const file = new Blob([data], { type: 'application/octet-stream' });
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = fileURL;
          a.download = 'Decision.pdf';
          document.body.appendChild(a);
          a.click();
          window.open(fileURL);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
    );
  }

}
