import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TypeProcedure} from "../../../models/gestion/nomenclature/type-procedure";

@Injectable({
  providedIn: 'root'
})
export class TypeProcedureService {

  url: string = environment.backend + '/type-procedures';

  constructor(private http: HttpClient) { }

  createTypeProcedure(typeProcedure: TypeProcedure): Observable<Object> {
    return this.http.post(`${this.url}`, typeProcedure);
  }

  deleteTypeProcedure(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateTypeProcedure(typeProcedure: TypeProcedure): Observable<Object> {
    return this.http.put(`${this.url}`, typeProcedure);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

}
