import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Salle } from '../../../models/gestion/org-judiciaire/salle';

@Injectable({
  providedIn: 'root'
})
export class SalleService {

  url: string = environment.backend + '/salles';

  constructor(private http: HttpClient) { }

  createSalle(salle: Salle): Observable<Object> {
    return this.http.post(`${this.url}`, salle);
  }

  deleteSalle(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateSalle(salle: Salle): Observable<Object> {
    return this.http.put(`${this.url}`, salle);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

  getSalleById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/${id}`);
  }

}
