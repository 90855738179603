<div class="chat-area">
  <div class="wrapper">
    <div class="container">
      <div
        style="
          overflow: hidden;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        "
      ></div>
      <div class="space">Espace de discussion</div>
      <!-- <div style="margin-left: 30px" class="radio-wrapper-20">
        <label
          for="example-20-2"
          nz-tooltip="Veuillez sélectionner plusieurs éléments en maintenant la touche Ctrl enfoncée tout en cliquant sur le destinataire souhaités"
        >
          <input
            (click)="clearSelection()"
            id="example-20-2"
            type="radio"
            name="radio-examples"
            checked
          />
          <span class="name">Sélectionner</span>
        </label>
        <label
          for="example-20-1"
          nz-tooltip="Sélectionner tous les destinataires"
        >
          <input
            (click)="selectAll()"
            id="example-20-1"
            type="radio"
            name="radio-examples"
          />
          <span class="name">{{ select }}</span>
        </label>
      </div> -->

      <div class="left">
        <div class="top">
          <input
            style="width: 100%"
            type="text"
            placeholder="Rechercher..."
            [(ngModel)]="searchTerm"
            (input)="search()"
          />
        </div>

        <ul class="people">
          <li
            class="person"
            *ngFor="let person of userListFiltered"
            (click)="selectPersons($event, person)"
            [ngClass]="{
              active: isSelected(person) || selectedPersonId === person?.id
            }"
          >
            <img src="../../../assets/images/chat-avatar.png" alt="" />
            <span class="name">{{ person?.name }} </span>
            <span class="preview"
              >{{ person?.fonction.toUpperCase() }}
              <span>{{ person?.partie.toUpperCase() }}</span></span
            >
            <span class="badge" *ngIf="messagesCount[person?.id] > 0">
              {{
                messagesCount[person?.id] > 10
                  ? "10+"
                  : messagesCount[person?.id]
              }}</span
            >
          </li>
        </ul>
        <div
          style="
            margin-left: 25px;
            color: red;
            font-size: 20px;
            margin-top: -10px;
            margin-bottom: 8px;
          "
        >
          <span
            style="cursor: pointer"
            nz-tooltip="Pour sélectionner plusieurs destinataires, veuillez maintenir la touche Ctrl enfoncée sous Windows ou la touche Commande sous macOS, puis cliquer sur chacun d'entre eux."
            nz-icon
            nzType="question-circle"
            nzTheme="outline"
          ></span>
        </div>
      </div>
      <div class="right">
        <div class="top chat-header">
          <span>
            <img
              style="width: 25px; height: 25px; border-radius: 50%; margin: 0"
              src="../../../assets/images/chat-avatar.png"
              alt=""
            />
            <ng-container
              *ngIf="selectedPersonsArray.length === 1; else moreThanOne"
            >
              <span class="name">{{ selectedPersonsArray[0]?.name }} </span>
            </ng-container>
            <ng-template #moreThanOne>
              <span class="name" *ngIf="selectedPersonsArray.length > 1"
                >{{ selectedPersonsArray[0]?.name }} +
                {{ selectedPersonsArray.length - 1 }}</span
              >
              <!-- Affiche le nombre d'éléments -->
            </ng-template>
            <!-- <span class="name">{{ selectedPerson?.name }} </span> -->
            <span class="dossier-info" style="float: right; color: white; font-weight: bold"
              >Dossier : {{ dossier?.numerorg }}</span
            ></span
          >
        </div>
        <div>
          <div
            class="chat"
            #chatContainer
            [class.active-chat]="selectedPerson !== null"
            *ngIf="selectedPerson !== null && selectedPersons.size === 1"
          >
            <div
              class="bubble"
              *ngFor="let message of messages"
              [class.you]="message?.type === 'you'"
              [class.me]="message?.type === 'me'"
            >
              {{ message?.message }}
              <span style="font-size: 12px; float: right; margin-left: 5px"
                >{{ message?.dateLu | date : "shortTime" }}
                <span
                  *ngIf="message?.lu == true && message?.type !== 'you'"
                  style="color: green; font-size: 12px"
                  >Lu</span
                ></span
              >
            </div>
          </div>
          <div class="chat-input">
            <textarea
              [(ngModel)]="newMessage"
              placeholder="Écrivez un message..."
              (input)="adjustHeight($event)"
              (keyup.enter)="sendMessagePrivate(messageTextarea)"
              rows="1"
              #messageTextarea
            ></textarea>
            <button
              class="send-button"
              (click)="sendMessagePrivate(messageTextarea)"
            >
              Envoyer
            </button>
          </div>
        </div>
      </div>
      <button
        class="btn btn-danger btn-rounded"
        style="
          float: right;
          display: flex;
          align-items: center;
          margin-top: 30px;
          font-size: 15px;
        "
        (click)="closeChat()"
      >
        <span
          nz-icon
          nzType="close"
          nzTheme="outline"
          style="margin-right: 5px"
        ></span>
        Retour au calendrier
      </button>
    </div>
  </div>
</div>
