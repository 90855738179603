import { Component, OnInit } from '@angular/core';
import {LocalStoreService} from "../../services/common/local-store.service";
import {ToastrService} from "ngx-toastr";
import {DocumentJuridique} from "../../models/gestion/dossier-juridique/documents-juridique";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-placard-drawing',
  templateUrl: './placard-drawing.component.html',
  styleUrls: ['./placard-drawing.component.css']
})
export class PlacardDrawingComponent implements OnInit {

  url: string = environment.backend

  documentStorageList: DocumentJuridique[] = [];

  constructor(
    private localStoreService: LocalStoreService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.documentStorageList = this.localStoreService.getItem('PLACARD');
    if(this.localStoreService.getItem('PLACARD') == null) {
      this.toastr.info('Il n\'existe aucun placard à projeter.', ' Erreur !', {progressBar: true});
    }
  }

  retourBackHome(): void {
    this.router.navigate(['gestion/dashboard']);
  }

}
