import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TypeBien } from 'src/app/models/gestion/dossier-juridique/type-bien';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TypeBienService {
  url: string = environment.backend + '/type-biens';

  constructor(private http: HttpClient) { 

  }
  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

  createBien(typeBienJuridique: TypeBien): Observable<Object> {
    return this.http.post(`${this.url}`, typeBienJuridique);
  }
  updateBien(typeBienJuridique: TypeBien): Observable<Object> {
    return this.http.post(`${this.url}`, typeBienJuridique);
  }
}
