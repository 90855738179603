import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMenuItem, NavigationService } from '../services/common/navigation.service';
import { SearchService } from '../services/common/search.service';
import { AuthService } from '../services/common/auth.service';
import { ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sign2',
  templateUrl: './sign2.component.html',
  styleUrls: ['./sign2.component.css']
})
export class Sign2Component implements OnInit {

  loading: boolean;
  loadingText: string;
  authenticationFailError: string;
  signinForm: FormGroup; 
  nav: IMenuItem[];
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private navService: NavigationService,
    public searchService: SearchService
  ) { }

  ngOnInit () {

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.loadingText = 'Loading Dashboard Module...';

        this.loading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.loading = false;
      }
    });

    this.signinForm = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  signin () {
    this.loading = true;
    this.loadingText = 'Connexion en cours...';
    this.authenticationFailError = '';
    const username = this.signinForm.value.email;
    const password = this.signinForm.value.password;
    const rememberMe = true;
    this.auth.signin(username, password, rememberMe)
      .subscribe(res => {
        this.navService.menuItems$.subscribe(items => {
          if (items!=null && items.length > 0) {
            const first_item = items[0];
            //this.router.navigateByUrl('/gestion/dashboard');
            // we navigate to the first nav url for the user which is dashboard generally except for auxilliaire where
            // it's dossier auxillaire
            this.router.navigateByUrl(first_item.state);
            this.loading = false;
          }
        });

      }, (error: HttpErrorResponse) => {
        console.log('Erreur ...');
        console.log(error);
        this.loading = false;
        if(error.status === 401){
          this.authenticationFailError = 'Erreur: Votre compte est desactivé !!!';
        }
        else{
          this.authenticationFailError = 'Erreur: Login ou Mot de passe Incorrect!';
        }
      });
  }
}
