import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {DocumentJuridique} from '../../models/gestion/dossier-juridique/documents-juridique';
import {DocumentService} from '../../services/gestion/dossier-juridique/document.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/common/auth.service';
import {ExerciceFonction} from '../../models/gestion/utilisateur/exercice-fonction';
import {SaisieNoteComponent} from '../saisie-note/saisie-note.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {User} from 'src/app/models/gestion/utilisateur/user';
import {TransferDataService} from 'src/app/services/common/transfer-data.service';

@Component({
    selector: 'app-document-dossier-details',
    templateUrl: './document-dossier-details.component.html',
    styleUrls: ['./document-dossier-details.component.css']
})
export class DocumentDossierDetailsComponent implements OnInit {

    @Input() dossierId?: number;

    documentJuridiqueList: DocumentJuridique[] = [];
    documentJuridiqueFiltered;

    currentFonction = '';

    constructor(
        private documentsJuridiqueService: DocumentService,
        private toastr: ToastrService,
        private auth: AuthService,
        private modal: NzModalService,
        private viewContainerRef: ViewContainerRef,
        private transferDataService: TransferDataService,
    ) {
    }

    ngOnInit(): void {
        this.auth.getCurrentExerciceFonction().subscribe((data: ExerciceFonction) => {
            console.log(data);
            if (data) {
                this.currentFonction = data?.fonctionCode;
            }
        });
        if (this.dossierId !== null && this.dossierId !== undefined) {
            this.documentsJuridiqueService.getDocumentsByDossier(this.dossierId).subscribe(
                (data: DocumentJuridique[]) => {
                    console.log("LISTE JURIDIQUE ::::::::::::::::::::::", data);
                    this.documentJuridiqueList = data == null ? [] : [...data];
                    this.documentJuridiqueFiltered = [...this.documentJuridiqueList];
                    console.log('docs ===>');
                    console.log(this.documentJuridiqueList[0]);
                },
                (error: HttpErrorResponse) => {
                    this.toastr.error('Chargement de la liste document By dossierId échoué.', ' Erreur !', {progressBar: true});
                    console.log('Echec status ==> ' + error.status);
                });
        }

    }


    printFileToViewInNavigator(fileName: string): void {
        // printFileToViewInNavigator(fileName: string): void {
        this.documentsJuridiqueService.downloadFile(fileName).subscribe(res => {
                let file;
                let fileURL;
                file = new Blob([res], {type: 'application/pdf'});
                fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
        );
    }

    printFileToDownloadInNavigator(fileName: string): void {
        this.documentsJuridiqueService.downloadFile(fileName).subscribe(res => {
                let file;
                let fileURL;
                file = new Blob([res], {type: 'application/pdf'});
                fileURL = URL.createObjectURL(file);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = fileURL;
                // the filename you want
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                window.URL.revokeObjectURL(fileURL);
                // window.open(fileURL);
            }
        );
    }

    confirmationValidation(documentPiece: DocumentJuridique): void {

    }

    validerDocument(documentPiece: DocumentJuridique): void {
        this.documentsJuridiqueService.validateDocument(documentPiece.id).subscribe(
            (data: DocumentJuridique) => {
                console.log(data);
                if (data != null) {
                    const i = this.documentJuridiqueList.findIndex(d => d.id === data.id);
                    this.documentJuridiqueList[i] = data;
                    this.documentJuridiqueFiltered = [...this.documentJuridiqueList];
                    this.toastr.success('Validation du doucment effectuée avec succès.', ' Success !', {progressBar: true});
                }
            },
            (error: HttpErrorResponse) => {
                this.toastr.error('Validation du document échouée.', ' Erreur !', {progressBar: true});
                console.log('Echec status ==> ' + error.status);
            });
    }


    openAjouterNoteDialog(row): void {

        let audienceId: number;
        let userId: number;
        let dossierId: number;
        let currentUser: User = null;

        // audienceId = this.audienceId;
        audienceId = this.transferDataService.getData();
        dossierId = this.dossierId;

        const token = localStorage.getItem('access_token');
        if (token) {
            currentUser = this.auth.getUserFromJwtToken(token);
            if (currentUser != null) {
                userId = currentUser.id;
                console.log('Utilisateur courant');
                console.log(userId);
            }
        }

        if (audienceId != null && userId != null && dossierId != null) {
            const modal = this.modal.create({
                nzTitle: 'Ajout de une nouvelle note',
                nzContent: SaisieNoteComponent,
                nzViewContainerRef: this.viewContainerRef,
                nzWidth: 800,
                nzStyle: {top: '50px'},
                nzComponentParams: {
                    audienceId,
                    userId,
                    dossierId
                },
                nzFooter: null
            });

        } else {
            this.modal.error({
                nzTitle: 'Erreur',
                nzContent: 'Les infos ne sonts pas corrects !'
            });
        }
    }


}
