import {Evenement} from "../parametrage/evenement";
import {DossierJuridique} from "../dossier-juridique/dossier-juridique";
import {Salle} from "../org-judiciaire/salle";
import {ServiceJuridique} from "../org-judiciaire/service-juridique";
import { Audience } from "./audience";
import { PartieJuridique } from "../dossier-juridique/partie-juridique";

export class AgendaAudience {
  chambre: ServiceJuridique;
  date: string;
  id: number;
  salle: Salle;
  dossier: DossierJuridique;
  evenement: Evenement;
  observation: string;
  etatProgression: boolean;
  audience: Audience;
  partie: PartieJuridique;
  parties: PartieJuridique[];
}
