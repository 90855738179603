<div class="container">
    <editor apiKey="f7tso3h0optrv5dlqbidvmb9v8a16fnnxmb73lvpkaq65hde"
            [(ngModel)]="content"
            [init]="{
          height: 800,
          plugins: 'advlist autolink lists link image charmap preview anchor textcolor ',
          toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help '
        }"
    ></editor>
    <div class="button-group">
        <!--        <button id="save-btn" (click)="saveContentAsXml()">Enregistrer</button>-->
        <button id="validate-btn" (click)="saveContentAsPDF()" >Enregistrer la note</button>
    </div>
</div>