<ul #nav="ngbNav" [(activeId)]="activeTabsNav" class="nav-tabs" ngbNav>
  <li [ngbNavItem]="1">
    <a ngbNavLink>Information sur le dossier</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-4">
            <div class="card-header">
              <h5> Dossier </h5>
            </div>
            <div *ngIf="dossierJuridique != null" class="card-body">

              <div class="row">
                <div class="col-md-4">
                  <strong>Mode de saisine : </strong>
                </div>
                <div class="col-md-8">
                  {{ dossierJuridique.origineProcedureLibelle }}
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Type de procédure : </strong>
                </div>
                <div class="col-md-8">
                  {{ dossierJuridique.typeProcedureLibelle }}
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Numéro RG : </strong>
                </div>
                <div class="col-md-8" style="color: #287fb8;">
                  <strong> {{ dossierJuridique.numerorg }} </strong>
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Statut : </strong>
                </div>
                <div class="col-md-8">
                  {{ dossierJuridique.statutLibelle }}
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Position actuelle : </strong>
                </div>
                <div class="col-md-8">
                  {{ dossierJuridique.serviceCourantLibelle }}
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Date de la prochaine audience : </strong>
                </div>
                <div class="col-md-8" style="color: #EC0D40;">
                  <strong>{{ getJourOfDate(dossierJuridique.dateNextAudience) + ' ' +
                  (dossierJuridique.dateNextAudience | date : 'dd MMMM yyyy') }}</strong>
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Date de saisine : </strong>
                </div>
                <div class="col-md-8">
                  {{ getJourOfDate(dossierJuridique.datesaisine) + ' ' +
                (dossierJuridique.datesaisine | date : 'dd MMMM yyyy') }}
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Date de la demande : </strong>
                </div>
                <div class="col-md-8">
                  {{ getJourOfDate(dossierJuridique.datedemande) + ' ' +
                (dossierJuridique.datedemande | date : 'dd MMMM yyyy') }}
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Objet de la demande : </strong>
                </div>
                <div class="col-md-8">
                  <ul>
                    <li *ngFor="let nac of nacChoicedList; last as isLast">
                      {{ nac?.libelle }}
                      <span *ngIf="!isLast">; &nbsp; </span>
                    </li>
                  </ul>
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-md-4">
                  <strong>Date de la création : </strong>
                </div>
                <div class="col-md-8">
                  {{ getJourOfDate(dossierJuridique.createdDate) + ' ' +
                (dossierJuridique.createdDate | date : 'dd MMMM yyyy') }}
                </div>
              </div>
                <br>
                <div class="row">
                    <div class="col-md-4">
                        <strong>Décision : </strong>
                    </div>
                    <div class="col-md-8" style="color: #287fb8;">
                        <strong *ngIf="dossierJuridique?.decisionUrl == null"> Aucune décision n'a encore été rendue pour ce dossier. </strong>
                        <button *ngIf="dossierJuridique?.decisionUrl != null" type="button" class="btn btn-primary btn-sm m-1" (click)="decisionDownloading(dossierJuridique?.decisionUrl)" >
                            Télécharger la décision
                        </button>
                    </div>
                </div>
                <br>

              <div class="row jumbotron" style="padding-top:20px;">
                <div class="col-md-6">
                  <h5> Démandeur(s) </h5>
                  <div *ngFor="let row of partiesChoicedDemandeurList">
                    <div>
                      <div>
                        {{ row.personnephysique != null ? (row.personnephysique.nom + ' ' +
                        row.personnephysique.prenom) : '' }}
                        {{ row.personnemorale != null ? (row.personnemorale.raisonsociale) :
                        '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <h5> Défendeur(s) </h5>
                  <div *ngFor="let row of partiesChoicedDefendeurList">
                    <div>
                      <div>
                        {{ row.personnephysique != null ? (row.personnephysique.nom + ' ' +
                        row.personnephysique.prenom) : '' }}
                        {{ row.personnemorale != null ? (row.personnemorale.raisonsociale) :
                        '' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

<!--              <div class="row jumbotron mt-3" style="padding-top:20px;">-->
<!--                <h5> Récapitulatif des frais du dossiers </h5>-->
<!--                <div class="col-md-12">-->
<!--                  <nz-table #basicTable class="w-100" cellspacing="10">-->
<!--                    <tr>-->
<!--                      <td>Démandeur(s)</td>-->
<!--                      <td>{{ getCumulMontantDemandeur() }}</td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td>Défendeur(s)</td>-->
<!--                      <td>{{ getCumulMontantDefendeur() }}</td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td>Trésor</td>-->
<!--                      <td>{{ getCumulMontantTresor() }}</td>-->
<!--                    </tr>-->
<!--                  </nz-table>-->
<!--                </div>-->
<!--              </div>-->

            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Liste des évènements du dossier</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col-md-12">
          <div class="card  o-hidden">
            <ngx-datatable [columnMode]="'force'" [columnWidth]="'auto'" [footerHeight]="50"
                           [headerHeight]="50" [limit]="3" [rowHeight]="'auto'" [rows]="evenementList"
                           class="material fullscreen"
                           style="height: 350px; box-shadow: none; overflow-y:visible">
              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  Libellé
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template>
                  {{ row.libelle}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  Statut libellé
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template>
                  {{ row.statutLibelle}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  Délai
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template>
                  {{ row.delais }}
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="3">
    <a ngbNavLink>Liste des audiences du dossier</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col-md-12">
          <div class="card  o-hidden">
            <ngx-datatable [columnMode]="'force'" [columnWidth]="'auto'" [footerHeight]="50"
                           [headerHeight]="50" [limit]="3" [rowHeight]="'auto'" [rows]="audienceList"
                           class="material fullscreen"
                           style="height: 350px; box-shadow: none; overflow-y:visible">
              <ngx-datatable-column [width]="152">
                <ng-template ngx-datatable-header-template>
                  Numéro RG
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template>
                  {{ dossierJuridique != null ? dossierJuridique.numerorg : '' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  Date d'audience
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template>
                  {{ row.date | date : 'dd MMMM yyyy' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  Chambre
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template>
                  {{ row.chambreLibelle }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  Salle
                </ng-template>
                <ng-template let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template>
                  {{ row.salleLibelle }}
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
