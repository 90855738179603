import { Component, OnInit } from '@angular/core';
import { AnneeService } from '../services/gestion/parametrage/annee.service';
import { DossiersService } from '../services/gestion/dossier-juridique/dossiers.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StatutService } from '../services/gestion/parametrage/statut.service';
import { ServiceJuridiqueService } from '../services/gestion/org-judiciaire/service-juridique.service';
import { NacService } from '../services/gestion/nomenclature/nac.service';
import { AuthService } from '../services/common/auth.service';
import { SharedService } from '../services/shared.service';
import { LocalStoreService } from '../services/common/local-store.service';
import { OrigineProcedureService } from '../services/gestion/parametrage/origine-procedure.service';
import { JuridictionDetails } from '../models/gestion/juridiction-details/juridiction-details';
import { Annee } from '../models/gestion/parametrage/annee';
import { Statut } from '../models/gestion/parametrage/statut';
import { ServiceJuridique } from '../models/gestion/org-judiciaire/service-juridique';
import { DossierJuridique } from '../models/gestion/dossier-juridique/dossier-juridique';
import { ExerciceFonction } from '../models/gestion/utilisateur/exercice-fonction';
import { HttpErrorResponse } from '@angular/common/http';
import { ModeSaisineDetails } from '../models/gestion/mode-saisine-details/mode-saisine-details';
import { NacDetails } from '../models/gestion/nac-details/nac-details';
import * as moment from 'moment';
import { OrigineProcedure } from '../models/gestion/parametrage/origine-procedure';
import { Nac } from '../models/gestion/nomenclature/nac';
import { User } from '../models/gestion/utilisateur/user';
import { EnumDossierPeriode } from '../models/payloads/dossier-parameter';
const Highcharts = require('highcharts');

// Load the exporting module, and initialize it.
require('highcharts/modules/exporting')(Highcharts);

interface SerieData {
    name: string;
    y: number;
}

//Léonel
interface StatStatut {
    dossierName: string;
    dossierNumero: string;
    dossierDateSaisine: string;
}

interface StatsDossierPeriode {
    dataRecusListInRange: StatStatut[];
    dataCourantsListInRange: StatStatut[];
    dataRadiesListInRange: StatStatut[];
    dataVdesListInRange: StatStatut[];
    dataRecusListNotInRange: StatStatut[];
    dataCourantsListNotInRange: StatStatut[];
    dataRadiesListNotInRange: StatStatut[];
    dataVdesListNotInRange: StatStatut[];

}

@Component({
  selector: 'app-grafpetitcreance',
  templateUrl: './grafpetitcreance.component.html',
  styleUrls: ['./grafpetitcreance.component.css']
})
export class GrafpetitcreanceComponent implements OnInit {

  startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

  dateDebut: FormControl = new FormControl(this.startOfMonth);
  dateFin: FormControl = new FormControl(this.endOfMonth);
  chambre: FormControl = new FormControl();

  validateForm: FormGroup;
  modeSaisineForm: FormGroup;
  nacForm: FormGroup;
  loading = false;
  entry: JuridictionDetails;
  anneeList: Annee[];
  statutList: Statut[] = [];
  activeTabsNav;
  statsDossierPeriodeList: StatsDossierPeriode[] = [];

  nombreDossiers1: SerieData[];


  chambres: ServiceJuridique[] = [];
  origineProcedureList: OrigineProcedure[] = [];

  dossierList: DossierJuridique[] = [];
  dossierListVides: DossierJuridique[] = [];
  dossierListRadies: DossierJuridique[] = [];
  dossierListNouveau: DossierJuridique[] = [];
  dossierListAudience: DossierJuridique[] = [];
  dossierListPeriode: DossierJuridique[] = [];
  nacList: Nac[] = [];
  currentUser: User;
  defaultExerciceFonction: ExerciceFonction;
  exerciceFonctionList: ExerciceFonction[];

  isAdmin: boolean = false;
  isAvocat: boolean = false;
  isHuissier: boolean = false;
  isComptable: boolean = false;
  isJuge: boolean = false;
  isGreffier: boolean = false;


  constructor( private anneeService: AnneeService,
    private dossiersService: DossiersService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private statutService: StatutService,
    private serviceJuridiqueService: ServiceJuridiqueService,
    private nacService: NacService,
    private auth: AuthService,
    private dataService: SharedService,
    private store: LocalStoreService,
    private origineProcedureService: OrigineProcedureService,) { }

  ngOnInit(): void {  this.nacService.list().subscribe(
    (data: any) => {
        this.nacList = [...data];
        // this.nacFiltered = this.nacList;
        // this.nacListNonValide = this.nacList.filter(n => n.validateNac == false);
        console.log(this.nacList);
    },
    (error: HttpErrorResponse) => {
        console.log('Echec atatus ==> ' + error.status);
    });
this.serviceJuridiqueService.list().subscribe(
    (data: any) => {
        this.chambres = [...data];


    },
    (error: HttpErrorResponse) => {
        console.log('Echec status ==> ' + error.status);
    });


/*let objeChanged = new EnumDossierPeriode();
objeChanged.datesaisineDebut = this.dateDebut.value;
objeChanged.datesaisineFin = this.dateFin.value;
objeChanged.statutId = null;*/
//this.submit();


// Affichage
console.log(this.startOfMonth, this.endOfMonth);


// this.loadDataForDossierByPeriodeAndStatut();


this.anneeService.list().subscribe(
    (data: any) => {
        this.anneeList = [...data];
        console.log(this.anneeList);
    },
    (error: HttpErrorResponse) => {
        console.log('Echec status ==> ' + error.status);
    });

this.makeForm(null);
this.makeModeSaisineForm(null);
this.natueAffaireForm(null);
this.getOrigineProcedureList();
}
  


getOrigineProcedureList() {
this.origineProcedureService.list().subscribe(
    (data: any) => {
        this.origineProcedureList = [...data];
    });
}

makeForm(juridictionDetails: JuridictionDetails): void {
this.validateForm = this.fb.group({
    chambre: [juridictionDetails != null ? juridictionDetails.chambre : null, [Validators.required]],
    datedebut: [juridictionDetails != null ? juridictionDetails.datedebut : this.startOfMonth, [Validators.required]],
    datefin: [juridictionDetails != null ? juridictionDetails.datefin : this.endOfMonth, [Validators.required]],
});

}
getChambres(): void {
  this.dossiersService.getChambreList().subscribe(
    (data) => {
      this.chambres = data;
      console.error(' chambre list',this.chambres);
    },
    (error) => {
      console.error('Error fetching decision list', error);
    }
  );
}
makeModeSaisineForm(modeSaisineDetails: ModeSaisineDetails): void {
this.modeSaisineForm = this.fb.group({
    modeSaisine: [2404, [Validators.required]],
    datedebut: [modeSaisineDetails != null ? modeSaisineDetails.datedebut : this.startOfMonth, [Validators.required]],
    datefin: [modeSaisineDetails != null ? modeSaisineDetails.datefin : this.endOfMonth, [Validators.required]],
});

}

natueAffaireForm(nacDetails: NacDetails): void {
this.nacForm = this.fb.group({
    nac: [nacDetails != null ? nacDetails.nac : null, [Validators.required]],
    datedebut: [nacDetails != null ? nacDetails.datedebut : this.startOfMonth, [Validators.required]],
    datefin: [nacDetails != null ? nacDetails.datefin : this.endOfMonth, [Validators.required]],
});

}

doGraph(
series: any,
total: number,
value: string,
chartGraph: any,
optionGraph: number
) {
console.log(series);
let options: any;
// Build the chart
if (optionGraph === 0) {
    console.log(series.year);
    options = {
        chart: chartGraph,
        title: {
            text:
                'Evaluation périodique du nombre de dossiers par chambre et par ' +
                value +
                '  <br><b>(' +
                this.formatNumber(total) +
                ' dossiers )</b>',
            style: {
                fontSize: '13px',
            },
        },
        credits: {
            enabled: true,
            href: '#',
            text: '',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        yAxis: [
            {
                // --- Primary yAxis
                title: {
                    text: '',
                },
            },
        ],
        // Categories are set by using an array
        xAxis: {
            categories: series.data.map((d) => d.name),
        },
        series: [series],
        exporting: {
            allowHTML: true,
            buttons: {
                contextButton: {
                    enabled: true,
                },
                exportButton: {
                    menuItems: [
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                    ],
                },
            },
        },
    };
    const chart0 = new Highcharts.Chart(options);
}

if (optionGraph === 1) {
    options = {
        chart: chartGraph,
        title: {
            text:
                'Evaluation périodique du nombre de dossiers par chambre et par ' +
                value +
                '  <br><b>(' +
                this.formatNumber(total) +
                ' dossiers )</b>',
            style: {
                fontSize: '13px',
            },
        },
        credits: {
            enabled: true,
            href: '#',
            text: '',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        yAxis: [
            {
                // --- Primary yAxis
                min: 0,
                title: {
                    text: '',
                },
            },
        ],
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        // Categories are set by using an array
        xAxis: {
            categories: ['dossiers reçus', 'dossiers vidés', 'dossiers radiés', 'dossiers audiencés'],
            title: {
                text: null
            }
        },
        series: series,
        exporting: {
            allowHTML: true,
            buttons: {
                contextButton: {
                    enabled: true,
                },
                exportButton: {
                    menuItems: [
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                    ],
                },
            },
        },
    };
    const chart2 = new Highcharts.Chart(options);
}


if (optionGraph === 2) {
    options = {
        chart: chartGraph,
        title: {
            text:
                'Evaluation périodique du nombre de dossiers par Mode de Saisine et par ' +
                value +
                '  <br><b>(' +
                this.formatNumber(total) +
                ' dossiers )</b>',
            style: {
                fontSize: '13px',
            },
        },
        credits: {
            enabled: true,
            href: '#',
            text: '',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        yAxis: [
            {
                // --- Primary yAxis
                min: 0,
                title: {
                    text: '',
                },
            },
        ],
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        // Categories are set by using an array
        xAxis: {
            categories: ['dossiers reçus', 'dossiers vidés', 'dossiers radiés', 'dossiers audiencés'],
            title: {
                text: null
            }
        },
        series: series,
        exporting: {
            allowHTML: true,
            buttons: {
                contextButton: {
                    enabled: true,
                },
                exportButton: {
                    menuItems: [
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                    ],
                },
            },
        },
    };
    const chart1 = new Highcharts.Chart(options);
}

if (optionGraph === 3) {
    options = {
        chart: chartGraph,
        title: {
            text:
                'Evaluation périodique du nombre de dossiers par Nature d\'affaire commercial et par ' +
                value +
                '  <br><b>(' +
                this.formatNumber(total) +
                ' dossiers )</b>',
            style: {
                fontSize: '13px',
            },
        },
        credits: {
            enabled: true,
            href: '#',
            text: '',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        yAxis: [
            {
                // --- Primary yAxis
                min: 0,
                title: {
                    text: '',
                },
            },
        ],
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        // Categories are set by using an array
        xAxis: {
            categories: ['dossiers reçus', 'dossiers vidés', 'dossiers radiés', 'dossiers audiencés'],
            title: {
                text: null
            }
        },
        series: series,
        exporting: {
            allowHTML: true,
            buttons: {
                contextButton: {
                    enabled: true,
                },
                exportButton: {
                    menuItems: [
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                    ],
                },
            },
        },
    };
    const chart3 = new Highcharts.Chart(options);
}
}

formatNumber(num: number): string {
return Math.round(num)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

listDossierJuridique() {
//this.loading = true;
const formData = this.validateForm.value;
this.dossiersService.dossierFilter(formData).subscribe(
    (data: DossierJuridique[]) => {
        this.dossierList = [...data];
        //this.validateForm.reset();
        // this.loading = false;
    },
    (error: HttpErrorResponse) => {
        this.toastr.error('Critère mal défini', ' Erreur !', {progressBar: true});
        // this.loading = false;
    });
}

//
submit(): void {

this.dossierListRadies = [];
this.dossierListNouveau = [];
this.dossierListVides = [];
this.dossierListAudience = [];

for (const i in this.validateForm.controls) {
    this.validateForm.controls[i].markAsDirty();
    this.validateForm.controls[i].updateValueAndValidity();
}
console.log('Entry: ', this.validateForm.value.chambre);

if (this.validateForm.valid == false) {
    this.loading = true;
    setTimeout(() => {
        this.loading = false;
        this.toastr.error('Veuillez remplir les champs convenablement.', ' Erreur !', {progressBar: true});
    }, 3000);
} else {
    // this.loading = true;

    const formData = this.validateForm.value;
    console.log('formData', formData);


    let objeChanged = new EnumDossierPeriode();
    objeChanged.datesaisineDebut = this.validateForm.value.datedebut;
    objeChanged.datesaisineFin = this.validateForm.value.datefin;
    objeChanged.statutId = null;
    console.log('objeChanged', objeChanged);


    this.dossiersService.listByServiceAndPeriode(this.validateForm.value.chambre, this.validateForm.value.datedebut, this.validateForm.value.datefin).subscribe(
        (data: DossierJuridique[]) => {
            this.loading = true;
            this.dossierListPeriode = [...data];
            console.log('Periode', this.dossierListPeriode);
            // tri
            data.forEach(element => {
                if (element.statutLibelle == 'NOUVEAU') {
                    this.dossierListNouveau.push(element);
                } else if (element.statutLibelle == 'RADIÉ') {
                    this.dossierListRadies.push(element);
                } else if (element.statutLibelle == 'VIDÉ') {
                    this.dossierListVides.push(element);
                } else if (element.statutLibelle == 'DISTRIBUER') {
                    this.dossierListAudience.push(element);
                }

            });


            console.log('Periode', this.dossierListNouveau);

            /* traitement du graphisme */
            let nombreDossiers: SerieData[] = [];
            let total = 0;
            console.log('value', this.dossierListNouveau);


            total += this.dossierListNouveau.length;
            nombreDossiers.push({name: 'dossiers reçus', y: this.dossierListNouveau.length});
            total += this.dossierListVides.length;
            nombreDossiers.push({name: 'dossiers vidés', y: this.dossierListVides.length});
            total += this.dossierListRadies.length;
            nombreDossiers.push({name: 'dossiers radiés', y: this.dossierListRadies.length});
            total += this.dossierListAudience.length;
            nombreDossiers.push({name: 'dossiers audiencés', y: this.dossierListAudience.length});
            console.log(nombreDossiers);
            if (nombreDossiers.length > 1) {
                console.log(nombreDossiers);
                const chartOption = {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'column',
                    renderTo: 'container0',
                };
                this.doGraph(
                    [{name: 'Dossiers', data: nombreDossiers}],
                    total,
                    'statut',
                    chartOption,
                    1
                );
            }
            /* fin traitements */
            setTimeout(() => {
                this.loading = false;
                this.toastr.success('Données chargées avec succès.', 'Success!', {progressBar: true});
            }, 3000);

        },
        (error: HttpErrorResponse) => {
            this.toastr.error('Critère mal défini', ' Erreur !', {progressBar: true});

        });
}


}

submitModeSaisine(): void {

this.dossierListRadies = [];
this.dossierListNouveau = [];
this.dossierListVides = [];
this.dossierListAudience = [];

for (const i in this.modeSaisineForm.controls) {
    this.modeSaisineForm.controls[i].markAsDirty();
    this.modeSaisineForm.controls[i].updateValueAndValidity();
}
console.log('Entry: ', this.modeSaisineForm.value.modeSaisine);

if (this.modeSaisineForm.valid == false) {
    this.loading = true;
    setTimeout(() => {
        this.loading = false;
        this.toastr.error('Veuillez remplir les champs convenablement.', ' Erreur !', {progressBar: true});
    }, 3000);
} else {
    // this.loading = true;

    const formData = this.modeSaisineForm.value;
    console.log('formData', formData);


    let objeChanged = new EnumDossierPeriode();
    objeChanged.datesaisineDebut = this.modeSaisineForm.value.datedebut;
    objeChanged.datesaisineFin = this.modeSaisineForm.value.datefin;
    objeChanged.statutId = null;
    console.log('objeChanged', objeChanged);


    this.dossiersService.listByModeSaisineAndPeriode(this.modeSaisineForm.value.modeSaisine, this.modeSaisineForm.value.datedebut, this.modeSaisineForm.value.datefin).subscribe(
        (data: DossierJuridique[]) => {
            this.loading = true;
            this.dossierListPeriode = [...data];
            console.log('Periode', this.dossierListPeriode);
            // tri
            data.forEach(element => {
                if (element.statutLibelle == 'NOUVEAU') {
                    this.dossierListNouveau.push(element);
                } else if (element.statutLibelle == 'RADIÉ') {
                    this.dossierListRadies.push(element);
                } else if (element.statutLibelle == 'VIDÉ') {
                    this.dossierListVides.push(element);
                } else if (element.statutLibelle == 'DISTRIBUER') {
                    this.dossierListAudience.push(element);
                }

            });


            console.log('Periode', this.dossierListNouveau);

            /* traitement du graphisme */
            let nombreDossiers: SerieData[] = [];
            let total = 0;
            console.log('value', this.dossierListNouveau);


            total += this.dossierListNouveau.length;
            nombreDossiers.push({name: 'dossiers reçus', y: this.dossierListNouveau.length});
            total += this.dossierListVides.length;
            nombreDossiers.push({name: 'dossiers vidés', y: this.dossierListVides.length});
            total += this.dossierListRadies.length;
            nombreDossiers.push({name: 'dossiers radiés', y: this.dossierListRadies.length});
            total += this.dossierListAudience.length;
            nombreDossiers.push({name: 'dossiers audiencés', y: this.dossierListAudience.length});
            console.log(nombreDossiers);
            if (nombreDossiers.length > 1) {
                console.log(nombreDossiers);
                const chartOption = {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'column',
                    renderTo: 'container0',
                };
                this.doGraph(
                    [{name: 'Dossiers', data: nombreDossiers}],
                    total,
                    'statut',
                    chartOption,
                    2
                );
            }
            /* fin traitements */
            setTimeout(() => {
                this.loading = false;
                this.toastr.success('Données chargées avec succès.', 'Success!', {progressBar: true});
            }, 3000);

        },
        (error: HttpErrorResponse) => {
            this.toastr.error('Critère mal défini', ' Erreur !', {progressBar: true});

        });
}


}

submitNac(): void {

this.dossierListRadies = [];
this.dossierListNouveau = [];
this.dossierListVides = [];
this.dossierListAudience = [];

for (const i in this.nacForm.controls) {
    this.nacForm.controls[i].markAsDirty();
    this.nacForm.controls[i].updateValueAndValidity();
}
console.log('Entry: ', this.nacForm.value.nac);

if (this.nacForm.valid == false) {
    this.loading = true;
    setTimeout(() => {
        this.loading = false;
        this.toastr.error('Veuillez remplir les champs convenablement.', ' Erreur !', {progressBar: true});
    }, 3000);
} else {
    // this.loading = true;

    const formData = this.nacForm.value;
    console.log('formData', formData);


    let objeChanged = new EnumDossierPeriode();
    objeChanged.datesaisineDebut = this.nacForm.value.datedebut;
    objeChanged.datesaisineFin = this.nacForm.value.datefin;
    objeChanged.statutId = null;
    console.log('objeChanged', objeChanged);


    this.dossiersService.listByNatureAffaireAndPeriode(this.nacForm.value.nac, this.nacForm.value.datedebut, this.nacForm.value.datefin).subscribe(
        (data: DossierJuridique[]) => {
            this.loading = true;
            this.dossierListPeriode = [...data];
            console.log('Periode', this.dossierListPeriode);
            // tri
            data.forEach(element => {
                if (element.statutLibelle == 'NOUVEAU') {
                    this.dossierListNouveau.push(element);
                } else if (element.statutLibelle == 'RADIÉ') {
                    this.dossierListRadies.push(element);
                } else if (element.statutLibelle == 'VIDÉ') {
                    this.dossierListVides.push(element);
                } else if (element.statutLibelle == 'DISTRIBUER') {
                    this.dossierListAudience.push(element);
                }

            });


            console.log('Periode', this.dossierListNouveau);

            /* traitement du graphisme */
            let nombreDossiers: SerieData[] = [];
            let total = 0;
            console.log('value', this.dossierListNouveau);


            total += this.dossierListNouveau.length;
            nombreDossiers.push({name: 'dossiers reçus', y: this.dossierListNouveau.length});
            total += this.dossierListVides.length;
            nombreDossiers.push({name: 'dossiers vidés', y: this.dossierListVides.length});
            total += this.dossierListRadies.length;
            nombreDossiers.push({name: 'dossiers radiés', y: this.dossierListRadies.length});
            total += this.dossierListAudience.length;
            nombreDossiers.push({name: 'dossiers audiencés', y: this.dossierListAudience.length});
            console.log(nombreDossiers);
            if (nombreDossiers.length > 1) {
                console.log(nombreDossiers);
                const chartOption = {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'column',
                    renderTo: 'container0',
                };
                this.doGraph(
                    [{name: 'Dossiers', data: nombreDossiers}],
                    total,
                    'statut',
                    chartOption,
                    3
                );
            }
            /* fin traitements */
            setTimeout(() => {
                this.loading = false;
                this.toastr.success('Données chargées avec succès.', 'Success!', {progressBar: true});
            }, 3000);

        },
        (error: HttpErrorResponse) => {
            this.toastr.error('Critère mal défini', ' Erreur !', {progressBar: true});

        });
}


}
}
