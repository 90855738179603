import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ServiceJuridique } from "../../../models/gestion/org-judiciaire/service-juridique";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ServiceJuridiqueService {
  url: string = environment.backend + "/services";
  baseurl: string = environment.backend;

  constructor(private http: HttpClient) {}

  createService(serviceJuridique: ServiceJuridique): Observable<Object> {
    return this.http.post(`${this.url}`, serviceJuridique);
  }

  deleteService(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateService(serviceJuridique: ServiceJuridique): Observable<Object> {
    return this.http.put(`${this.url}`, serviceJuridique);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

  listAvtService(): Observable<Object> {
    return this.http.get(`${this.url}/filtered-by-room/?page=0&size=10000000`);
  }

  listPublic(): Observable<Object> {
    return this.http.get(`${this.url}/public/?page=0&size=10000000`);
  }

  listFilter(chambreId: number): Observable<Object> {
    return this.http.get(`${this.baseurl}/services-without-id?id=${chambreId}`);
  }

  getServiceById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/${id}`);
  }
}
