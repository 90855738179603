<br>
<br>
<h3 class="text-center"> Ajouter un nouvel avocat </h3>
<div class="modal-body">
    <form [formGroup]="addAvocatForm">
        <div class="row">
          <div class="col-md-4 form-group mb-3">
            <label for="typeJuridictionId">Civilité <span class="error-field"> * </span></label>
            <select class="form-control" formControlName="civilite" id="juridictionId">
              <option value="1">M.</option>
              <option value="2">Mme</option>
              <option value="3">Mlle</option>
            </select>
            <div *ngIf="addAvocatForm.controls.civilite.errors &&
             (addAvocatForm.controls.civilite.dirty || addAvocatForm.controls.civilite.touched)"
                 class="error-field">
              Ce champ est obligatoire !
            </div>
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="nom">Nom <span class="error-field"> * </span> </label>
            <input class="form-control" formControlName="nom" id="nom" placeholder="Entrer le nom" type="text">
            <div *ngIf="addAvocatForm.controls.nom.errors &&
                               (addAvocatForm.controls.nom.dirty || addAvocatForm.controls.nom.touched)" class="error-field">
              Ce champ est obligatoire !
            </div>
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="prenom">Prénom(s) <!--<span class="error-field"> * </span>--> </label>
            <input class="form-control" formControlName="prenom" id="prenom" placeholder="Entrer le prénom" type="text">
            <!--<div *ngIf="validateFormPersonnePhysique.controls.prenom.errors &&
                               (validateFormPersonnePhysique.controls.prenom.dirty || validateFormPersonnePhysique.controls.prenom.touched)" class="error-field">
              Ce champ est obligatoire !
            </div>-->
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="telephone">Téléphone <!--<span class="error-field"> * </span>--> </label>
            <input class="form-control" formControlName="telephone" id="telephone" placeholder="Entrer le numéro de téléphone" type="text">
            <!--<div *ngIf="validateFormPersonnePhysique.controls.telephone.errors &&
                               (validateFormPersonnePhysique.controls.telephone.dirty || validateFormPersonnePhysique.controls.telephone.touched)" class="error-field">
              Ce champ est obligatoire !
            </div>-->
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="email">Email</label>
            <input class="form-control" formControlName="email" id="email" placeholder="Entrer email" type="text">
          
          </div>
  
          <div class="col-md-4  form-group mb-3">
            <label for="nationaliteId">Nationalité</label>
            <select class="form-control" formControlName="nationaliteId"  id="nationaliteId" placeholder="Entrer la nationalité">
              <option *ngFor="let nationalite of nationaliteList" [value]="nationalite.id">{{nationalite.libelle}}</option>
            </select>
          </div>
  
          <!--div class="col-md-4 form-group mb-3">
            <label for="nationalite">Nationalité <span class="error-field"> * </span> </label>
            <input class="form-control" formControlName="nationalite" id="nationalite" placeholder="Entrer la nationalité"
                   type="text"-->
            <!--<div *ngIf="validateFormPersonnePhysique.controls.nationalite.errors &&
                               (validateFormPersonnePhysique.controls.nationalite.dirty || validateFormPersonnePhysique.controls.nationalite.touched)" class="error-field">
              Ce champ est obligatoire !
            </div>-->
          <!--/div-->
  
          <div class="col-md-4 form-group mb-3">
            <label for="datenaissance">Date naissance</label>
            <input class="form-control" formControlName="datenaissance" id="datenaissance" placeholder="Entrer votre date de naissance"
                   type="date">
            <!--<div *ngIf="validateFormPersonnePhysique.controls.datenaissance.errors &&
                               (validateFormPersonnePhysique.controls.datenaissance.dirty || validateFormPersonnePhysique.controls.datenaissance.touched)" class="error-field">
              Ce champ est obligatoire !
            </div>-->
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="dateprestation">Date de prestation</label>
            <input class="form-control" formControlName="dateprestation" id="dateprestation" placeholder="Entrer la date de prestation"
                   type="date">
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="prenom">Fonction</label>
            <input class="form-control" formControlName="fonction" id="fonction" placeholder="Entrer la fonction"
                   type="text">
          </div>
  
          <div class="col-md-4  form-group mb-3">
            <label for="situationmatrimonialeId">Situation matrimoniale</label>
            <select class="form-control" formControlName="situationmatrimonialeId"  id="situationmatrimonialeId" placeholder="Entrer la nationalité">
              <option *ngFor="let situationmatrimoniale of situationMatrimonialeList" [value]="situationmatrimoniale.id">{{situationmatrimoniale.libelle}}</option>
            </select>
          </div>

          <div class="col-md-4  form-group mb-3">
            <label for="sexeId">Sexe</label>
            <select class="form-control" formControlName="sexeId"  id="sexeId" placeholder="Entrer la nationalité">
              <option *ngFor="let sexe of sexeList" [value]="sexe.id">{{sexe.libelle}}</option>
            </select>
          </div>
  
          <div class="col-md-4  form-group mb-3">
            <label for="professionId">Profession</label>
            <select class="form-control" formControlName="professionId" id="professionId">
              <option *ngFor="let profession of professionList" [value]="profession.id">{{profession.libelle}}</option>
            </select>
          </div>
  
          <!--div class="col-md-4 form-group mb-3">
            <label for="sexe">Sexe</label>
            <select class="form-control" formControlName="sexe" id="sexe">
              <option value=1>Masculin</option>
              <option value=2>Féminin</option>
              <option value=3>Autres</option>
            </select>
          </div-->
  
          <!--div class="col-md-4 form-group mb-3">
            <label for="nomjeunefille">Nom jeune fille</label>
            <input class="form-control" formControlName="nomjeunefille" id="nomjeunefille" placeholder="Entrer le nom de jeune fille"
                   type="text">
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="nompere">Nom Père</label>
            <input class="form-control" formControlName="nompere" id="nompere" placeholder="Entrer le nom du père"
                   type="text">
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="prenompere">Prénom Père</label>
            <input class="form-control" formControlName="prenompere" id="prenompere" placeholder="Entrer le prénom du père"
                   type="text">
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="nommere">Nom Mère</label>
            <input class="form-control" formControlName="nommere" id="nomere" placeholder="Entrer le nom de la mère"
                   type="text">
          </div>
  
          <div class="col-md-4 form-group mb-3">
            <label for="prenommere">Prénom mère</label>
            <input class="form-control" formControlName="prenommere" id="prenommere" placeholder="Entrer le prénom de la mère"
                   type="text">
          </div-->
        </div>
      </form>
  </div>
  <div class="modal-footer">
    <button (click)="resetForm()" class="btn btn-warning" type="button" >Vider les champs</button>
    <button (click)="submitedPersonnePhysiqueForm(false)" class="btn btn-warning" type="button">Fermer</button>
    <button (click)="submitedPersonnePhysiqueForm(true)"
            [disabled]="addAvocatForm.invalid"
            class="btn btn-primary" type="button">
      Enregistrer
    </button>
  </div>