<div class="container">
    <div class="row">

        <div class="col-md-1"></div>

        <div class="col-md-2">
            <div class="auth-logo text-left mb-2">
                <!--<img src="assets/images/logoForsetiCom.png" alt=""  (click)="quitterPageAction()"
                     style="margin-top:27px;height: 95px;cursor: pointer;">-->
            </div>
        </div>

        <div class="col-md-6" style="text-align: center; padding-top: 2em;cursor: pointer;">
            <!--<img alt="" src="assets/images/logoTCC_02.png" (click)="quitterPageAction()" style="width: 400px;height: 75px;margin-bottom: 0px;">-->
            <img alt="" src="../../../assets/images/logoTCC.png" style="width: 400px;height: 75px;margin-bottom: 0px;">
        </div>

        <div class="col-md-2">
            <div class="auth-logo text-right mb-2">
                <!--<img src="./assets/images/pero.png" alt="LOGO" (click)="quitterPageAction()"
                     style="width: 200px;height: 67px;margin-top: 40px;cursor: pointer;">-->
            </div>
        </div>

        <div class="col-md-1"></div>
    </div>
</div>
<div class="container dark-background">
    <div class="breadcrumb">
        <h1> Statistiques </h1>
        <ul>
           
        </ul>
    </div>

    <div class="separator-breadcrumb border-top"></div>
    <div class="row">

        <div class="col-md-12">
            <div class="card card-icon-bg card-icon-bg-primary">

                <ul #nav="ngbNav" [(activeId)]="activeTabsNav" class="nav-tabs" ngbNav>
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>
                         <span style="font-size: 14px;text-transform: uppercase;">
                            par chambre </span>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="o-hidden">
                                        <form (ngSubmit)="submit()" [formGroup]="validateForm">
                                            <div class="row">
                                                <div class="col-md-4 mb-3">
                                                    <label for="chambre">Chambre <span
                                                            class="required-field">*</span></label>
                                                    <ng-select placeholder="Sélectionner la chambre" id="chambreid"
                                                               formControlName="chambre"
                                                               name="chambre">
                                                        <ng-option *ngFor="let chambre of chambres"
                                                                   [value]="chambre.id"> {{ chambre.libelle }}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-4 mb-3">
                                                    <label>Date début: <span class="required-field">*</span> </label>
                                                    <input class="form-control" id="datedebut" name="datefin"
                                                           placeholder="Entrer la date de début"
                                                           formControlName="datedebut"
                                                           type="date">
                                                </div>

                                                <div class="col-md-4 mb-3">
                                                    <label for="">Date fin: <span class="required-field">*</span>
                                                    </label>
                                                    <input class="form-control" id="datefin" name="datefin"
                                                           formControlName="datefin"
                                                           placeholder="Entrer la date de fin"
                                                           type="date">
                                                </div>

                                                <!--div class="col-md-2 mb-3" style="align-self: flex-end;">
                                                    <btn-loading [loading]="loading" btnClass="btn-primary  btn-save btn-block m-1">
                                                        Actualiser
                                                    </btn-loading>
                                                </div-->
                                            </div>

                                            <!--div class="col-md-2 mb-3" style="align-self: flex-end;"-->
                                            <div class="col-md-12 mb-3">
                                                <btn-loading [loading]="loading"
                                                             btnClass="btn-primary  btn-save btn-block m-1">
                                                    Actualiser
                                                </btn-loading>
                                            </div>
                                        </form>
                                        <br>
                                        <div class="row">

                                            <div class="col-md-12">
                                                <div id="container0" style="width:100%; height:500px;">
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>
                         <span style="font-size: 14px;text-transform: uppercase;">
                             par mode de saisine </span>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="o-hidden">
                                        <form (ngSubmit)="submitModeSaisine()" [formGroup]="modeSaisineForm">
                                            <div class="row">
                                                <div class="col-md-4 mb-3">
                                                    <label for="mode">Mode de saisine <span
                                                            class="required-field">*</span></label>
                                                    <ng-select placeholder="Sélectionner la mode de saisine" id="mode"
                                                               formControlName="modeSaisine"
                                                               name="chambre">
                                                        <ng-option *ngFor="let origineProcedure of origineProcedureList"
                                                                   [value]="origineProcedure.id"> {{ origineProcedure.libelle }}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-4 mb-3">
                                                    <label>Date début: <span class="required-field">*</span> </label>
                                                    <input class="form-control" id="datedebut" name="datefin"
                                                           placeholder="Entrer la date de début"
                                                           formControlName="datedebut"
                                                           type="date">
                                                </div>

                                                <div class="col-md-4 mb-3">
                                                    <label for="">Date fin: <span class="required-field">*</span>
                                                    </label>
                                                    <input class="form-control" id="datefin" name="datefin"
                                                           formControlName="datefin"
                                                           placeholder="Entrer la date de fin"
                                                           type="date">
                                                </div>

                                                <!--div class="col-md-2 mb-3" style="align-self: flex-end;">
                                                    <btn-loading [loading]="loading" btnClass="btn-primary  btn-save btn-block m-1">
                                                        Actualiser
                                                    </btn-loading>
                                                </div-->
                                            </div>

                                            <!--div class="col-md-2 mb-3" style="align-self: flex-end;"-->
                                            <div class="col-md-12 mb-3">
                                                <btn-loading [loading]="loading"
                                                             btnClass="btn-primary  btn-save btn-block m-1">
                                                    Actualiser
                                                </btn-loading>
                                            </div>
                                        </form>
                                        <br>
                                        <div class="row">

                                            <div class="col-md-12">
                                                <div id="container0" style="width:100%; height:500px;">
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <!--Statistique par Nac -->
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>
                         <span style="font-size: 14px;text-transform: uppercase;">
                             par nac </span>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="o-hidden">
                                        <form (ngSubmit)="submitNac()" [formGroup]="nacForm">
                                            <div class="row">
                                                <div class="col-md-4 mb-3">
                                                    <label for="nac">Nature des Affaires Commerciales <span
                                                            class="required-field">*</span></label>
                                                    <ng-select placeholder="Sélectionner la nature d'affaire commerciale" id="nac"
                                                               formControlName="nac"
                                                               name="nac">
                                                        <ng-option *ngFor="let nac of nacList"
                                                                   [value]="nac.id"> {{ nac.libelle }}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-4 mb-3">
                                                    <label>Date début: <span class="required-field">*</span> </label>
                                                    <input class="form-control" id="datedebut" name="datefin"
                                                           placeholder="Entrer la date de début"
                                                           formControlName="datedebut"
                                                           type="date">
                                                </div>

                                                <div class="col-md-4 mb-3">
                                                    <label for="">Date fin: <span class="required-field">*</span>
                                                    </label>
                                                    <input class="form-control" id="datefin" name="datefin"
                                                           formControlName="datefin"
                                                           placeholder="Entrer la date de fin"
                                                           type="date">
                                                </div>

                                                <!--div class="col-md-2 mb-3" style="align-self: flex-end;">
                                                    <btn-loading [loading]="loading" btnClass="btn-primary  btn-save btn-block m-1">
                                                        Actualiser
                                                    </btn-loading>
                                                </div-->
                                            </div>

                                            <!--div class="col-md-2 mb-3" style="align-self: flex-end;"-->
                                            <div class="col-md-12 mb-3">
                                                <btn-loading [loading]="loading"
                                                             btnClass="btn-primary  btn-save btn-block m-1">
                                                    Actualiser
                                                </btn-loading>
                                            </div>
                                        </form>
                                        <br>
                                        <div class="row">

                                            <div class="col-md-12">
                                                <div id="container0" style="width:100%; height:500px;">
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>

        </div>
    </div>


    <!--    Pas de touche -->


</div>
