import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class TransferDataService {

  private data;
  private dataDossierId;
  private audienceData;
  private dossierData;
  private dossierEventData;
  private isPresent: boolean;
  private dataAudienceId: number;

  constructor(
    private router: Router,
  ) {
  }

  setData(data) {
    this.data = data;
  }

  getData() {
    let temp = this.data;
    this.clearData();
    return temp;
  }

  clearData() {
    this.data = undefined;
  }

  /// Transfert data for transfert dossierId
  setDataDossierId(dataDossierId) {
    this.dataDossierId = dataDossierId;
  }

  getDataDossierId() {
    let temp = this.dataDossierId;
    this.clearData();
    return temp;
  }

  clearDataDossierId() {
    this.dataDossierId = undefined;
  }
  /// end transfert for dossierId


  /// Transfert data for transfert audienceId

  setDataAudienceId(dataAudienceId) {
    this.dataAudienceId = dataAudienceId;
  }

  getDataAudienceId() {
    let tempAudienceId = this.dataAudienceId;
    this.clearData();
    return tempAudienceId;
  }

  clearDataAudienceId() {
    this.dataAudienceId = undefined;
  }

  /// end transfert for audienceId

  setAudienceData(audienceData) {
    this.audienceData = audienceData;
  }

  getAudienceData() {
    let tempAudience = this.audienceData;
    this.clearAudienceData();
    return tempAudience;

  }

  clearAudienceData() {
    this.audienceData = undefined;
  }

  //Dossier transfert objet
  setDossierData(dossierEventData) {
    this.dossierEventData = dossierEventData;
  }

  getDossierData() {
    let tempDossier = this.dossierEventData;
    this.clearDossierData();
    return tempDossier;

  }

  clearDossierData() {
    this.dossierData = undefined;
  }

  //Dossier transfert objet
  clearDossierEventeBool() {
    this.isPresent = undefined;
  }

  setDossierEventBool(isPresent) {
    this.isPresent = isPresent;
  }

  getDossierEventBool() {
    let temBool = this.isPresent;
    this.clearDossierEventeBool();
    return temBool;

  }
}
