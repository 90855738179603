<div class="">

  <div class="row col-md-12">

    <div class="col-md-1">
      <div class="auth-logo text-center mb-2" style="padding-top: 1em">
        <img src="assets/images/logoForsetiCom.png" alt="">
      </div>
    </div>

    <div class="col-md-11" style="text-align: center; padding-top: 2em">
      <h1>TRIBUNAL DE COMMERCE DE COTONOU</h1>
    </div>

  </div>

    <!--div class="breadcrumb">
      <h1>Gestion des audiences</h1>
      <ul>
        <li><a routerLink="/gestion/audience/Progression-etat">Audience</a></li>
        <li>Gestion des dossiers de l'audience</li>
      </ul>
     
     
        <div class="col-md-12 mb-2">
          <div class="float-right">
            <button (click)="backToAudience()" class="btn btn-outline-secondary m-1" type="button">
              Retour à l'audience du jour
            </button>

          </div>
        </div>
      </div-->
      <!--Fin button-->

    <!--/div-->
    <div class="separator-breadcrumb border-top"></div>
    <div class="text-center" style="color: #000; font-weight: bold; margin-bottom: 20px;"> <span class="h2">CHAMBRE : {{audienceId?.chambreLibelle}}</span></div>
    <div class="text-center"> Rôle du {{audienceId?.date | date :  "dd/MM/y"}} -  {{audienceId?.salleLibelle}}</div>


    <div #myDrop2="ngbDropdown" class="d-inline-block" ngbDropdown >
      <button (focus)="myDrop2.open()" class="btn btn-primary m-1"
        id="dropdownDecision">
        . . .
      </button>
      <div aria-labelledby="dropdownManual" ngbDropdownMenu>

        <div>
          <button (click)="gererTermine()" ngbDropdownItem>Terminé</button>
          <button (click)="gererRemettreEnAttente()" ngbDropdownItem>Remettre en attente</button>

        </div>
      </div>
    </div>

    <div class="card mb-4" >
      <div class="card-body" style="background-color: #287FB8; border-radius: 10px;">
        <div class="card-title mb-3 pb-2" style="border-bottom: 1px solid white;">
          <div class="row">
            <div class="col-sm-2" style="color: white;">
              Dossier
            </div>
            <div class="col-sm-3" style="color: white;">
              Parties
            </div>
            <div class="col-sm-2" style="color: white;">
              Statut
            </div>
            <div class="col-sm-2" style="color: white;">
              Etat
            </div>
            <div class="col-sm-3" style="color: white;">
              Décision/Motif
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-sm-2">
            <tr *ngFor="let dossierEn of dossierEncours; let i = index">
              <td> <span style="color: white;"> {{dossierEn?.numerorg}}</span></td>

            </tr>

          </div>
          <div class="col-sm-3">
            <tr *ngFor="let progres of progressionDossierListByAudienceIdEncours; let i = index">
            <span *ngIf="getPartieByTypeLibelle(progres.dossier.parties, 'DEMANDEUR')?.length > 0"  style="color: white;"> Demandeur(s) : </span>
                    <ul>
                      <li *ngFor="let partie of getPartieByTypeLibelle(progres.dossier.parties, 'DEMANDEUR')"  style="color: white;">
                        {{ partie?.personnephysique != null ? (partie?.personnephysique.nom + ' '+partie?.personnephysique.prenom) : '' }}
                        {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                      </li>
                    </ul>
                    <span *ngIf="getPartieByTypeLibelle(progres.dossier.parties, 'DEFENDEUR')?.length > 0"  style="color: white;"> Défendeur(s) :</span>
                    <ul>
                      <li *ngFor="let partie of getPartieByTypeLibelle(progres.dossier.parties, 'DEFENDEUR')"  style="color: white;">
                        {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' '+partie.personnephysique.prenom) : '' }}
                        {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                      </li>
                    </ul>
            </tr>
          </div>
          <div class="col-sm-2">
            <tr *ngFor="let progres of progressionDossierListByAudienceIdEncours; let i = index">
              <span style="color: white;">{{ progres.progressiondossier?.statutCode }}</span>
            </tr>
          </div>
          <div class="col-sm-2">
            <tr *ngFor="let progres of progressionDossierListByAudienceIdEncours; let i = index">
              <span style="color: white;">{{ progres.progressiondossier?.etat.libelle }}</span>
            </tr>
          </div>
          <div class="col-sm-3">
          <tr *ngFor="let progres of progressionDossierListByAudienceIdEncours; let i = index">
            <!--span style="color: white;" (click)="showNoteDossierEncours(modalNote)" > président </span-->
            <span style="color: white;" (click)="showNoteDossierEncours(modalNote)" *ngIf="progres.progressiondossier.statutCode == 'DEL' "> {{ progres.progressiondossier.noteAlire | truncate : 15 }} </span>
            <span style="color: white;" *ngIf="progres.progressiondossier.statutCode == 'RAD' || progres.progressiondossier.statutCode == 'RADF' "> {{ progres.progressiondossier.motifRenvoie }} </span>

          </tr>
          </div>

        </div>

      </div>
    </div>


    <!--Dossier en atte-->
    <div class="card mb-4">
      <div class="card-body">
        <div class="card-title mb-3 border-bottom pb-2">Dossiers en attente</div>
        <div class="row">

          <div class="col-md-12">
            <div class="o-hidden">
              <ngx-datatable [columnMode]="'force'" [footerHeight]="50" [headerHeight]="50" [rowHeight]="'auto'"
                             [rows]="progressionDossierListByAudienceIdEnAttente" [messages]="{emptyMessage: 'Aucune donnée à afficher!'}" [limit]="1" class="material fullscreen"
                             style="height: 400px; box-shadow: none">

                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14"> Numéro RG</span>
                  </ng-template>
                  <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span
                      class="font-weight-bold text-14"> {{ row.dossier.numerorg }}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14">  Parties</span>
                  </ng-template>
                  <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span *ngIf="getPartieByTypeLibelle(row.dossier.parties, 'DEMANDEUR').length > 0"> Demandeur(s) : </span>
                    <ul>
                      <li *ngFor="let partie of getPartieByTypeLibelle(row.dossier.parties, 'DEMANDEUR')">
                        {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' '+partie.personnephysique.prenom) : '' }}
                        {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                      </li>
                    </ul>
                    <span *ngIf="getPartieByTypeLibelle(row.dossier.parties, 'DEFENDEUR').length > 0"> Défendeur(s) :</span>
                    <ul>
                      <li *ngFor="let partie of getPartieByTypeLibelle(row.dossier.parties, 'DEFENDEUR')">
                        {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' '+partie.personnephysique.prenom) : '' }}
                        {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                      </li>
                    </ul>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14">  Statut</span>
                  </ng-template>
                  <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ row.progressiondossier.statutCode }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column>
                  <ng-template ngx-datatable-header-template>
                    <span class="font-weight-bold text-14">  Etat</span>
                  </ng-template>
                  <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ row.progressiondossier.etat.libelle }}
                  </ng-template>
                </ngx-datatable-column>


                <ngx-datatable-column>
                    <ng-template ngx-datatable-header-template>
                        <span class="font-weight-bold text-14"> Actions</span>
                    </ng-template>

                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      <button class="btn btn-primary m-1" type="button"
                        (click)="updatedEtatProgressionDossier(row.progressiondossier)">
                        Activer
                      </button>
                    </ng-template>

                  </ngx-datatable-column>

              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card mb-4">
        <div class="card-body">
          <div class="card-title mb-3 border-bottom pb-2">Dossier Terminé</div>

          <!--Tableau Dossier terminé -->

          <div class="col-md-12">
              <div class="o-hidden">
                <ngx-datatable [columnMode]="'force'" [footerHeight]="50" [headerHeight]="50" [rowHeight]="'auto'"
                               [rows]="progressionDossierListByAudienceIdTermine" [messages]="{emptyMessage: 'Aucune donnée à afficher!'}" [limit]="1" class="material fullscreen"
                               style="height: 300px; box-shadow: none; overflow-y:visible">

                  <ngx-datatable-column>
                    <ng-template ngx-datatable-header-template>
                      <span class="font-weight-bold text-14"> Numéro RG</span>
                    </ng-template>
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      <span
                        class="font-weight-bold text-14"> {{ row.dossier.numerorg }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column >
                    <ng-template ngx-datatable-header-template>
                      <span class="font-weight-bold text-14">  Parties</span>
                    </ng-template>
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      <span *ngIf="getPartieByTypeLibelle(row.dossier.parties, 'DEMANDEUR')?.length > 0"> Demandeur(s) : </span>
                      <ul>
                        <li *ngFor="let partie of getPartieByTypeLibelle(row.dossier.parties, 'DEMANDEUR')">
                          {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' '+partie.personnephysique.prenom) : '' }}
                          {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                        </li>
                      </ul>
                      <span *ngIf="getPartieByTypeLibelle(row.dossier.parties, 'DEFENDEUR')?.length > 0"> Défendeur(s) :</span>
                      <ul>
                        <li *ngFor="let partie of getPartieByTypeLibelle(row.dossier.parties, 'DEFENDEUR')">
                          {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' '+partie.personnephysique.prenom) : '' }}
                          {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                        </li>
                      </ul>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column>
                    <ng-template ngx-datatable-header-template>
                      <span class="font-weight-bold text-14">  Statut</span>
                    </ng-template>
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>

                      {{ row.progressiondossier.statutCode }}

                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column>
                    <ng-template ngx-datatable-header-template>
                      <span class="font-weight-bold text-14">  Etat</span>
                    </ng-template>
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>

                      {{ row.progressiondossier.etat.libelle }}

                    </ng-template>
                  </ngx-datatable-column>

                </ngx-datatable>
              </div>
            </div>

            <!--Fin tableau dossier terminé-->

        </div>
      </div>

  </div>

  <ng-template #modalNote let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title" style="font-weight: bold;">Note du président</h4>
      <button (click)="modal.dismiss('Cross click')" aria-describedby="modal-title" aria-label="Close button"
        class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="modal-body">

        <p *ngFor="let progres of progressionDossierListByAudienceIdEncours">
          {{ progres.progressiondossier.noteAlire != null ? (progres.progressiondossier.noteAlire) : 'Aucune note à lire' }}
        </p>
      </div>
      <div class="modal-footer">
        <button (click)="modal.dismiss('cancel')" class="btn btn-outline-secondary" type="button">Fermer
        </button>
      </div>
  </ng-template>

