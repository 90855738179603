import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import {ParametrageRoutingModule} from './gestion/parametrage/parametrage.routing';
import {AppRoutingModule} from './app.routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {JwtInterceptor} from './helpers/jwt.interceptor';

import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');


import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';

import * as AllIcons from '@ant-design/icons-angular/icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { MenuUserHabilitation } from './models/habilitation/menu-user-habilitation';
import {FedaPayCheckoutModule} from 'fedapay-angular';
import { CalendrierAudienceComponent } from './role-audience/calendrier-audience/calendrier-audience.component';
import {
    CalendarCommonModule,
    CalendarDayModule,
    CalendarModule,
    CalendarMonthModule,
    CalendarWeekModule,
    DateAdapter
} from 'angular-calendar';
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {DocumentEditorComponent} from './document-editor/document-editor.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TestgraphComponent } from './testgraph/testgraph.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SafeUrlPipe } from './safe-url.pipe';
import { Sign2Component } from './sign2/sign2.component';
import { Sign1Component } from './sign1/sign1.component';
import { NoteEditorComponent } from './note-editor/note-editor.component';
import {GrafpetitcreanceComponent} from "./grafpetitcreance/grafpetitcreance.component";
import {GrafprocedureComponent} from "./grafprocedure/grafprocedure.component";

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

@NgModule({
    declarations: [
        AppComponent,
        DocumentEditorComponent,
        TestgraphComponent,
        SafeUrlPipe,
        Sign2Component,
        Sign1Component,
        NoteEditorComponent,
        GrafpetitcreanceComponent,
        GrafprocedureComponent,

    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgbModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        NgSelectModule,
        NzIconModule.forRoot(icons),
        FedaPayCheckoutModule.forRoot({public_key: 'pk_live_qsvSr4xGqAAJ04HZuu2T_Yox'}),
        SharedModule,
        CalendarCommonModule,
        CalendarDayModule,
        CalendarMonthModule,
        CalendarWeekModule,
        NgbTooltipModule,
        EditorModule,
        FormsModule,
        CommonModule,
       
        ReactiveFormsModule,
        NgxPaginationModule,
        NgxDatatableModule,
        NgbModule,
        NgSelectModule,

    ],
    providers: [
        MenuUserHabilitation,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        /* The below line prevent the interceptor from inserting the access_token ... should look at it more deeply later
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, */
        {provide: LOCALE_ID, useValue: 'fr'}
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
