export class PieceJuridique {
  createdBy: string;
  createdDate: string;
  description: string;
  dossierId: number;
  fileName: string;
  gedId: number;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  mimetype: string;
  refdocument: string;
  sens: boolean;
  submittedfilename: string;
  taille: string;
  audienceId: number;
  typedocumentId: number;
  typedocumentUuid: string;
  uuid: string;
  file: File;
  fileDownloadUri: string;
  // fonctionId: number;
}
