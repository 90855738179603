import {IMenuItem} from "../../services/common/navigation.service";

export class PresidentTrbunal {
  static menuList: IMenuItem[] = [
    {
      name: 'Accueil',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'link',
      icon: 'i-Bar-Chart',
      state: '/gestion/dashboard'
    },
  /*  {
      name: 'Paramétrage',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
      type: 'dropDown',
      icon: 'i-Gear',
      sub: [
        {icon: 'i-Arrow-Right', name: 'Langues', state: '/gestion/parametrage/langue', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Civilités', state: '/gestion/parametrage/civilite', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Années', state: '/gestion/parametrage/annee', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Types d\'évènement', state: '/gestion/parametrage/type-evenement', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Évènements', state: '/gestion/parametrage/evenement', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Statuts', state: '/gestion/parametrage/statut', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Sections', state: '/gestion/parametrage/section', type: 'link'},
        {
          icon: 'i-Arrow-Right',
          name: 'Secteurs d\'activité',
          state: '/gestion/parametrage/secteur-activite',
          type: 'link'
        },
        {icon: 'i-Arrow-Right', name: 'Professions', state: '/gestion/parametrage/profession', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Fonctions', state: '/gestion/parametrage/fonction', type: 'link'},
        {
          icon: 'i-Arrow-Right',
          name: 'Rôles des auxilliaires',
          state: '/gestion/parametrage/role-auxiliaire',
          type: 'link'
        },
        {icon: 'i-Arrow-Right', name: 'Modèles', state: '/gestion/parametrage/modele', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Corps juridiques', state: '/gestion/parametrage/corps', type: 'link'},
        {
          icon: 'i-Arrow-Right',
          name: 'Natures juridiques',
          state: '/gestion/parametrage/nature-juridique',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Matières juridiques',
          state: '/gestion/parametrage/matiere-juridique',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Origines de procédure',
          state: '/gestion/parametrage/origine-procedure',
          type: 'link'
        },
      ]
    },
    {
      name: 'Nomenclature',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'dropDown',
      icon: 'i-Library',
      sub: [
        {icon: 'i-Arrow-Right', name: 'Classifications', state: '/gestion/nomenclature/classification', type: 'link'},
        {
          icon: 'i-Arrow-Right',
          name: 'Natures d\'affaire',
          state: '/gestion/nomenclature/nature-affaire',
          type: 'link'
        },
        {icon: 'i-Arrow-Right', name: 'Nom Affaires Com', state: '/gestion/nomenclature/nac', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Types de procédure', state: '/gestion/nomenclature/type-procedure', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Qualifications', state: '/gestion/nomenclature/qualification', type: 'link'},
      ]
    },
    {
      name: 'Org. judiciaire',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'dropDown',
      icon: 'i-Network-Window',
      sub: [
        {icon: 'i-Arrow-Right', name: 'Salles', state: '/gestion/org-judiciaire/salle', type: 'link'},
        {
          icon: 'i-Arrow-Right',
          name: 'Types de juridiction',
          state: '/gestion/org-judiciaire/type-juridiction',
          type: 'link'
        },
        {icon: 'i-Arrow-Right', name: 'Juridictions', state: '/gestion/org-judiciaire/juridiction', type: 'link'},
        {icon: 'i-Arrow-Right', name: 'Types de Services', state: '/gestion/org-judiciaire/type-service', type: 'link'},
        {
          icon: 'i-Arrow-Right',
          name: 'Services',
          state: '/gestion/org-judiciaire/service-juridique',
          type: 'link'
        },
      ]
    },*/
 /*   {
      name: 'Personnes',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'dropDown',
      icon: 'i-Conference',
      sub: [
        {
          icon: 'i-Arrow-Right',
          name: 'Personnes physiques',
          state: '/gestion/personnes/personne-physique',
          type: 'link'
        },
        {icon: 'i-Arrow-Right', name: 'Personnes morales', state: '/gestion/personnes/personne-morale', type: 'link'},
        {
          icon: 'i-Arrow-Right',
          name: 'Repertoires auxilliaires',
          state: '/gestion/personnes/auxilliaire-repertoire',
          type: 'link'
        },
      ]
    },
    {
      name: 'Utilisateurs',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'dropDown',
      icon: 'i-Business-ManWoman',
      sub: [
        {icon: 'i-Arrow-Right', name: 'Gestion des utilisateurs', state: '/gestion/utilisateur/user', type: 'link'},
        {
          icon: 'i-Arrow-Right',
          name: 'Groupe des utilisateurs',
          state: '/gestion/utilisateur/user-group',
          type: 'link'
        },
      ]
    },*/
    {
      name: 'Audiences',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'dropDown',
      icon: 'i-Receipt-3',
      sub: [
        {
          icon: 'i-Arrow-Right',
          name: 'Programmation des audiences',
          state: '/gestion/audience/programme-audience',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Calendrier des audiences',
          state: '/gestion/audience/calendrier-audience',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Gestion des audiences',
          state: '/gestion/audience/role-avant-et-apres-audience',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Répartir juges consulaires',
          state: '/gestion/audience/role-avant-et-apres-audience/0',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Rôle Audience',
          state: '/gestion/audience/role-audience',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: "Projection en Salle",
          state: '/gestion/audience/progression-audience',
          type: 'link'
        },
        /*{icon: 'i-Folders',
          name: 'Dossier auxillaire',
          state: '/gestion/dossier-juridique/dossier-auxillaire',
          type: 'link'
        },*/
      ]
    },
    {
      name: 'Dossiers',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'dropDown',
      icon: 'i-Windows-2',
      sub: [
        {icon: 'i-Folders', name: 'Role général', state: '/gestion/dossier-juridique/role-general', type: 'link'},
        {
          icon: 'i-Add-File',
          name: 'Nouveau dossier',
          state: '/gestion/dossier-juridique/enregistrer-dossier',
          type: 'link'
        },
        /*{
          icon: 'i-Link-2',
          name: 'Distribuer dossier',
          state: '/gestion/dossier-juridique/distribuer-dossier',
          type: 'link'
        },*/
        {
          icon: 'i-Paper-Plane',
          name: 'Transferer dossier',
          state: '/gestion/dossier-juridique/transfert-dossier',
          type: 'link'
        },
        {
          icon: 'i-Files',
          name: 'Explorateur documents',
          state: '/gestion/dossier-juridique/explorateur-documents/:numDossierAuxilliaire',
          type: 'link'
        },
        {icon: 'i-Folders',
          name: 'Rechercher dossier',
          state: '/search',
          type: 'link'
        },
      ]
    },
    {
      name: 'Placard',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'dropDown',
      icon: 'i-Split-Four-Square-Window',
      sub: [
        {
          icon: 'i-Arrow-Right',
          name: 'Liste Placard',
          state: '/gestion/placard/placard-recent',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Gestion bien',
          state: '/gestion/placard/gestion-bien',
          type: 'link'
        },
      ]
    },

    {
      name: 'Espace de travail',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'link',
      icon: 'i-Network-Window',
      state: '/gestion/workspace'
    },
    {
      name: 'Requete à fin d\'ordonnance',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'link',
      icon: 'i-Letter-Open',
      state: '/gestion/requete-fin-ordonnance'
    },
    {
      name: 'Répertoires judiciaire',
      description: 'Répertoire des intervenants.',
      type: 'dropDown',
      icon: 'i-Conference',
      sub: [
        {
          icon: 'i-Arrow-Right',
          name: 'Avocats',
          state: '/gestion/repertoires-auxiliaires/avocat',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Commissaire Priseur',
          state: '/gestion/repertoires-auxiliaires/commissaire-priseur',
          type: 'link'
        },
        {icon: 'i-Arrow-Right',
         name: 'Experts',
         state: '/gestion/repertoires-auxiliaires/expert', 
         type: 'link'
        },
        {icon: 'i-Arrow-Right',
         name: 'Greffiers',
         state: '/gestion/repertoires-auxiliaires/greffier', 
         type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Huissiers',
          state: '/gestion/repertoires-auxiliaires/huissier',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Magistrats',
          state: '/gestion/repertoires-auxiliaires/magistrat',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Mandataires',
          state: '/gestion/repertoires-auxiliaires/mandataires',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Notaires',
          state: '/gestion/repertoires-auxiliaires/notaire',
          type: 'link'
        },
      ]
    },

    {
      name: 'Statistiques',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'dropDown',
      icon: 'i-Statistic',
      sub: [
        /*{
          icon: 'i-Arrow-Right',
          name: 'Montant reçu',
          state: '/gestion/statistiques/montant-recu',
          type: 'link'
        },*/
        {
          icon: 'i-Arrow-Right',
          name: 'Juridiction (Détails)',
          state: '/gestion/statistiques/juridiction-details',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Histogramme',
          state: '/gestion/statistiques/histogramme',
          type: 'link'
        },
        {
          icon: 'i-Arrow-Right',
          name: 'Point Financier',
          state: '/gestion/statistiques/point-financier',
          type: 'link'
        },
      ]
    },

  ];
}
