import { Component, OnInit, OnDestroy, ViewContainerRef } from "@angular/core";
import { NavigationService } from "../../services/common/navigation.service";
import { CustomizerService } from "../../services/common/customizer.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { StatutService } from "src/app/services/gestion/parametrage/statut.service";
import { TypeProcedureService } from "src/app/services/gestion/nomenclature/type-procedure.service";
import { ServiceJuridiqueService } from "src/app/services/gestion/org-judiciaire/service-juridique.service";
import { DossiersService } from "src/app/services/gestion/dossier-juridique/dossiers.service";
import { TransferDataService } from "src/app/services/common/transfer-data.service";
import { AuthService } from "src/app/services/common/auth.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { Statut } from "src/app/models/gestion/parametrage/statut";
import { TypeProcedure } from "src/app/models/gestion/nomenclature/type-procedure";
import { ServiceJuridique } from "src/app/models/gestion/org-judiciaire/service-juridique";
import { DossierJuridique } from "src/app/models/gestion/dossier-juridique/dossier-juridique";
import { FormControl } from "@angular/forms";
import { ExerciceFonction } from "src/app/models/gestion/utilisateur/exercice-fonction";
import { User } from "src/app/models/gestion/utilisateur/user";
import { HttpErrorResponse } from "@angular/common/http";
import { PartieJuridique } from "src/app/models/gestion/dossier-juridique/partie-juridique";
import { AgendaMiseEnEtatComponent } from "../agenda-mise-en-etat/agenda-mise-en-etat.component";
import { UserEventsAudits } from "src/app/models/gestion/utilisateur/user-events-audit";
import { UserEventsAuditService } from "src/app/services/gestion/utilisateur/user-events-audit.service";

@Component({
  selector: "app-customizer",
  templateUrl: "./customizer.component.html",
  styleUrls: ["./customizer.component.scss"]
})
export class CustomizerComponent implements OnInit {
  isOpen: boolean = false;
  nav;
  routeInterceptorSub: Subscription;
  isRTL: boolean;
  statutList: Statut[] = [];
  statutListSortForRequete: Statut[] = [];
  typeProcedureList: TypeProcedure[] = [];
  serviceJuridiqueList: ServiceJuridique[] = [];

  dossierJuridiqueRefereList: DossierJuridique[] = [];
  dossierJuridiqueFilteredRefereList: DossierJuridique[] = [];
  dossierJuridiqueConciliationList: DossierJuridique[] = [];
  dossierJuridiqueFilteredConciliationList: DossierJuridique[] = [];
  dossierJuridiqueDistributionList: DossierJuridique[] = [];
  dossierJuridiqueFilteredDistributionList: DossierJuridique[] = [];
  dossierJuridiqueAttentePVList: DossierJuridique[] = [];
  dossierJuridiqueFilteredAttentePVList: DossierJuridique[] = [];

  currentFonction: string = null;
  currentServiceId: number = 0;
  statutCon: Statut = null;
  searchControlRefere: FormControl = new FormControl();
  searchControlConciliation: FormControl = new FormControl();
  searchControlDistribution: FormControl = new FormControl();
  searchControlAttentePV: FormControl = new FormControl();

  loginUser:string;
  journalOfUser: UserEventsAudits[]=[];
  journalOfUserFiltered: UserEventsAudits[]=[];

  currentUser: User = null;
    journalOfUserTwenty: UserEventsAudits[];
    journalOfUserFilteredTwenty: UserEventsAudits[];
  
  constructor(
    private statutService: StatutService,
    private toastr: ToastrService,
    private userEventService: UserEventsAuditService,
    private typeProcedureService: TypeProcedureService,
    private serviceJuridiqueService: ServiceJuridiqueService,
    private dossiersService: DossiersService,
    private auth: AuthService,
    private transferDataService: TransferDataService,
    private router: Router,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    public navService: NavigationService,
  ) {}

  ngOnInit() {
    this.nav = [...this.navService.defaultMenu];

    const token = localStorage.getItem('access_token');
    this.currentUser = this.auth.getUserFromJwtToken(token);

    this.auth.getCurrentExerciceFonction().subscribe((data: ExerciceFonction) => {
        console.log(data);
        this.currentFonction = data?.fonctionCode;
        this.currentServiceId = data?.serviceId;
        this.loginUser=data?.userLogin;
        let choice = '';
        let paramId = 0;
        if(this.currentFonction == 'ADM' || this.currentFonction == 'PRT') {
            choice = 'aaa';
        }
        if(this.currentFonction == 'JUG' || this.currentFonction == 'JUC' || this.currentFonction == 'VP') {
            choice = 'bbb';
            paramId =  this.currentServiceId;
        }
        if(this.currentFonction == 'AVT' || this.currentFonction == 'HSR') {
            choice = 'ccc';
            if(this.currentUser != null) {
                paramId =  this.currentUser.personnephysiqueId;
            }
        }
        console.log("choice ===> "+choice);
        console.log("paramId ===> "+paramId);
        if(choice != '' || paramId != 0) {
            this.dossiersService.getAllForPvOrPieceCommunication(paramId, choice).subscribe(
                (data: any) => {
                    console.log(data);
                    this.dossierJuridiqueAttentePVList = [...data];
                    this.dossierJuridiqueFilteredAttentePVList = [...data];
                });
            console.log("pv en attente de pv", this.dossierJuridiqueAttentePVList);
            console.log(choice, paramId);
        }
    });

    this.userEventService.getEventsByLogin(this.loginUser).subscribe(
        (data:UserEventsAudits[])=>{
          if(data!=null && data.length>0){
            this.journalOfUser=[...data];
            this.journalOfUserFiltered=[...this.journalOfUser];
          }else {
            console.log("Error du chargement du journal d'events ",data);
          }
        },
        (error: HttpErrorResponse) => {
          console.log('Echec de chargement du journal d\'events ==> '+error.status);
    });

    this.userEventService.get10EventsByLogin(this.loginUser).subscribe(
        (data:UserEventsAudits[])=>{
          if(data!=null && data.length>0){
              console.log("user data 10 ",data);
            this.journalOfUserTwenty=[...data];
            this.journalOfUserFilteredTwenty=[...this.journalOfUserTwenty];
          }else {
            console.log("Error du chargement du journal d'events ",data);
          }
        },
        (error: HttpErrorResponse) => {
          console.log('Echec de chargement du journal d\'events ==> '+error.status);
    });
    console.log("here we go");

    this.serviceJuridiqueService.list().subscribe(
        (data: any) => {
            this.serviceJuridiqueList = [...data];
            if (this.serviceJuridiqueList.length > 0 && this.serviceJuridiqueList != null) {
                const serviceDistribution: ServiceJuridique = this.serviceJuridiqueList.find(s => s.code == 'NG_DIS');
                if (serviceDistribution) {
                    this.dossiersService.listByService(serviceDistribution.id).subscribe(
                        (data: any) => {
                            this.dossierJuridiqueDistributionList = [...data];
                            this.dossierJuridiqueFilteredDistributionList = [...this.dossierJuridiqueDistributionList];
                        },
                        (error: HttpErrorResponse) => {
                            console.log('Echec status ==> ' + error.status);
                        });
                }
                const serviceConciliation: ServiceJuridique = this.serviceJuridiqueList.find(s => s.code === 'CACPC');
                if (serviceConciliation) {
                    this.dossiersService.listByService(serviceConciliation.id).subscribe(
                        (data: any) => {
                            this.dossierJuridiqueConciliationList = [...data];
                            this.dossierJuridiqueFilteredConciliationList = [...this.dossierJuridiqueConciliationList];
                        },
                        (error: HttpErrorResponse) => {
                            console.log('Echec status ==> ' + error.status);
                        });
                }
                const serviceRef: ServiceJuridique = this.serviceJuridiqueList.find(s => s.code === 'CPP1');
                if (serviceRef) {
                    this.dossiersService.listByService(serviceRef.id).subscribe(
                        (data: any) => {
                            this.dossierJuridiqueRefereList = [...data];
                            this.dossierJuridiqueFilteredRefereList = [...this.dossierJuridiqueRefereList];
                        },
                        (error: HttpErrorResponse) => {
                            console.log('Echec status ==> ' + error.status);
                        });
                }
            }
        },
        (error: HttpErrorResponse) => {
            console.log('Echec status ==> ' + error.status);
        });
    this.statutService.list().subscribe(
        (data: any) => {
            this.statutList = [...data];
            this.statutListSortForRequete.push(this.statutList.find(s => s.code == 'NEW'));
            this.statutListSortForRequete.push(this.statutList.find(s => s.code == 'MEE'));
            this.statutCon = this.statutList.find(s => s.code == 'CON');
        });
    this.typeProcedureService.list().subscribe(
        (data: any) => {
            this.typeProcedureList = [...data];
        });
  }

  
    manageDossier(dossierJurudique: DossierJuridique): void {

    }

    getPartieByTypeLibelle(partiesJuridiqueList: PartieJuridique[], type: string): PartieJuridique[] {
        let parties: PartieJuridique[] = [];
        if (partiesJuridiqueList != null && partiesJuridiqueList.length > 0) {
            partiesJuridiqueList.forEach(p => {
                if (p.typePartieLibelle == type) {
                    parties.push(p);
                }
            });
        }
        parties = [...parties];
        console.log(parties);
        return parties;
    }

    filerDataRefere(val) {
        if (val) {
            val = val.toLowerCase();
        } else {
            return this.dossierJuridiqueFilteredRefereList = [...this.dossierJuridiqueRefereList];
        }

        const columns = Object.keys(this.dossierJuridiqueRefereList[0]);
        if (!columns.length) {
            return;
        }

        const rows = this.dossierJuridiqueRefereList.filter(function (d) {
            for (let i = 0; i <= columns.length; i++) {
                const column = columns[i];
                // console.log(d[column]);
                if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
                    return true;
                }
            }
        });
        this.dossierJuridiqueFilteredRefereList = rows;
    }

    accederAudienceForDiligence(dossier: DossierJuridique): void {
        if(dossier != null && dossier.audiencedistributionId != null) {
            this.transferDataService.setData(dossier.audiencedistributionId);
            this.router.navigate(['gestion/audience/audiencement']);
        } else {
            this.toastr.error('Pas de dossier ou d\'audience programmé.', ' Erreur !', {progressBar: true});
        }
    }

    openAgendaMiseEnEtat(dossier: DossierJuridique): void {
        if(dossier != null && dossier != undefined){
            const modal = this.modal.create({
                nzTitle: 'Calendrier d\'instruction du dossier : <b>'+dossier?.numerorg+'</b>',
                nzContent: AgendaMiseEnEtatComponent,
                nzViewContainerRef: this.viewContainerRef,
                nzWidth: 1050,
                nzStyle: {top: '50px'},
                nzComponentParams: {
                    dossierId: dossier?.id
                },
                nzFooter: null
            });
        }
        else{
            this.toastr.error('Dossier inexistant.', ' Erreur !', {progressBar: true});
        }
    }

    getListEvent(){
        this.router.navigateByUrl('gestion/utilisateur/user-events-audit');
    }


}
