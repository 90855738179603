import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {User} from "../../../models/gestion/utilisateur/user";
import {Observable} from "rxjs";
import {ManagedUserVM} from "../../../models/gestion/utilisateur/managedUserVM";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url: string = environment.backend + '/users';
  url1: string = environment.backend + '/user-menu';
  url2: string = environment.backend;

  constructor(private http: HttpClient) { }

  createUser(user: ManagedUserVM): Observable<Object> {
    return this.http.post(`${this.url}`, user);
  }

  deleteUser(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateUser(user: User): Observable<Object> {
    return this.http.put(`${this.url}`, user);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

  findAllByFonctionId(fonctionId: number): Observable<Object> {
    return this.http.get(`${this.url}/fonction/${fonctionId}/?page=0&size=10000000`);
  }

  //list des menus d'un user
  listMenuByUserId(userId: number): Observable<Object> {
    return this.http.get(`${this.url1}/all/${userId}`);
  }

  //list menu not in user's menu
  listMenuNotInByUserId(userId: number): Observable<Object> {
    return this.http.get(`${this.url1}/list-menu-not-in-user/${userId}`);
  }

  //delete menu of an user
  deleteMenuInUser(userId: number,menuId: number){
    return this.http.delete(`${this.url1}/${userId}/${menuId}`);
  }

  //create menus for user
  createListMenusForUser(userId: number, menuNumber: number[]): Observable<Object> {
    return this.http.post(`${environment.backend}/user-menu-many/${userId}`, menuNumber);
  }

  findAllMenuByUser(idUser: number){
    return this.http.get(`${this.url2}/display/menu-item/${idUser}`);
  }
}
