import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { TypeDocument } from 'src/app/models/gestion/dossier-juridique/type-document';

@Injectable({
  providedIn: 'root'
})
export class TypeDocumentService {

  url: string = environment.backend + '/typedocuments';

  constructor(private http: HttpClient) {
  }

  createTypeDocument(typeDocument: TypeDocument): Observable<Object> {
    return this.http.post(`${this.url}`, typeDocument);
  }

  deleteTypeDocument(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateTypeDocument(typeDocument: TypeDocument): Observable<Object> {
    return this.http.put(`${this.url}`, typeDocument);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

  listMiseEnEtat(): Observable<Object> {
    return this.http.get(`${this.url}/mise-en-etat`);
  }

  listProcedureCollective(): Observable<Object> {
    return this.http.get(`${this.url}/procedure-collective`);
  }

  listNonMiseEnEtat(): Observable<Object> {
    return this.http.get(`${this.url}/non-mise-en-etat`);
  }

  listByAudience(audienceId: number): Observable<Object> {
    return this.http.get(`${this.url}/audience/${audienceId}?page=0&size=10000000`);
  }

  listByService(serviceId: number): Observable<Object> {
    return this.http.get(`${this.url}/service/${serviceId}?page=0&size=10000000`);
  }

}
