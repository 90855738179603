import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DossierAuxilliaire} from '../../../models/gestion/dossier-juridique/dossier-auxilliaire';

@Injectable({
  providedIn: 'root'
})
export class DossierAuxilliaireService {

  url: string = environment.backend + '/dossier-auxilliaires';
  url1: string = environment.backend;

  constructor(private http: HttpClient) {
  }

  createDossierAuxilliaire(dossierAuxilliaire: DossierAuxilliaire): Observable<Object> {
    return this.http.post(`${this.url}`, dossierAuxilliaire);
  }

  deleteDossierAuxilliaire(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  getById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/${id}`);
  }

  getByIdDossier(id: number): Observable<Object> {
    return this.http.get(`${this.url}/dossier/${id}`);
  }

  getCurrentUserDossierAuxilliaire(startDate: string, endDate: string): Observable<Object> {

    /*const params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('page', 0)
      .set('size', 10000000);*/

    return this.http.get(`${this.url}/current?startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);

  }

  getCurrentUserDossierAuxilliaireByLogin(login: string, startDate: string, endDate: string): Observable<Object> {
    return this.http.get(`${this.url}/user/${login}?startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
    // return this.http.get(`${this.url}/user?login=${login}&startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
  }

  getCurrentUserDossierAuxilliaireByLoginWithoutPeriode(login: string): Observable<Object> {
    return this.http.get(`${this.url}/user-without-period/${login}?page=0&size=10000000`);
    // return this.http.get(`${this.url}/user?login=${login}&startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
  }

  getCurrentUserDossierAuxilliaireByLoginUp(dossierFilter): Observable<Object> {
    return this.http.post(`${this.url}/user?page=0&size=10000000`, dossierFilter);
    // return this.http.get(`${this.url}/user?login=${login}&startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
  }

  getAllCreatedByPersonne(personneId: number, startDate: string, endDate: string): Observable<Object> {
    return this.http.get(`${this.url}/personne/${personneId}?startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
    // return this.http.get(`${this.url}/user?login=${login}&startDate=${startDate}&endDate=${endDate}&page=0&size=10000000`);
  }

  getByDossierIdPartieId(dossierId: number, partieId: number): Observable<Object> {
    return this.http.get(`${this.url}/dossier/partie/${dossierId}/${partieId}`);
  }

  getByDossierIdPartieIdProfessionCode(dossierId: number, partieId: number, professionCode: string): Observable<Object> {
    return this.http.get(`${this.url}/dossier/partie/professionCode/${dossierId}/${partieId}/${professionCode}`);
  }

  getAllPersonnePhysiqueByDossierIdPartieIdProfessionCode(dossierId: number, partieId: number, professionCode: string): Observable<Object> {
    return this.http.get(`${this.url}/personnephysique/dossier/partie/professionCode/${dossierId}/${partieId}/${professionCode}`);
  }

  getAllByDossierIdAndProfessionCode(dossierId: number, professionCode: string): Observable<Object> {
    return this.http.get(`${this.url}/all-by-dossier-and-profession-code/${dossierId}/${professionCode}`);
  }

  getAllByDossierIdAndProfessionCodePublic(dossierId: number, professionCode: string): Observable<Object> {
    return this.http.get(`${this.url}/all-by-dossier-and-profession-code-for-public/${dossierId}/${professionCode}`);
  }


  // retirer un auxilliaire
  takeOffOne(auxiliaireId: number): Observable<Object> {
    return this.http.get(`${this.url}/take-off/${auxiliaireId}`);
  }

  getAllAuxiliairesByDossierId(dossierId: number): Observable<Object>{
    return this.http.get(`${this.url}/all/dossier/${dossierId}`);
  }

  getAllIntervenant(dossierId: number, currentUser: number): Observable<Object>{
    return this.http.get(`${this.url1}/intervenants/dossier/${dossierId}/current-user/${currentUser}`);
  }

  getCurrentIntervenant(dossierId: number, currentUser: number): Observable<Object>{
    return this.http.get(`${this.url1}/intervenant/dossier/${dossierId}/current-user/${currentUser}`);
  }
}
