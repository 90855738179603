import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Stomp } from "@stomp/stompjs";
import * as SockJSbackend from "sockjs-client";
import { Discussion } from "src/app/models/common/discussion";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  private stompClient: any;
  public notificationSubject: Subject<Discussion> = new Subject<Discussion>();
  private messageSubject: BehaviorSubject<Discussion[]> = new BehaviorSubject<
    Discussion[]
  >([]);
  private apiUrl = environment.websocket; // URL de base de votre backend

  constructor(private http: HttpClient) {
    this.initConnenctionSocket();
    //  this.requestNotificationPermission();
  }

  initConnenctionSocket() {
    const url = `${this.apiUrl}`;
    const socket = new SockJSbackend(url);
    this.stompClient = Stomp.over(socket);
  }

  joinRoom(roomId: string) {
    this.stompClient.connect({}, () => {
      this.stompClient.subscribe(`/topic/${roomId}`, (messages: any) => {
        const messageContent: any = JSON.parse(messages.body);
        //   console.log("message depuis le serveur", messageContent);
        const currentMessage = this.messageSubject.getValue();
        currentMessage.push(messageContent);
        this.messageSubject.next(currentMessage);
        // this.showNotification(messageContent); // Show notification
        this.notificationSubject.next(messageContent);
      });
      // this.stompClient.subscribe(`/topic/notifications`, (message) => {
      //   console.log("message depuis le serveur", message.body);
      //   this.notificationSubject.next(message.body);
      // })
    });
  }

  public sendNotification(notification: string) {
    this.stompClient.publish({
      destination: "/app/notify",
      body: notification,
    });
  }

  sendMessage(roomId: string, chatMessage: Discussion) {
    this.stompClient.send(
      `/app/discussion/${roomId}`,
      {},
      JSON.stringify(chatMessage)
    );
  }

  // sendMessage(roomId: string, chatMessage: Discussion) {
  //   return new Observable((observer) => {
  //     this.stompClient.send(`/app/discussion/${roomId}`, {}, JSON.stringify(chatMessage), {
  //       onSuccess: () => {
  //         observer.next();
  //         observer.complete();
  //       },
  //       onError: (error) => {
  //         observer.error(error);
  //       }
  //     });
  //   });
  // }

  getMessageSubject(): Observable<Discussion[]> {
    return this.messageSubject.asObservable();
  }

  private showNotification(messageContent: Discussion) {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        new Notification("New Message", {
          body: `${messageContent.expediteur}: ${messageContent.message}`,
          //  icon: 'assets/icon.png' // Adjust the icon path
        });
      }
    }
  }

  private requestNotificationPermission() {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") {
          console.error("Notification permission denied");
        }
      });
    }
  }
}
