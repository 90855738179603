import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  url: string = environment.backend + "/discussion";
  ai_url: string = environment.ai_backend + "/chat";

  constructor(private http: HttpClient) {}

  // IA side
  sendQuestion(question: any): Observable<Object> {
    return this.http.post(`${this.url}/ask/question`, question);
  }

  uploadPdfForAi(data: any): Observable<any> {
    return this.http.post(`${this.ai_url}/upload`, data);
  }

  getAllDocument(): Observable<any> {
    return this.http.get(`${this.ai_url}/documents`);
  }
  // IA side End

  sendMessage(message: any): Observable<any> {
    return this.http.post(`${this.url}/send`, message);
  }

  getChatList(
    dossierId: number,
    youId: number,
    meId: number,
    room: string
  ): Observable<Object> {
    return this.http.get(
      `${this.url}/read/dossier/${dossierId}/you/${youId}/me/${meId}/room/${room}`
    );
  }

  getChatListWithoutRead(
    dossierId: number,
    youId: number,
    meId: number,
    room: string
  ): Observable<Object> {
    return this.http.get(
      `${this.url}/discussions/dossier/${dossierId}/you/${youId}/me/${meId}/room/${room}`
    );
  }

  getMessagesNotifications(meId: number): Observable<Object> {
    return this.http.get(`${this.url}/notifications/me/${meId}`);
  }

  getNombreMessage(
    dossierId: number,
    youId: number,
    meId: number,
    room: string
  ): Observable<Object> {
    return this.http.get(
      `${this.url}/nombre-messages/dossier/${dossierId}/you/${youId}/me/${meId}/room/${room}`
    );
  }

  getNombreTotalMessage(
    dossierId: number,
    meId: number,
    room: string
  ): Observable<Object> {
    return this.http.get(
      `${this.url}/nb-new-messages/dossier/${dossierId}/me/${meId}/room/${room}`
    );
  }

  getNombreTotalNewMessage(meId: number, room: string): Observable<Object> {
    return this.http.get(
      `${this.url}/nb-total-new-messages/me/${meId}/room/${room}`
    );
  }

  getLastMessage(
    dossierId: number,
    youId: number,
    meId: number,
    room: string
  ): Observable<Object> {
    return this.http.get(
      `${this.url}/last-message/dossier/${dossierId}/you/${youId}/me/${meId}/room/${room}`
    );
  }
}
