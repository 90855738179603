import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Civilite} from "../../../models/gestion/parametrage/civilite";
import {Observable} from "rxjs";
import {SituationMatrimoniale} from "../../../models/gestion/parametrage/situation-matrimoniale";

@Injectable({
  providedIn: 'root'
})
export class SituationMatrimonialeService {

  url: string = environment.backend + '/situationmatrimoniales';

  constructor(private http: HttpClient) { }

  createSituationMatrimoniale(situationMatrimoniale: SituationMatrimoniale): Observable<Object> {
    return this.http.post(`${this.url}`, situationMatrimoniale);
  }

  deleteSituationMatrimoniale(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateSituationMatrimoniale(situationMatrimoniale: SituationMatrimoniale): Observable<Object> {
    return this.http.put(`${this.url}`, situationMatrimoniale);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

}
