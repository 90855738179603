
import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AudienceGenerate} from "../../../models/gestion/audience/audience-generate";
import {Audience} from "../../../models/gestion/audience/audience";

@Injectable({
  providedIn: 'root'
})
export class AudienceGenerateService {

  url: string = environment.backend + '/audiences';
  url2: string = environment.backend + '/audience';

  constructor(private http: HttpClient) {
  }

  createAudienceGenerate(audienceGenerate: AudienceGenerate): Observable<Object> {
    return this.http.post(`${this.url}/generate`, audienceGenerate);
  }

  createAudience(audience: Audience): Observable<Object> {
    return this.http.post(`${this.url}`, audience);
  }

  getAudienceById(audienceid: number): Observable<Object> {
    return this.http.get(`${this.url}/${audienceid}`);
  }

  deleteAudience(id: number): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  updateAudience(audience: Audience): Observable<Object> {
    return this.http.put(`${this.url}`, audience);
  }

  list(): Observable<Object> {
    return this.http.get(`${this.url}/?page=0&size=10000000`);
  }

  listById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/${id}`);
  }

  getAudienceByServiceId(serviceId: number): Observable<Object> {
    return this.http.get(`${this.url}/service/${serviceId}`);
  }

  listByServiceId(serviceId: number): Observable<Object> {
    return this.http.get(`${this.url}/service/${serviceId}`);
  }

  listByTypeProcedureId(typeProcedureId: number): Observable<Object> {
    return this.http.get(`${this.url}/type-procedure/${typeProcedureId}`);
  }

  listByTypeServiceId(typeServiceId: number): Observable<Object> {
    return this.http.get(`${this.url}/type-service/${typeServiceId}`);
  }

  listAudienceAdjudication(audienceId: number, minDays: number): Observable<Object> {
    return this.http.get(`${this.url}/${audienceId}/adjudications?minDays=${minDays}`);
  }


  listAudienceByDate(date: Date): Observable<Object> {
    return this.http.get(`${this.url}/date/${date}`);
  }

  listByPeriode(dateDebut: string, dateFin: string, anneeId: number): Observable<Object> {
    return this.http.get(`${this.url}/by-periode/${dateDebut}/${dateFin}/${anneeId}`);
  }

  //list des audience par programmeId
  listAudienceByProgrammeId(programmeId: number): Observable<Object> {
    return this.http.get(`${this.url2}/by-programmeId/${programmeId}`);
  }

  //repartition-jugees-consulaire-par audience-de-service
  repartirJugesConsulaire(dateDebut: string, dateFin: string): Observable<Object> {
    return this.http.get(`${this.url}/repartir-juges-consulaire/periode/${dateDebut}/${dateFin}`);
  }

  getUserByAudienceId(audienceId: number): Observable<Object> {
    return this.http.get(`${this.url}/find-user/${audienceId}`);
  }

  getAllUsersByAudienceId(audienceId: number): Observable<Object> {
    return this.http.get(`${this.url}/users/${audienceId}?page=0&size=10000000`);
  }

  /*getAllAudienceByUserLogin(loginUser: string): Observable<Object> {
    return this.http.get(`${this.url}/users/login/${loginUser}`);
  }*/

  getAllAudienceByUserLogin(loginUser: string): Observable<Object> {
    return this.http.get(`${this.url}/by-user-login/${loginUser}`);
  }
//audiences by service, login, annee judiciaire fredyyyy
  getAllAudienceByUserLoginServiceAndAnnee(loginUser: string, serviceId: number, anneeId: number): Observable<Object> {
    return this.http.get(`${this.url}/by-user-login/service/anneeId/${loginUser}/${serviceId}/${anneeId}`);
  }


  getAllByServiceAndPeriode(serviceId: number, dateDebut: string, dateFin: string): Observable<Object> {
    return this.http.get(`${this.url}/service/${serviceId}/periode/${dateDebut}/${dateFin}`);
  }

  getAllAudienceByAnneeJudiciaireId(anneeId: number){
    return this.http.get(`${this.url}/annee/${anneeId}`);
  }

  //list audiences by periode et service et année de freddy mais ca ne prends pas les dates hein
  getAllByServiceAndPeriodeAndAnnee(serviceId: number, anneeId: string): Observable<Object> {
    return this.http.get(`${this.url}/service/date-range/${serviceId}/${anneeId}`);
  }

  //list audiences by periode et service et année de freddy mais ca ne prends pas les dates hein
  getAllByServiceAndAnnee(serviceId: number, anneeId: string): Observable<Object> {
    return this.http.get(`annees/audience/${serviceId}/${anneeId}`);
  }

}
