import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../services/common/auth.service";
import {LocalStoreService} from "../services/common/local-store.service";
import {IMenuItem, NavigationService} from "../services/common/navigation.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  nav: IMenuItem[];
  constructor(
    private router: Router,
    private auth: AuthService,
    private store: LocalStoreService,
    private navService: NavigationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // get the active nav
    this.navService.menuItems$.subscribe(items => {
      this.nav = items;
    });
    const token = this.store.getItem('access_token');
    if(token) {
      const currentUser = this.auth.getUserFromJwtToken(token);
      if(currentUser!=null) {
        const activeRoute = window.location.hash || window.location.pathname;
        const openRoutes: string[] = ['/', '/login', '/forgot-password', '/change-password'];
        if(openRoutes.indexOf(activeRoute.trim()) !== -1) {
          return true;
        }

        return true;
      }
    }else{
      // not logged in so redirect to login page with the return url
      //this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
      this.router.navigateByUrl('/');
      return false;
    }
  }

}
