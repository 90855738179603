<div class="row">
  <div
    *ngIf="documentJuridiqueList == null || documentJuridiqueList.length == 0"
    class="col-md-12"
    style="margin-top: 20px"
  >
    <br /><br />
    <ngb-alert [type]="'info'">
      <strong class="text-capitalize"> info !</strong>
      Aucun document n'est trouvé ou affiché.
    </ngb-alert>
  </div>

  <div
    *ngIf="documentJuridiqueList != null && documentJuridiqueList.length > 0"
    class="col-md-12"
    style="margin-top: 20px"
  >
    <div class="card o-hidden">
      <ngx-datatable
        [columnMode]="'force'"
        [footerHeight]="50"
        [headerHeight]="50"
        [messages]="{ emptyMessage: 'Aucune donnée à afficher!' }"
        [rowHeight]="'auto'"
        [limit]="4"
        [rows]="documentJuridiqueFiltered"
        class="material fullscreen"
        style="height: auto; box-shadow: none"
      >
        <ngx-datatable-column name="typedocumentLibelle">
          <ng-template ngx-datatable-header-template>
            <span class="font-weight-bold text-14"> Type de document </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="refdocument">
          <ng-template ngx-datatable-header-template>
            <span class="font-weight-bold text-14">Nom du fichier</span>
          </ng-template>
          <ng-template [width] = "50"
            let-row="row"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
            {{ row?.submittedfilename }} <br />
            <span class="font-weight-bold text-12 text-danger">
              Pièce envoyé par: {{ row.createdBy }}</span
            >
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="mimetype">
          <ng-template ngx-datatable-header-template>
            <span class="font-weight-bold text-14">Type de fichier</span>
          </ng-template>
          <!-- <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{ row.mimetype | slice:12}}
                    </ng-template> -->
        </ngx-datatable-column>
        <ngx-datatable-column name="createdDate">
          <ng-template ngx-datatable-header-template>
            <span class="font-weight-bold text-14">Date de création</span>
          </ng-template>
          <ng-template
            let-row="row"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
            {{ row.createdDate | date : "dd MMMM yyyy HH:mm" }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="createdDate">
          <ng-template ngx-datatable-header-template>
            <span class="font-weight-bold text-14">Auteur</span>
          </ng-template>
          <ng-template
            let-row="row"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
              {{ row.createdBy === 'anonymousUser' ? 'PUBLIC' : row.createdBy }}

          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column>
          <ng-template ngx-datatable-header-template>
            <span class="font-weight-bold text-14">État</span>
          </ng-template>
          <ng-template
            let-row="row"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
            <span
              *ngIf="row.estDocumentValider == true"
              style="color: green; font-weight: bold"
            >
              VALIDÉ
            </span>
            <span
              *ngIf="row.estDocumentValider == false"
              style="color: red; font-weight: bold"
            >
              NON VALIDÉ
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column>
          <ng-template ngx-datatable-header-template>
            <span class="font-weight-bold text-14">Actions</span>
          </ng-template>
          <ng-template
            let-row="row"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
            <!--<strong>{{ row.code }}</strong>-->
            <!--  <button type="button" class="btn btn-primary btn-sm m-1" (click)="makeForm(row)">
                            <i class="i-Pen-5"> </i>
                          </button>-->
            <nz-space>
              <!--<strong>{{ row.code }}</strong>-->
              <button
                (click)="printFileToDownloadInNavigator(row.refdocument)"
                nz-button
                nzShape="circle"
                nzType="default"
              >
                <i nz-icon nzTheme="outline" nzType="download"></i>
              </button>
              <button
                (click)="printFileToViewInNavigator(row.refdocument)"
                nz-button
                nzShape="circle"
                nzType="primary"
                style="margin-left: 10px"
              >
                <i nz-icon nzTheme="outline" nzType="eye"></i>
              </button>
              <button
                (click)="validerDocument(row)"
                nz-button
                nzShape="circle"
                nzType="default"
                *ngIf="
                  row.estDocumentValider == false &&
                  (currentFonction == 'JUG' || currentFonction == 'PRT')
                "
                style="margin-left: 10px"
              >
                <i nz-icon nzTheme="outline" nzType="check"></i>
              </button>

<!--              <button-->
<!--                (click)="openAjouterNoteDialog(row)"-->
<!--                nz-button-->
<!--                nzShape="circle"-->
<!--                nzType="default"-->
<!--                *ngIf="-->
<!--                  row.estDocumentValider == true &&-->
<!--                  (currentFonction == 'JUG' || currentFonction == 'PRT')-->
<!--                "-->
<!--                style="margin-left: 10px"-->
<!--              >-->
<!--                <i nz-icon nzTheme="outline" nzType="edit"></i>-->
<!--              </button>-->

              <!-- <nz-popconfirm
                                    style="margin-left: 10px;"
                                    (nzOnConfirm)="confirmationValidation(row)"
                                    [nzCancelText]="'Non'"
                                    [nzOkText]="'Oui'"
                                    [nzTitle]="'Valider ce document ?'"
                                    *ngIf="row.estDocumentValider == false && (currentFonction == 'JUG' || currentFonction== 'PRT')">
                                <button
                                        nz-button
                                        nz-popconfirm
                                        nzPopconfirmPlacement="top"
                                        nzShape="round"
                                        nzType="primary">
                                    <i nz-icon nzTheme="outline" nzType="check"></i>
                                </button>
                            </nz-popconfirm>-->
            </nz-space>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <div class="separator-breadcrumb"></div>
    <!-- <div>
          <app-explorateur-documents-details>
          </app-explorateur-documents-details>
        </div> -->
  </div>
</div>
