<!-- <div class="customizer" [ngClass]="{ open: isOpen }">
  <div class="handle" (click)="isOpen = !isOpen">
    <i class="i-Newspaper"></i>
  </div>
  <div class="customizer-body" perfectScrollbar>
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel title="Tâches en cours">
        <ng-template ngbPanelContent>
          <div class="spaceAfter d-flex">
            <div >
              <i class="i-Receipt-3  text-warning mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>Juge ADJOVI Nicodème</span>
                <span class="flex-grow-1 text-small">AUDIENCE</span>
                <span class="text-small text-muted ml-auto">21/01/2021 à 10h</span>
              </p>
              <p class="text-small text-muted m-0"> 4 dossiers prévus pour cette audience à venir</p>
            </div>
          </div>
          <div class="spaceAfter d-flex">
            <div >
              <i class="i-Receipt-3  text-warning mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>Juge ADJOVI Nicodème</span>
                <span class="flex-grow-1 text-small">AUDIENCE</span>
                <span class="text-small text-muted ml-auto" style="text-decoration: underline; text-decoration-color: red;">20/01/2021 à 11h</span>
              </p>
              <p class="text-small text-muted m-0"> 4 dossiers prévus pour cette audience à venir</p>
            </div>
          </div>
          <div class="spaceAfter d-flex">
            <div >
              <i class="i-Receipt-3  text-warning mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>Juge ADJOVI Nicodème</span>
                <span class="flex-grow-1 text-small">AUDIENCE</span>
                <span class="text-small text-muted ml-auto" style="text-decoration: underline; text-decoration-color: red;">20/01/2021 à 15h</span>
              </p>
              <p class="text-small text-muted m-0"> 4 dossiers prévus pour cette audience à venir</p>
            </div>
          </div>
          <div class="spaceAfter d-flex">
            <div >
              <i class="i-Folders  text-warning mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>Juge ADJOVI Nicodème</span>
                <span class="flex-grow-1 text-small">DOSSIER</span>
                <span class="text-small text-muted ml-auto">16/01/2021</span>
              </p>
              <p class="text-small text-muted m-0"> Affaire entre SONEB et SBEE</p>
            </div>
          </div>
          <div class="spaceAfter d-flex">
            <div >
              <i class="i-Folders  text-warning mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>Juge ADJOVI Nicodème</span>
                <span class="flex-grow-1 text-small">DOSSIER</span>
                <span class="text-small text-muted ml-auto">16/01/2021</span>
              </p>
              <p class="text-small m-0"> Affaire entre SONEB et SBEE</p>
            </div>
          </div>
          
          <div class="spaceAfter d-flex">
            <div >
              <i class="i-Folders  text-warning mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>Juge ADJOVI Nicodème</span>
                <span class="flex-grow-1 text-small">DOSSIER</span>
                <span class="text-small text-muted ml-auto">16/01/2021</span>
              </p>
              <p class="text-small text-muted m-0"> Affaire entre SONEB et SBEE</p>
            </div>
          </div>
          
        </ng-template>
      </ngb-panel>

      <ngb-panel title="Mes dernières notes">
        <ng-template ngbPanelContent>
          <div class="spaceAfter d-flex">
            <div >
              <i class="i-Pen-5  text-warning mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>Maître ADJAHO Serge</span>
                <span class="flex-grow-1">Audience</span>
                <span class="text-small text-muted ml-auto">21/01/2021</span>
              </p>
              <p class="text-small text-muted m-0"> Les avocats ne se sont pas entendus au préalable. Report d'audience</p>
            </div>
          </div>
          
          <div class="spaceAfter d-flex">
            <div >
              <i class="i-Pen-5  text-warning mr-1"></i>
            </div>
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>Maître ADJAHO Serge</span>
                <span class="flex-grow-1">Requêtes</span>
                <span class="text-small text-muted ml-auto">21/01/2021</span>
              </p>
              <p class="text-small text-muted m-0"> Les avocats n'ont pas bien suivis le format indiqué pour la demande.</p>
            </div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel title="Évènements">
        <ng-template ngbPanelContent perfectScrollbar>

          <div  style="height: 100%;" >
            <div  class="notification-dropdown rtl-ps-none" perfectScrollbar>
              <div class="spaceAfter d-flex">
                <div >
                  <i class="i-Folders  text-warning mr-1"></i>
                </div>
                <div class="notification-details flex-grow-1">
                  <p class="m-0 d-flex align-items-center">
                    <span>Juge ADJOVI Nicodème</span>
                    <span class="flex-grow-1">IP: 192.458.54.02</span>
                    <span class="text-small text-muted ml-auto">21/01/2021</span>
                  </p>
                  <p class="text-small text-muted m-0"> Mise à jour de dossier BJ/TCC/2021/2524</p>
                </div>
              </div>
              
              <div class="spaceAfter d-flex">
                <div >
                  <i class="i-Folders  text-warning mr-1"></i>
                </div>
                <div class="notification-details flex-grow-1">
                  <p class="m-0 d-flex align-items-center">
                    <span>Juge ADJOVI Nicodème</span>
                    <span class="flex-grow-1">IP: 192.458.54.02</span>
                    <span class="text-small text-muted ml-auto">21/01/2021</span>
                  </p>
                  <p class="text-small text-muted m-0"> Mise à jour de dossier BJ/TCC/2021/2524</p>
                </div>
              </div>
              
              <div class="spaceAfter d-flex">
                <div >
                  <i class="i-Folders  text-warning mr-1"></i>
                </div>
                <div class="notification-details flex-grow-1">
                  <p class="m-0 d-flex align-items-center">
                    <span>Juge ADJOVI Nicodème</span>
                    <span class="flex-grow-1">IP: 192.458.54.02</span>
                    <span class="text-small text-muted ml-auto">21/01/2021</span>
                  </p>
                  <p class="text-small text-muted m-0"> Mise à jour de dossier BJ/TCC/2021/2524</p>
                </div>
              </div>
              
              <div class="spaceAfter d-flex">
                <div >
                  <i class="i-Folders  text-warning mr-1"></i>
                </div>
                <div class="notification-details flex-grow-1">
                  <p class="m-0 d-flex align-items-center">
                    <span>Juge ADJOVI Nicodème</span>
                    <span class="flex-grow-1">IP: 192.458.54.02</span>
                    <span class="text-small text-muted ml-auto">21/01/2021</span>
                  </p>
                  <p class="text-small text-muted m-0"> Mise à jour de dossier BJ/TCC/2021/2524</p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div> -->

<div *ngIf="currentFonction != '' && (currentFonction == 'PRT' || currentFonction == 'JUG' ||
currentFonction == 'AVT' ||  currentFonction == 'JUC' ||  currentFonction == 'ADM') " class="customizer" [ngClass]="{ open: isOpen }">
  <div class="handle" (click)="isOpen = !isOpen">
    <i class="i-Newspaper">
      <span class="badge" *ngIf="currentFonction != '' && currentFonction == 'PRT' && (dossierJuridiqueFilteredRefereList.length+dossierJuridiqueFilteredConciliationList.length+dossierJuridiqueFilteredDistributionList.length+dossierJuridiqueFilteredAttentePVList.length)>0">
        {{dossierJuridiqueFilteredRefereList.length+dossierJuridiqueFilteredConciliationList.length+dossierJuridiqueFilteredDistributionList.length+dossierJuridiqueFilteredAttentePVList.length}}
      </span>
      <span class="badge" *ngIf="currentFonction != '' && currentFonction != 'PRT' && dossierJuridiqueFilteredAttentePVList.length>0">
        {{dossierJuridiqueFilteredAttentePVList.length}}
      </span>
      <span *ngIf="dossierJuridiqueFilteredAttentePVList.length<=0 || ((dossierJuridiqueFilteredRefereList.length+dossierJuridiqueFilteredConciliationList.length+dossierJuridiqueFilteredDistributionList.length+dossierJuridiqueFilteredAttentePVList.length)<=0)" id="zero">
        0
      </span>
    </i>
  </div>
  <div class="customizer-body" perfectScrollbar>
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel *ngIf="currentFonction != '' && currentFonction == 'PRT' ">
        <ng-template ngbPanelHeader>
          <div class="d-flex align-items-center col-md-12">
              <button ngbPanelToggle class=" col-md-10 btn btn-link p-0">PROCÉDURE PRÉSIDENTIELLE <span style="color: red !important;" >{{(dossierJuridiqueFilteredRefereList.length)}}</span></button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div *ngIf="dossierJuridiqueFilteredRefereList.length>0 || dossierJuridiqueFilteredRefereList!=null">
            <div *ngFor="let dossier of dossierJuridiqueFilteredRefereList" class="spaceAfter d-flex">
              <div >
                <i class="i-Receipt-3  text-warning mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span>{{dossier.numerorg}}</span>
                  <span class="text-small text-muted ml-auto">Demandé le {{ dossier.datedemande | date : 'dd MMMM' }}</span>
                </p>
                <p *ngIf="getPartieByTypeLibelle(dossier.parties, 'DEMANDEUR').length > 0" class="text-small m-0">
                  <span *ngFor="let partie of getPartieByTypeLibelle(dossier.parties, 'DEMANDEUR')">
                    Demandeur: {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }}
                    {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                  </span> 
                </p>
                <p *ngIf="getPartieByTypeLibelle(dossier.parties, 'DEFENDEUR').length > 0" class="text-small m-0">
                  <span *ngFor="let partie of getPartieByTypeLibelle(dossier.parties, 'DEFENDEUR')">
                    Défendeur: {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }}
                    {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                  </span> 
                </p>
              </div>
            </div> 
          </div>
          <div *ngIf="dossierJuridiqueFilteredRefereList.length==0 || dossierJuridiqueFilteredRefereList==null">
            Aucune donnée à afficher
          </div>         
        </ng-template>
      </ngb-panel>

      <ngb-panel *ngIf="currentFonction != '' && currentFonction == 'PRT' ">
        <ng-template ngbPanelHeader>
          <div class="d-flex align-items-center col-md-12">
              <button ngbPanelToggle class=" col-md-10 btn btn-link p-0">CONCILIATION <span style="color: red !important;" >{{(dossierJuridiqueFilteredConciliationList.length)}}</span></button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div *ngIf="dossierJuridiqueFilteredConciliationList.length>0 || dossierJuridiqueFilteredConciliationList!=null">
            <div *ngFor="let dossier of dossierJuridiqueFilteredConciliationList" class="spaceAfter d-flex">
              <div >
                <i class="i-Receipt-3  text-warning mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span>{{dossier.numerorg}}</span>
                  <span class="text-small text-muted ml-auto">Demandé le {{ dossier.datedemande | date : 'dd MMMM' }}</span>
                </p>
                <p *ngIf="getPartieByTypeLibelle(dossier.parties, 'DEMANDEUR').length > 0" class="text-small m-0">
                  <span *ngFor="let partie of getPartieByTypeLibelle(dossier.parties, 'DEMANDEUR')">
                    Demandeur: {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }}
                    {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                  </span> 
                </p>
                <p *ngIf="getPartieByTypeLibelle(dossier.parties, 'DEFENDEUR').length > 0" class="text-small m-0">
                  <span *ngFor="let partie of getPartieByTypeLibelle(dossier.parties, 'DEFENDEUR')">
                    Défendeur: {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }}
                    {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                  </span> 
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="dossierJuridiqueFilteredConciliationList.length==0 || dossierJuridiqueFilteredConciliationList==null">
            Aucune donnée à afficher
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel *ngIf="currentFonction != '' && currentFonction == 'PRT' ">
        <ng-template ngbPanelHeader>
          <div class="d-flex align-items-center col-md-12">
              <button ngbPanelToggle class=" col-md-10 btn btn-link p-0">DISTRIBUTION <span style="color: red !important;" >{{(dossierJuridiqueFilteredDistributionList.length)}}</span></button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div  *ngIf="dossierJuridiqueFilteredDistributionList.length>0 || dossierJuridiqueFilteredDistributionList!=null">
            <div *ngFor="let dossier of dossierJuridiqueFilteredDistributionList" class="spaceAfter d-flex">
              <div >
                <i class="i-Receipt-3  text-warning mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span>{{dossier.numerorg}}</span>
                  <span class="text-small text-muted ml-auto">Demandé le {{ dossier.datedemande | date : 'dd MMMM' }}</span>
                </p>
                <p *ngIf="getPartieByTypeLibelle(dossier.parties, 'DEMANDEUR').length > 0" class="text-small m-0">
                  <span *ngFor="let partie of getPartieByTypeLibelle(dossier.parties, 'DEMANDEUR')">
                    Demandeur: {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }}
                    {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                  </span> 
                </p>
                <p *ngIf="getPartieByTypeLibelle(dossier.parties, 'DEFENDEUR').length > 0" class="text-small m-0">
                  <span *ngFor="let partie of getPartieByTypeLibelle(dossier.parties, 'DEFENDEUR')">
                    Défendeur: {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }}
                    {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                  </span> 
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="dossierJuridiqueFilteredDistributionList.length==0 || dossierJuridiqueFilteredDistributionList==null">
            Aucune donnée à afficher
          </div> 
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelHeader>
          <div class="d-flex align-items-center col-md-12">
              <button ngbPanelToggle class=" col-md-10 btn btn-link p-0">EN ATTENTE DE PV OU DE PIECE(S) <span style="color: red !important;" >{{(dossierJuridiqueFilteredAttentePVList.length)}}</span></button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div *ngIf="dossierJuridiqueFilteredAttentePVList.length>0 || dossierJuridiqueFilteredAttentePVList!=null">
            <div *ngFor="let dossier of dossierJuridiqueFilteredAttentePVList" class="spaceAfter d-flex">
              <div >
                <i class="i-Receipt-3  text-warning mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span>{{dossier.numerorg}}</span>
                  <span class="text-small text-muted ml-auto">Demandé le {{ dossier.datedemande | date : 'dd MMMM' }}</span>
                </p>
                <p *ngIf="getPartieByTypeLibelle(dossier.parties, 'DEMANDEUR').length > 0" class="text-small m-0">
                  <span *ngFor="let partie of getPartieByTypeLibelle(dossier.parties, 'DEMANDEUR')">
                    Demandeur: {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }}
                    {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                  </span> 
                </p>
                <p *ngIf="getPartieByTypeLibelle(dossier.parties, 'DEFENDEUR').length > 0" class="text-small m-0">
                  <span *ngFor="let partie of getPartieByTypeLibelle(dossier.parties, 'DEFENDEUR')">
                    Défendeur: {{ partie.personnephysique != null ? (partie.personnephysique.nom + ' ' + partie.personnephysique.prenom) : '' }}
                    {{ partie.personnemorale != null ? (partie.personnemorale.raisonsociale) : '' }}
                  </span> 
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="dossierJuridiqueFilteredAttentePVList.length==0 || dossierJuridiqueFilteredAttentePVList==null">
            Aucune donnée à afficher
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelHeader>
          <div class="d-flex align-items-center col-md-12">
              <button ngbPanelToggle class=" col-md-10 btn btn-link p-0">JOURNAL DES ÉVÈNEMENTS <span style="color: midnightblue !important;" >({{journalOfUserFiltered.length}})</span></button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent perfectScrollbar>
          <div *ngIf="journalOfUserFilteredTwenty.length>0 || journalOfUserFilteredTwenty!=null">
            <div *ngFor="let event of journalOfUserFilteredTwenty" class="spaceAfter d-flex">
              <div >
                <i class="i-Receipt-3  text-warning mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
                <p class="m-0 d-flex align-items-center">
                  <span style="margin-right: 0.2em; font-weight: 600;">{{event.createdBy}} </span>
                  <span class="flex-grow-1" style="font-size:10px; font-weight: 600;"> {{event.addressip}}</span>
                  <span class="text-small text-muted ml-auto">{{event.dateevt | date:'dd MMMM yyyy à hh:mm:ss'}}</span>
                </p>
                <p class="text-small text-muted m-0"> {{event.description}}</p>
              </div>
            </div> 
            <div class="dropdown-item d-flex" style="height: auto;" *ngIf="journalOfUserFiltered.length > 50">
              <button class="btn btn-primary btn-block m-1dropdown-item"  (click) ="getListEvent()">Lire tous les évènements</button>
            </div>
          </div>
          <div *ngIf="journalOfUserFilteredTwenty.length==0 || journalOfUserFilteredTwenty==null">
            Aucune donnée à afficher
          </div>  
        </ng-template>
      </ngb-panel>

    </ngb-accordion>
  </div>
</div>
