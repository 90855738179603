<h3>
    Ajouter un document au dossier : <strong> {{ numerorg }} </strong>
</h3>
<div *ngIf="!bienIsSave" class="modal-body">
    <form [formGroup]="docJuridiqueForm">
        <div class="row">
            <div
                    class="card-body row"
                    style="
          border-radius: 10px;
          box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
          border: 0;
          margin: 6px;
        "
            >
                <div
                        class="col-md-12 mb-3"
                        style="
            border-bottom: 1px solid #eee;
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
          "
                >
                    <h5 class="card-title">Ajout du document</h5>
                </div>
                <div class="col-md-6 form-group mb-3">
                    <label for="code">Description</label>
                    <textarea
                            class="form-control"
                            formControlName="description"
                            id="code"
                            placeholder="Entrer la description du document "
                            type="textarea"></textarea>
                </div>
                <div class="col-md-6 form-group mb-3">
                    <label>Type Document</label>
                    <ng-select
                            (change)="getTypeDocLib()"
                            [(ngModel)]="typeDocumentId"
                            [ngModelOptions]="{ standalone: true }"
                            id="typeDocumentId"
                            name="Type Document"
                            ngDefaultControl
                            placeholder="Entrer le type de document"
                    >
                        <ng-option *ngFor="let typeDoc of typeDocList" [value]="typeDoc.id">
                            {{ typeDoc.libelle }}
                        </ng-option
                        >
                    </ng-select>
                </div>
                <div
                        *ngIf="typeDocument == 'STENOTYPIE'"
                        class="col-md-6 form-group mb-3"
                >
                    <label for="code"
                    >Audience<span class="required-field">*</span>
                    </label>
                    <ng-select
                            [(ngModel)]="audienceId"
                            [ngModelOptions]="{ standalone: true }"
                            id="audienceId"
                            placeholder="Entrer l'audience"
                    >
                        <ng-option *ngFor="let audi of audienceList" [value]="audi.id">
                            {{ audi.date | date : "dd MMMM yyyy à hh:mm:ss" }} | {{
                                audi.chambreLibelle
                            }}
                            | {{ audi.salleLibelle }}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 form-group mb-3">
                    <label for="code"
                    >Document<span class="required-field">*</span>
                    </label>
                    <br/>
                    <input
                        #fileInput
                        (change)="handleFileInput($event)"
                        accept=".pdf,.doc,.docx,.png,.jpeg,.jpg"
                        type="file"
                    />
                    <br/>
                    <br/>
                    <span *ngIf="fileSize != null"
                    >Taille du fichier:
            <span style="font-weight: bold">{{ fileSize }} Mo</span></span
                    >
                    <br/>
                    <br/>
                    <span *ngIf="errorMessage" style="color: red">{{
                            errorMessage
                        }}</span>
                </div>
            </div>
            <div
                    *ngIf="typeDocument == 'PLACARD'"
                    class="card-body row"
                    style="
          border-radius: 10px;
          margin: 6px;
          box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
          border: 0;
        "
            >
                <div
                        class="col-md-12 mb-3"
                        style="
            border-bottom: 1px solid #eee;
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
          "
                >
                    <h5 class="card-title">Informations sur le Bien</h5>
                </div>
                <div class="col-md-6 form-group mb-3">
                    <label for="code">Type<span class="required-field">*</span> </label>
                    <ng-select
                            formControlName="typeBienId"
                            id="typeBienId"
                            placeholder="Entrer le type de Bien"
                    >
                        <ng-option
                                *ngFor="let typeBie of typeBienList"
                                [value]="typeBie.id"
                        >
                            {{ typeBie.libelle }}
                        </ng-option
                        >
                    </ng-select>
                </div>
                <div class="col-md-6 form-group mb-3">
                    <label for="code"
                    >Libelle<span class="required-field">*</span>
                    </label>
                    <input
                            class="form-control"
                            formControlName="libelle"
                            id="code"
                            placeholder="Entrer le libelle du Bien"
                            type="text"
                    />
                </div>
                <div class="col-md-6 form-group mb-3">
                    <label for="code"
                    >Caractéristiques<span class="required-field">*</span>
                    </label>
                    <textarea
                            class="form-control"
                            formControlName="caracteristique"
                            id="code"
                            placeholder="Entrer les caractéristiques du Bien"
                            type="textarea"
                    ></textarea>
                </div>
                <div class="col-md-6 form-group mb-3">
                    <label for="code">Valeur<span class="required-field">*</span> </label>
                    <input
                            class="form-control"
                            formControlName="valeurCession"
                            id="code"
                            placeholder="Entrer la valeur du Bien"
                            type="text"
                    />
                </div>
                <!-- <div class="col-md-6 form-group mb-3">
                        <label for="code">Acheteur<span class="required-field">*</span> </label>
                        <ng-select [(ngModel)]="personneAutocompleteAcheteur" required [ngModelOptions]="{standalone: true}" id="personneAutocomplet" placeholder="Entrer Acheteur">
                          <ng-option *ngFor="let personneAutocomplet of personneAutocompleteList" [value]="personneAutocomplet">
                              {{personneAutocomplet.display}}</ng-option>
                        </ng-select>
                    </div> -->
                <div class="col-md-6 form-group mb-3">
                    <label for="code"
                    >Acheteur<span class="required-field">*</span>
                    </label>
                    <input
                            class="form-control"
                            formControlName="acheteur"
                            id="acheteur"
                            placeholder="Entrer l'acheteur du Bien"
                            type="text"
                    />
                </div>
                <div class="col-md-6 form-group mb-3">
                    <label for="code"
                    >Propriétaire<span class="required-field">*</span>
                    </label>
                    <ng-select
                            [(ngModel)]="personneAutocompleteProprietaire"
                            [ngModelOptions]="{ standalone: true }"
                            id="personneAutocomplet"
                            placeholder="Entrer le Propriétaire du Bien"
                    >
                        <ng-option
                                *ngFor="let personneAutocomplet of personneAutocompleteList"
                                [value]="personneAutocomplet"
                        >
                            {{ personneAutocomplet.display }}
                        </ng-option
                        >
                    </ng-select>
                </div>
            </div>
            <hr/>

            <div class="col-md-12" style="margin-top: 1px">
                <btn-loading
                        (click)="enregistrerAll()"
                        [loading]="isLoadingData"
                        btnClass="btn-save"
                        class="float-right"
                >Enregistrer
                </btn-loading
                >
                <button
                        (click)="resetForm()"
                        class="btn m-1 ml-2 btn-cancel"
                        type="button"
                >
                    Annuler
                </button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="bienIsSave" class="modal-body">
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 text-center">
            <ngb-alert [type]="'info'">
                <strong class="text-capitalize"> info !</strong>
                Le Bien du Placard a été enregistré avec succès sous le numéro <br/>
                <span style="text-align: center; font-weight: bold; font-size: 25px">
          {{ submittedBien.code }} </span
                >.
            </ngb-alert>
            <button
                    (click)="okButton()"
                    class="btn btn-outline-secondary"
                    type="button"
            >
                Ok
            </button>
        </div>
    </div>
</div>
