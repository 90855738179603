import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ProjectionDossierComponent } from './shared/projection-dossier/projection-dossier.component';
import { AudienceDetailsComponent } from './shared/audience-details/audience-details.component';
import { AuthGuard } from './helpers/auth.guard';
import { PlacardDrawingComponent } from './shared/placard-drawing/placard-drawing.component';
import { EnregistrerDossierJuridiqueComponent } from './gestion/dossier-juridique/enregistrer-dossier-juridique/enregistrer-dossier-juridique.component';
import { NotFoundComponent } from './gestion/others/not-found/not-found.component';
import { CalendrierAudienceComponent } from './role-audience/calendrier-audience/calendrier-audience.component';
import { DocumentEditorComponent } from './document-editor/document-editor.component';
import { TestgraphComponent } from './testgraph/testgraph.component';
import { EnregistrerDossierParticulierComponent } from './gestion/dossier-juridique/enregistrer-dossier-particulier/enregistrer-dossier-particulier.component';
import { Sign2Component } from './sign2/sign2.component';
import { Sign1Component } from './sign1/sign1.component';
import { GrafpetitcreanceComponent } from './grafpetitcreance/grafpetitcreance.component';
import { GrafprocedureComponent } from './grafprocedure/grafprocedure.component';
import {NoteEditorComponent} from './note-editor/note-editor.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,

    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./auth/auth.module').then((a) => a.AuthModule),
      },
        {
            path: 'note-editor',
            component: NoteEditorComponent,
        },
      {
        path: 'placard-projection',
        component: PlacardDrawingComponent,
      },
      {
        path: 'audience-details',
        component: AudienceDetailsComponent,
      },
      {
        path: 'projection-dossier',
        component: ProjectionDossierComponent,
      },
      {
        path: 'gestion',
        loadChildren: () =>
          import('./gestion/gestion.module').then((g) => g.GestionModule),
        canActivate: [AuthGuard],
      },
      { path: 'document-editor', component: DocumentEditorComponent },
      {
        path: '',
        redirectTo: '/auth',
        pathMatch: 'full',
      },

      {
        path: '',
        loadChildren: () =>
          import('./search/search.module').then((s) => s.SearchModule),
      },
      {
        path: 'particulier',
        loadChildren: () =>
          import('./particulier-menu/particulier-menu.module').then(
            (p) => p.ParticulierMenuModule
          ),
      },
      {
        path: 'audience',
        loadChildren: () =>
          import('./role-audience/role-audience.module').then(
            (p) => p.RoleAudienceModule
          ),
      },
      // Route for 404 request
        // fix for task
    ],
  },
  { path: 'graph', component: TestgraphComponent
  },
  { path: 'statistiquepetitecreance', component: GrafpetitcreanceComponent },
  {
    path: 'statistiqueprocedurescollectives',
    component: GrafprocedureComponent,
  },
  { path: 'login', component: Sign2Component },
  { path: 'professionnel', component: Sign1Component },
  { path: '**', pathMatch: 'full', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
