import {IMenuItem} from "../../services/common/navigation.service";

export class SecretaireJudiciaire {
  static menuList: IMenuItem[] = [
    {
      name: 'Accueil',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'link',
      icon: 'i-Bar-Chart',
      state: '/gestion/dashboard'
    },
    {
      name: 'Requete à fin d\'ordonnance',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
      type: 'link',
      icon: 'i-Letter-Open',
      state: '/gestion/requete-fin-ordonnance'
    },
    // **********************************************************/
  ];
}
