/*import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ajouter-document',
  templateUrl: './ajouter-document.component.html',
  styleUrls: ['./ajouter-document.component.css']
})
export class AjouterDocumentComponent implements OnInit {
  @Input() numerorg?: string;

  constructor() { }

  ngOnInit(): void {
  }

}*/

import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentJuridique } from "src/app/models/gestion/dossier-juridique/documents-juridique";
import { DossierJuridique } from "src/app/models/gestion/dossier-juridique/dossier-juridique";
import { TypeDocument } from "src/app/models/gestion/dossier-juridique/type-document";
import { DocumentService } from "src/app/services/gestion/dossier-juridique/document.service";
import { DossiersService } from "src/app/services/gestion/dossier-juridique/dossiers.service";
import { TypeDocumentService } from "src/app/services/gestion/dossier-juridique/type-document.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TypeBien } from "src/app/models/gestion/dossier-juridique/type-bien";
import { TypeBienService } from "src/app/services/gestion/dossier-juridique/type-bien.service";
import { AutoComplete } from "src/app/models/common/auto-complete";
import { PersonnePhysiqueService } from "src/app/services/gestion/personnes/personne-physique.service";
import { PersonnePhysique } from "src/app/models/gestion/personnes/personne-physique";
import { PersonneMoraleService } from "src/app/services/gestion/personnes/personne-morale.service";
import { PersonneMorale } from "src/app/models/gestion/personnes/personne-morale";
import { PieceJuridique } from "src/app/models/gestion/dossier-juridique/piece-juridique";
import { environment } from "src/environments/environment";
import { filter } from "rxjs/operators";
import { BienJuridique } from "src/app/models/gestion/dossier-juridique/bien-juridique";
import { BienJuridiqueService } from "src/app/services/gestion/dossier-juridique/bien-juridique.service";
import { LocalStoreService } from "src/app/services/common/local-store.service";
import { User } from "src/app/models/gestion/utilisateur/user";
import { ExerciceFonction } from "src/app/models/gestion/utilisateur/exercice-fonction";
import { AuthService } from "src/app/services/common/auth.service";
import { ServiceJuridiqueService } from "src/app/services/gestion/org-judiciaire/service-juridique.service";
import { ServiceJuridique } from "src/app/models/gestion/org-judiciaire/service-juridique";
import { Audience } from "src/app/models/gestion/audience/audience";
import { NzModalService } from "ng-zorro-antd/modal";
import { ProcesVerval } from "src/app/models/common/pv";
import { th } from "date-fns/locale";

@Component({
  selector: "app-ajouter-document",
  templateUrl: "./ajouter-document.component.html",
  styleUrls: ["./ajouter-document.component.css"],
})
export class AjouterDocumentComponent implements OnInit {
  @Input() numerorg?: string;
  @Input() typeAjout = "";

  // dossierJuridiqueList: DossierJuridique[] = [];
  dossierJuridique: DossierJuridique;

  docJuridiqueForm: FormGroup;

  numerorgChoice: string = null;
  dossierId;
  newDocument: DocumentJuridique;
  submittedBien: BienJuridique;
  searchDossierByNumeroReadOnly: boolean;

  // activeTabsNav;
  acte;
  isLoadingData = false;
  dossierControl = false;

  // validateForm: FormGroup;
  typeDocList: TypeDocument[] = [];
  typeBienList: TypeBien[] = [];
  audienceList: Audience[] = [];
  personneAutocompleteList: AutoComplete[] = [];
  personneAutocompleteAcheteur: AutoComplete = null;
  personneAutocompleteProprietaire: AutoComplete = null;
  // typeDocument: TypeDocument;

  url = environment.backend;
  fileToUpload: File = null;
  @ViewChild("fileInput", { static: false }) fileInputActe: ElementRef;

  typeDocument: string;
  typeDocumentId: number;
  typeBien: string;
  audience: Audience;
  audienceId: number;
  bienIsSave = false;

  documentJuridiqueList: DocumentJuridique[] = [];
  documentJuridiqueFiltered;

  // users
  currentUser: User;
  defaultExerciceFonction: ExerciceFonction;
  exerciceFonctionList: ExerciceFonction[];
  pv: ProcesVerval = new ProcesVerval();
  piece: ProcesVerval = new ProcesVerval();

  currentFonction: string;
  fileSize: string;
  errorMessage: string;
  isFileValid: boolean;
  disabledBtnAjouterPiece: boolean;

  constructor(
    private dossiersService: DossiersService,
    private personnePhysiqueService: PersonnePhysiqueService,
    private personneMoraleService: PersonneMoraleService,
    private typeDocumentService: TypeDocumentService,
    private typeBienService: TypeBienService,
    private bienService: BienJuridiqueService,
    private serviceActuel: ServiceJuridiqueService,
    private documentsJuridiqueService: DocumentService,
    private activeRoute: ActivatedRoute,
    // private store: LocalStoreService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private http: HttpClient,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    // const numDossierAuxilliaire=this.activeRoute.snapshot.params['numDossierAuxilliaire'];
    if (this.numerorg !== undefined && this.numerorg != null) {
      this.numerorgChoice = this.numerorg;
      console.log(this.numerorg);
      this.loadDossierByNumerorg();
    }

    this.auth
      .getCurrentExerciceFonction()
      .subscribe((data: ExerciceFonction) => {
        console.log(data);
        if (data) {
          this.currentFonction = data?.fonctionCode;
        }
      });

    if (
      this.typeAjout !== "" &&
      this.typeAjout !== undefined &&
      this.typeAjout === "M"
    ) {
      this.typeDocList = [];
      this.typeDocumentService.listMiseEnEtat().subscribe((data: any) => {
        this.typeDocList = [...data];

        this.dossiersService
          .checkTypeDocument(this.dossierId, "PV DE CONCILIATION")
          .subscribe(
            (data1: any) => {
              if (data1 === true) {
                const i = this.typeDocList.findIndex(
                  (l) => l.libelle === "PV DE CONCILIATION"
                );
                this.typeDocList.splice(i, 1);
                this.typeDocList = [...this.typeDocList];
              }
            },
            (error: HttpErrorResponse) => {
              console.log("Error lors du check type document");
            }
          );
      });
    } else if (
      this.typeAjout !== "" &&
      this.typeAjout !== undefined &&
      this.typeAjout === "P"
    ) {
      this.typeDocList = [];
      this.typeDocumentService
        .listProcedureCollective()
        .subscribe((data: any) => {
          this.typeDocList = [...data];
        });
    } else {
      this.typeDocList = [];
      this.typeDocumentService.list().subscribe((data: any) => {
        this.typeDocList = [...data];
      });
    }

    this.typeBienService.list().subscribe((data: any) => {
      this.typeBienList = [...data];
    });

    if (
      this.typeAjout != "" &&
      this.typeAjout != undefined &&
      this.typeAjout == "M"
    ) {
      this.typeDocList = [];
      this.typeDocumentService.listMiseEnEtat().subscribe((data: any) => {
        this.typeDocList = [...data];

        this.dossiersService
          .checkTypeDocument(this.dossierId, "PV DE CONCILIATION")
          .subscribe(
            (data1: any) => {
              if (data1 == true) {
                const i = this.typeDocList.findIndex(
                  (l) => l.libelle == "PV DE CONCILIATION"
                );
                this.typeDocList.splice(i, 1);
                this.typeDocList = [...this.typeDocList];
              }
            },
            (error: HttpErrorResponse) => {
              console.log("Error lors du check type document");
            }
          );
      });
    } else if (
      this.typeAjout != "" &&
      this.typeAjout != undefined &&
      this.typeAjout == "P"
    ) {
      this.typeDocList = [];
      this.typeDocumentService
        .listProcedureCollective()
        .subscribe((data: any) => {
          this.typeDocList = [...data];
        });
    } else {
      this.typeDocList = [];
      this.typeDocumentService.list().subscribe((data: any) => {
        this.typeDocList = [...data];
      });
    }

    this.typeBienService.list().subscribe((data: any) => {
      this.typeBienList = [...data];
    });

    this.personnePhysiqueService
      .list()
      .subscribe((data: PersonnePhysique[]) => {
        if (data) {
          data.forEach((d) => {
            this.personneAutocompleteList.unshift({
              display: d.nom + " " + d.prenom,
              personnePhysique: d,
              nature: "PHYSIQUE",
              personneMorale: null,
              avocat: null,
              partieConcerne: null,
            });
          });
        }
      });
    this.personneMoraleService.list().subscribe((data: PersonneMorale[]) => {
      if (data) {
        data.forEach((d) => {
          this.personneAutocompleteList.unshift({
            display: d.raisonsociale,
            personneMorale: d,
            personnePhysique: null,
            nature: "MORALE",
            avocat: null,
            partieConcerne: null,
          });
        });
      }
    });

    this.makeform(null, null);
  }

  makeform(piece: PieceJuridique, bien: BienJuridique): void {
    this.docJuridiqueForm = this.fb.group({
      id: [piece != null ? piece.id : null],
      // refdocument: [piece != null ? piece.refdocument : null],
      description: [piece != null ? piece.description : null],
      typeBienId: [
        bien != null ? bien.typeBienId : null,
        [Validators.required],
      ],
      typeBienLibelle: [bien != null ? bien.typeBienLibelle : null],
      libelle: [bien != null ? bien.libelle : null, [Validators.required]],
      caracteristique: [bien != null ? bien.caracteristique : null],
      valeurCession: [bien != null ? bien.valeurCession : null],
      acheteur: [bien != null ? bien.acheteur : null, [Validators.required]],
      estDocumentValider: [true],
    });
  }

  loadDossierByNumerorg(): void {
    if (this.numerorgChoice != null) {
      this.isLoadingData = true;
      this.dossiersService.getListByNumerorg(this.numerorgChoice).subscribe(
        (data: DossierJuridique) => {
          this.dossierJuridique = data;
          if (this.dossierJuridique) {
            this.dossierId = this.dossierJuridique?.id;
            this.loadDocumentByIdDossier(this.dossierId);
            if (
              !this.documentJuridiqueFiltered &&
              this.documentJuridiqueList.length === 0
            ) {
              this.dossierControl = true;
            }
            this.audienceList = this.allaudienceListe(this.dossierId);
          }
        },
        (error: HttpErrorResponse) => {
          this.isLoadingData = false;
          this.toastr.error("Chargement de la liste échoué.", " Erreur !", {
            progressBar: true,
          });
          console.log("Echec atatus ==> " + error.status);
        }
      );
    }
  }

  loadDocumentByIdDossier(idDossier): void {
    if (idDossier != null) {
      console.log("Incoming dossier Id " + this.dossierId);
      this.isLoadingData = true;
      this.documentsJuridiqueService.getDocumentsByDossier(idDossier).subscribe(
        (data: DocumentJuridique[]) => {
          this.isLoadingData = false;
          console.log(data);
          this.documentJuridiqueList = data == null ? [] : [...data];
          this.documentJuridiqueFiltered = [...this.documentJuridiqueList];
        },
        (error: HttpErrorResponse) => {
          this.toastr.error("Chargement de la liste échoué.", " Erreur !", {
            progressBar: true,
          });
          console.log("Echec atatus ==> " + error.status);
        }
      );
    }
  }

  docType(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        size: "lg",
      })
      .result.then(
        (result) => {
          console.log("d" + result);
        },
        (reason) => {
          console.log("Err!", reason);
        }
      );
  }

  allaudienceListe(idDossierActuel): Audience[] {
    this.dossiersService
      .getAudiencesByDossierId(idDossierActuel)
      .subscribe((data: any) => {
        this.audienceList = [...data];
        console.log("audience tableau " + this.audienceList);
      });
    return this.audienceList;
  }

  // pour enregistrer le document et le bien
  enregistrerAll(): void {
    // this.audience.
    this.isLoadingData = true;
    this.acte = this.typeDocument;
    console.log("test ++++++++++++++++++++++" + this.acte);
    if (this.typeDocument == null) {
      this.isLoadingData = false;
      this.toastr.error("Entrez un type de document.", " Erreur !", {
        progressBar: true,
      });
      return console.log("Entrez un type document forcé");
    }
    const piece: PieceJuridique = new PieceJuridique();
    piece.description = this.docJuridiqueForm.get("description").value;
    piece.dossierId = this.dossierId;
    piece.file = this.fileToUpload;
    piece.typedocumentId = this.typeDocumentId;
    piece.audienceId = this.audienceId;
    piece.refdocument = this.dossierId;
    this.enregistrerPiece(piece);

    // this.resetForm();
    // this.modalService.dismissAll("Done");

    if (this.typeDocument === "PROCÈS VERBALE") {
      this.pv.numerorg = this.numerorgChoice;
      this.pv.typeDocument = this.typeDocument;
      this.pv.estPv = true;
      this.pv.estPiece = false;
      localStorage.setItem("Pv", JSON.stringify(this.pv));
    } else {
      this.pv.numerorg = this.numerorgChoice;
      this.pv.typeDocument = this.typeDocument;
      this.pv.estPv = false;
      this.pv.estPiece = true;
      localStorage.setItem("Piece", JSON.stringify(this.pv));
    }
  }

  enregistrerBien(bien: BienJuridique): void {
    console.log(
      "bien send to data base " +
        bien.libelle +
        " caracte " +
        bien.caracteristique
    );
    this.isLoadingData = true;
    this.bienService.createBien(bien).subscribe(
      (data: any) => {
        this.submittedBien = data;
        console.log("SUBMITTED BIEN " + this.submittedBien.libelle);
        this.bienIsSave = true;
        this.isLoadingData = false;
        this.toastr.success(
          "Enregistrement de PLACARD effectué avec succès.",
          "Success!",
          { progressBar: true }
        );
        this.loadDossierByNumerorg();
      },
      (error: HttpErrorResponse) => {
        console.log("Echec status ==> " + error.status);
        this.isLoadingData = true;
        setTimeout(() => {
          this.isLoadingData = false;
          this.toastr.error(
            "Erreur avec le status " + error.status,
            " Erreur !",
            { progressBar: true }
          );
        }, 3000);
      }
    );
  }

  // enregistrer Document seul
  enregistrerPiece(piece: PieceJuridique): boolean {
    console.log("Incoming Pièce+++++++++++++++ " + piece);
    if (piece.id == null || piece.id === 0) {
      const formData = new FormData();
      formData.append("Content-Type", "multipart/form-data");
      formData.append("file", piece.file as File);
      formData.append(
        "typedocumentId",
        piece.typedocumentId.toString() as string
      );
      formData.append("description", piece.description as string);
      formData.append("dossierId", this.dossierId.toString() as string);
      formData.append("refdocument", this.dossierId.toString() as string);
      if (this.currentFonction !== "JUG" && this.currentFonction !== "PRT") {
        formData.append("estDocumentValider", "0");
      } else {
        formData.append("estDocumentValider", "1");
      }

      const req = new HttpRequest(
        "POST",
        environment.backend + "/documents/upload",
        formData
      );
      this.http
        .request(req)
        .pipe(filter((e) => e instanceof HttpResponse))
        .subscribe(
          (data: any) => {
            console.log(data);
            // a decommenter pour avoir le doc id
            this.newDocument = data.body;
            if (data.body != null) {
              this.isLoadingData = false;
              if (this.typeDocument !== "PLACARD") {
                // this.loadDossierByNumerorg();
                this.toastr.success(
                  "Enregistrement de document effectué avec succès.",
                  "Success!",
                  { progressBar: true }
                );
                this.resetForm();
                // this.modalService.dismissAll("Done");
                this.modal.closeAll();
              }
              if (this.typeDocument === "PLACARD") {
                // save the bien
                const bien: BienJuridique = new BienJuridique();
                bien.acheteur = this.docJuridiqueForm.get("acheteur").value;
                bien.caracteristique =
                  this.docJuridiqueForm.get("caracteristique").value;
                bien.codeRG = "1101";
                bien.libelle = this.docJuridiqueForm.get("libelle").value;
                bien.typeBienId = this.docJuridiqueForm.get("typeBienId").value;
                bien.typeBienLibelle = this.getTypeBienLib(
                  this.docJuridiqueForm.get("typeBienId").value
                );
                bien.valeurCession =
                  this.docJuridiqueForm.get("valeurCession").value;
                bien.documentId = this.newDocument.documentId;
                if (this.personneAutocompleteProprietaire.nature === "MORALE") {
                  bien.personnemoraleProprietaireId =
                    this.personneAutocompleteProprietaire?.personneMorale.id;
                } else {
                  bien.personnephysiqueProprietaireId =
                    this.personneAutocompleteProprietaire?.personnePhysique.id;
                }

                this.enregistrerBien(bien);
              }
            }
            return true;
          },
          (err) => {
            this.isLoadingData = false;
            this.toastr.error(
              "Pièce " + piece.id + " non enregistrée, problème système !",
              " Erreur !",
              { progressBar: true }
            );
          }
        );
    }
    return false;
  }

  handleFileInput(event): void {
    this.fileToUpload = event.target.files[0];
    console.log(this.fileToUpload.name);
    console.log(this.fileToUpload.type);
    if (
      this.fileToUpload.type.toLowerCase() !== "application/pdf" &&
      this.fileToUpload.type.toLowerCase() !==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
      this.fileToUpload.type.toLowerCase() !== "image/jpeg" &&
      this.fileToUpload.type.toLowerCase() !== "image/png" &&
      this.fileToUpload.type.toLowerCase() !== "image/jpg"
    ) {
      this.toastr.error(
        "Seul les fichiers au format pdf ou images sont autorisés.",
        " Erreur !",
        { progressBar: true }
      );
    }
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const fileSize = file.size; // Taille en octets
      const fileSizeInKB = fileSize / 1024; // Taille en Ko
      const fileSizeInMB = fileSizeInKB / 1024; // Taille en Mo

      // Vous pouvez aussi mettre à jour une variable dans le composant
      this.fileSize = fileSizeInMB.toFixed(2);

      if (fileSizeInMB > 5) {
        // Si la taille du fichier dépasse 5 Mo
        this.errorMessage =
          "La taille de votre fichier dépasse la taille maximale qui est 5 Mo";
        this.isFileValid = false; // Vous pouvez utiliser cette variable pour gérer l'état du fichier
        this.disabledBtnAjouterPiece = false;
      } else {
        // Le fichier est valide
        this.errorMessage = "";
        this.isFileValid = true;
        this.disabledBtnAjouterPiece = true;
        // Continuez avec votre logique si le fichier est valide
      }
    }
  }

  getTypeDocLib(): void {
    // setting the type document libelle
    const i = this.typeDocList.findIndex((d) => d.id === this.typeDocumentId);
    if (i > -1) {
      const typeDoc = this.typeDocList[i];
      this.typeDocument = typeDoc.libelle;
      console.log("Type Document ::: ", this.typeDocument);
    }
  }

  getTypeBienLib(typeBienId: number): string {
    // setting the type bien libelle
    const i = this.typeBienList.findIndex((d) => d.id === typeBienId);
    if (i > -1) {
      const typeBien = this.typeBienList[i];
      return typeBien.libelle;
    }
  }

  resetForm(): void {
    this.docJuridiqueForm.reset();
    for (const key in this.docJuridiqueForm.controls) {
      this.docJuridiqueForm.controls[key].markAsPristine();
      this.docJuridiqueForm.controls[key].updateValueAndValidity();
    }
    this.makeform(null, null);
  }

  printFileToViewInNavigator(fileName: string): void {
    this.documentsJuridiqueService.downloadFile(fileName).subscribe((res) => {
      let file;
      let fileURL;
      file = new Blob([res], { type: "application/pdf" });
      fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  }

  printFileToDownloadInNavigator(fileName: string): void {
    this.documentsJuridiqueService.downloadFile(fileName).subscribe((res) => {
      let file;
      let fileURL;
      file = new Blob([res], { type: "application/pdf" });
      fileURL = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      // the filename you want
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(fileURL);
      // window.open(fileURL);
    });
  }

  okButton(): void {
    this.resetForm();
    this.bienIsSave = false;
    this.modalService.dismissAll("Done");
  }
}
